Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.invento = "INVENTO HUB";
exports.professional = "Unlock Innovation: Explore Your IP Playground!";
exports.jionAs = "Join as an";
exports.user = "IP Professional";
exports.client = "IP Client";
exports.continue = "CONTINUE";
exports.haveAccount = "Already have an account?";
exports.signIn = "SIGN IN"


exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.errorMessage ="Please enter your password.";
exports.applyAsUser = "APPLY AS AN USER"
exports.applyAsClient = "APPLY AS A CLIENT"

// Customizable Area End