import React from "react";
// Customizable Area Start
import { Box,  Card,  CardContent,  FormControl,  Grid, MenuItem, Select, Typography, styled } from "@material-ui/core";
const CustomSelect = styled(Select)({
    borderRadius:"16px",
    borderColor:"#979797"
   });
   import {tick, edit
   } from './assets'

   const HeaderGrid = styled(Grid)({
    padding: "20px",
    paddingBottom: 100,
    '& .inner-header-box': {
        margin: 'auto',
    },
    '@media(max-width: 960px)': {
        '& .inner-header-box': {
            margin: 'auto',
        },
    },
    '@media(max-width: 599px)': {
        '& .inner-header-box': {
            maxWidth: '100%',
            margin: 'auto',
            marginTop:"14px"
        },
    },
    '@media(max-width: 480px)': {
        '& .inner-header-box': {
            maxWidth: '100%',
            margin: 'auto',
            marginTop:"14px"
        },
    }
});

const ProjectPrefText = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 500,
  fontFamily:"Rubik",
  [theme.breakpoints.down('md')]: {
    fontSize: '16px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px', 
  },
}));

const SubText = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  fontFamily:"Rubik",
  color:"#979797",
  marginTop: "14px",
  [theme.breakpoints.down('md')]: {
    fontSize: '14px', 
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px', 
  },
}));


// Customizable Area End
import FreelancerProfileSettingsController, {
    Props,
    configJSON
} from "./FreelancerProfileSettingsController";

export default class FreelancerProfileSettings extends FreelancerProfileSettingsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderChips = (items: string[]) => {
        return items.map((item) => (
            <Box key={item} style={{ margin: '12px 0px' }}>
            <Box style={webStyles.chipStyle}>
              <Typography style={webStyles.skillStyle}>{item}</Typography>
            </Box>
          </Box>
        ));
      };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { areaOfExpertise, industryFocus } = this.state;
        return (
          <HeaderGrid container spacing={3}>
          <Grid className="inner-header-box" item xs={12} sm={12}>
            <Box style={webStyles.profileSettingBorderBox}>
              <Typography style={{ ...webStyles.projectSettingText, fontSize: 'clamp(16px, 2.5vw, 24px)' }}>
                Profile Settings
              </Typography>
                <ProjectPrefText>Project Preference</ProjectPrefText>
                <FormControl fullWidth variant="outlined" style={{ marginTop: '16px' }}>
                  <CustomSelect
                    value={this.state.preference}
                    onChange={this.handleSelectChange}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    displayEmpty
                  >
                    <MenuItem value="" disabled selected>
                      Select your preference
                    </MenuItem>
                    <MenuItem value={'shortTerm'}>{configJSON.shortTerm}</MenuItem>
                    <MenuItem value={'longTerm'}>{configJSON.longTerm}</MenuItem>
                    <MenuItem value={'both'}>{configJSON.bothTerm}</MenuItem>
                  </CustomSelect>
                </FormControl>
              </Box>
            </Grid>
          
            <Grid item xs={12} sm={12} >
            <Box style={{...webStyles.profileSettingBorderBox, marginTop:"16px"}}>
                <Typography style={webStyles.projectSettingText}>Experience Level</Typography>
   
                <Grid container spacing={3}>
                        {[
                            { label: "Entry Level", value: "entry_level", description: "I am relatively new to the field and learning." },
                            { label: "Intermediate", value: "intermediate", description: "I have substantial experience in this field." },
                            { label: "Expert", value: "expert", description: "I have comprehensive and deep expertise." }
                        ].map((item) => (
                            <Grid item xs={12} md={4} key={item.value}>
                            <Card
                                variant="outlined"
                                style={{
                                borderColor: this.state.selectedLevel === item.value ? "#3f51b5" : "#e0e0e0",
                                borderWidth: "2px",
                                borderRadius: "8px",
                                height: "100%",
                                cursor: "pointer" 
                                }}
                                data-test-id="handleLevelChange"
                                onClick={() => this.handleLevelChange(item.value)} 
                            >
                                <CardContent>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <ProjectPrefText>
                                    {item.label}
                                    </ProjectPrefText>
                                    {this.state.selectedLevel === item.value && (
                                    <img src={tick} alt="Selected" />
                                    )}
                                </Box>
                                <SubText>
                                    {item.description}
                                </SubText>
                                </CardContent>
                            </Card>
                            </Grid>
                        ))}
                 </Grid>

            </Box>
            </Grid>

            
          
          </HeaderGrid>
          
        );
        // Customizable Area End
    }
}

// Customizable Area Start

export const webStyles = {
    profileSettingBorderBox: {
        border:"1px solid #CECECE",
        padding:"24px",
        borderRadius:"16px"
    },
    projectSettingText:{
        fontSize: '24px',
        fontWeight: 700,
        fontFamily:"Rubik",
        marginBottom: "32px"
    },
    projectPrefText:{
        fontSize: '20px',
        fontWeight: 500,
        fontFamily:"Rubik",
    },
    subText:{
        fontSize: '18px',
        fontFamily:"Rubik",
        color:"#979797",
        marginTop: "14px"
    },
    chipStyle: {
        background: "#364BA0",
        borderRadius: "26px",
        display: "flex",
        padding: "8px",
        gap: "6px",
        justifyContent: "center",
      },
      skillStyle: {
        color: '#FFFFFF',
        padding: "2px 8px"
      },
      headingDisplay: {
        display: "flex",
        justifyContent: "space-between"
    },
};
// Customizable Area End
