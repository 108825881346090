// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start
  Box,
  Button,
  Typography,
  Grid,
  FormControl,
  styled,
  TextField

} from "@material-ui/core";
import { step_1,step_22,step_33,step_44,step_5_active ,addBtn,twitter,facebook,medium,linkedin,instagram,youtube, viewBarActive} from "./assets";

const CustomTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius:"10px",
    borderColor:"#E6E6E6",
  },
})

// Customizable Area End

// Customizable Area Start
import FreelancerNavBar from "../../dashboard/src/FreelancerNavBar.web";
import  RemindMeDialogbox  from "./RemindMeDialogbox.web";

import AchievementsCustomFormController, {
  Props,
 
} from "./AchievementsCustomFormController.web";
import FooterWeb from "../../dashboard/src/Components/Footer.web";
// Customizable Area End

export default class AchievementsCustomForm extends AchievementsCustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handleNext = this.handleNext.bind(this);
    this.doItLater = this.doItLater.bind(this);


    // Customizable Area End
  }

  // Customizable Area Start
 
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
     
      <>
        <FreelancerNavBar navigation={this.props.navigation} isFromHomePage={true} />
        <Styled_wrapper>
      
     
        <Stepper_customAchi_design>
          <Box className="box_1">  <img src={step_1}/></Box>
          <img src={viewBarActive} className="view_bar"/>
          <Box className="box_1 img_second"> <img src={step_22}/></Box>
          <img src={viewBarActive} className="view_bar"/>
          <Box className="box_1"> <img src={step_33}/></Box>
          <img src={viewBarActive} className="view_bar"/>
          <Box className="box_1"> <img src={step_44}/></Box>
          <img src={viewBarActive} className="view_bar"/>
          <Box className="box_1"> <img src={step_5_active}/></Box>
        </Stepper_customAchi_design>
          <Typography style={webStyle.basicDetailsHead}>
          Add your Social Media and Professional Websites
          </Typography>
          <Typography className="main_subheading">
          Provide links to your professional social media profiles and websites.
          </Typography>
          
        <div>
            <Media_icon_wrapper>
              <Box className="box_media">
                <img
                  src={twitter}
                  data-test-id="twitter"
                  className="media_icon"
                  onClick={() => this.handleMediaIconClick("Twitter")}
                />
              </Box>
              <Box className="box_media">
                <img
                  src={facebook}
                  className="media_icon"
                  data-test-id="facebook"
                  onClick={() => this.handleMediaIconClick("Facebook")}
                />
              </Box>
              <Box className="box_media">
                <img
                  src={medium}
                  className="media_icon"
                  data-test-id="medium"
                  onClick={() => this.handleMediaIconClick("Medium")}
                />
              </Box>
              <Box className="box_media">
                <img
                  src={linkedin}
                  className="media_icon"
                  data-test-id="linkedin"
                  onClick={() => this.handleMediaIconClick("LinkedIn")}
                />
              </Box>
              <Box className="box_media">
                <img
                  src={instagram}
                  className="media_icon"
                  data-test-id="instagram"
                  onClick={() => this.handleMediaIconClick("Instagram")}
                />
              </Box>
              <Box className="box_media">
                <img
                  src={youtube}
                  className="media_icon"
                  data-test-id="youtube"
                  onClick={() => this.handleMediaIconClick("YouTube")}
                />
              </Box>
            </Media_icon_wrapper>

            <Box>
            {this.state.socialMediaForm.length > 0 && (
              this.state.socialMediaForm.map((form, index) => (
                <Box key={form.id} style={{ marginBottom: "16px" }}>
                <Box style={webStyle.blueBackgroundBox}>
                  <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography style={webStyle.labelStyle}>Media Name</Typography>
                    <FormControl style={webStyle.textField} variant="outlined">
                      <CustomTextField
                        data-test-id="Media_Name"
                        variant="outlined"
                        placeholder="Personal Website"
                        value={form.Media_Name}
                        onChange={(e) => this.handleSocialMediaChange(e, form.id, "Media_Name")}
                        error={!!this.state.socialMediaErrordata[index]?.Media_Name}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={webStyle.labelStyle}>Link</Typography>
                    <FormControl style={webStyle.textField} variant="outlined">
                      <CustomTextField
                        placeholder="www.example.com"
                        variant="outlined"
                        value={form.Media_Link}
                        onChange={(e) => this.handleSocialMediaChange(e, form.id, "Media_Link")}
                        error={!!this.state.socialMediaErrordata[index]?.Media_Link}
                        data-test-id="Media_Link"

                      />
                      {this.state.socialMediaErrordata[index]?.Media_Link && (
                        <Typography color="error">
                          {this.state.socialMediaErrordata[index].Media_Link}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                    <Grid item xs={2}>
                      <Button
                        variant="text"
                        onClick={() => this.handleRemoveSocialMediaForm(form.id)}
                        style={webStyle.removeText}
                      >
                        Remove
                      </Button>
                    </Grid>
                    </Grid>
                </Box>

                {index === this.state.socialMediaForm.length - 1 && (
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ marginTop: "26px" }}
                  >
                    <Grid item>
                      {this.state.socialMediaForm.length < 5 && (
                        <Button
                          variant="text"
                          style={webStyle.removeText}
                          onClick={this.handleAddSocialMediaForm}
                          data-test-id="SocialMediaForm_add"
                        >
                            <Box style={{ padding: "0 14px 3px 0" }}>
                                <img  src={addBtn}
                                  className="Add_btn_icon" />
                            </Box>
                            Add another 
                        </Button>
                      )}
                    </Grid>
                    <Grid item>
                      <Grid container spacing={2}>
                        <Grid item>
                          {this.state.socialMediaForm.length > 1 && (
                            <Button
                              variant="text"
                              style={webStyle.removeText}
                              onClick={() => this.handleRemoveSocialMediaForm(form.id)}
                              data-test-id="SocialMediaForm_remove"

                            >
                              Remove
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                </Box>
            ))
          )}
        </Box>
        </div>



<Box style={webStyle.mainSubBox}>
        <Typography className="basicDetailsHead">Add your Publications and Contributions</Typography>
        <Typography className="main_subheading">
        Provide links to published articles, press releases, or blogs that feature your work or contributions.
        </Typography>
        {this.state.publicationsForm.map((form, index) => (
          <Box key={form.id}>
            <Box style={webStyle.BoxTwoStyle}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Typography style={webStyle.labelStyle}>Title</Typography>
                  <FormControl style={webStyle.textField} variant="outlined">
                  <CustomTextField
                      variant="outlined"
                      placeholder="Enter Title here"
                      value={form.Publications_Title}
                      onChange={(e) => this.handlePublicationschnage(e, form.id, 'Publications_Title')}
                      error={!!this.state.publicationserrors[index]?.Publications_Title}
                     data-test-id="Publications_Title"
                    />
                    {this.state.publicationserrors[index]?.Publications_Title && (
                      <Typography color="error">{this.state.publicationserrors[index].Publications_Title}</Typography>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Typography style={webStyle.labelStyle}>Description</Typography>
                  <FormControl style={webStyle.textField} variant="outlined">
                    <CustomTextField
                      placeholder="Enter Description here"
                      variant="outlined"
                      value={form.Publications_Description}
                      onChange={(e) => this.handlePublicationschnage(e, form.id, 'Publications_Description')}
                      error={!!this.state.publicationserrors[index]?.Publications_Description}
                     data-test-id="Publications_Description"

                    />
                    {this.state.publicationserrors[index]?.Publications_Description && (
                      <Typography color="error">{this.state.publicationserrors[index].Publications_Description}</Typography>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Typography style={webStyle.labelStyle}>Add Link</Typography>
                  <FormControl style={webStyle.textField} variant="outlined">
                    <CustomTextField
                      variant="outlined"
                      placeholder="Enter Link here"
                      value={form.Publications_Add_Link}
                      onChange={(e) => this.handlePublicationschnage(e, form.id, 'Publications_Add_Link')}
                      error={!!this.state.publicationserrors[index]?.Publications_Add_Link}
                     data-test-id="Publications_Add_Link"

                    />
                    {this.state.publicationserrors[index]?.Publications_Add_Link && (
                      <Typography color="error">{this.state.publicationserrors[index].Publications_Add_Link}</Typography>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: "26px" }}>
              <Grid item>
                {this.state.publicationsForm.length < 5 && (
                  <Button
                    variant="text"
                    style={webStyle.removeText}
                    onClick={this.handleAddPublications}
                    data-test-id='educationAddBTN'
                  >
                    <Box style={{ padding: "0 14px 3px 0" }}>
                       <img  src={addBtn}
                         className="Add_btn_icon" />
                  </Box>
                  Add another 
                  </Button>
                )}
              </Grid>
              <Grid item>
                {this.state.publicationsForm.length > 1 && (
                  <Button
                    variant="text"
                    style={webStyle.removeText}
                    onClick={() => this.handleRemovePublications(form.id)}
                    data-test-id="Publications_remove"
                  >
                    Remove
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        ))}
      </Box>


      <Box style={webStyle.mainSubBox}>
        <Typography className="basicDetailsHead">Add your Professional Affiliations</Typography>
        <Typography className="main_subheading">
        List any professional organizations or industry groups you are a member of. </Typography>
        <Box>
          {this.state.professionalForms.map((form, index) => (
            <Box key={form.id}>
              <Box style={webStyle.BoxTwoStyle}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <Typography style={webStyle.labelStyle}>Add Organizations or Industry Groups</Typography>
                    <FormControl style={webStyle.textField} variant="outlined">
                      <CustomTextField
                        variant="outlined"
                        placeholder="Enter Organization or Industry Groups here"
                        value={form.Professional_Organizations}
                        onChange={(e) => this.handlecertificateChange(e, form.id, 'Professional_Organizations')}
                        error={!!this.state.professionalerrors[index]?.Professional_Organizations}
                        data-test-id='Professional_Organizations'
                      />
                        {this.state.professionalerrors[index]?.Professional_Organizations && (
                      <Typography color="error">{this.state.professionalerrors[index].Professional_Organizations}</Typography>
                    )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography style={webStyle.labelStyle}>Description</Typography>
                    <FormControl style={webStyle.textField} variant="outlined">
                      <CustomTextField
                        placeholder="Enter Description here"
                        value={form.Professional_Description}
                        onChange={(e) => this.handlecertificateChange(e, form.id, 'Professional_Description')}
                        error={!!this.state.professionalerrors[index]?.Professional_Description}
                        data-test-id='Professional_Description'
                        variant="outlined"
                      />
                         {this.state.professionalerrors[index]?.Professional_Description && (
                      <Typography color="error">{this.state.professionalerrors[index].Professional_Description}</Typography>
                    )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography style={webStyle.labelStyle}>Add Link</Typography>
                    <FormControl style={webStyle.textField} variant="outlined">
                      <CustomTextField
                        placeholder="Enter Link here"
                        value={form.Professional_Add_Link}
                        onChange={(e) => this.handlecertificateChange(e, form.id, 'Professional_Add_Link')}
                        error={!!this.state.professionalerrors[index]?.Professional_Add_Link}
                        data-test-id='Professional_Add_Link'
                        variant="outlined"
                      />
                        {this.state.professionalerrors[index]?.Professional_Add_Link && (
                      <Typography color="error">{this.state.professionalerrors[index].Professional_Add_Link}</Typography>
                    )}
                    </FormControl>
                  </Grid>
                
                </Grid>
              </Box>
              <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: '26px' }}>
                <Grid item>
                  {this.state.professionalForms.length < 5 && (
                    <Button variant="text" style={webStyle.removeText} onClick={this.handleAddprofessionalForms}>
                       <Box style={{ padding: "0 14px 3px 0" }}>
                       <img  src={addBtn}
                         className="Add_btn_icon" />
                  </Box>
                  Add another 
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  {this.state.professionalForms.length > 1 && (
                    <Button variant="text" style={webStyle.removeText}
                     onClick={() => this.handleRemoveprofessionalForms(form.id)}
                     
                      data-test-id="handleRemoveprofessionalForms"
                     >
                      Remove
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          ))}
        </Box>
      </Box>

      <Box style={webStyle.mainSubBox}>
        <Typography className="basicDetailsHead">Add your Speaking Engagements and Seminars</Typography>
        <Typography className="main_subheading">List any recent speaking engagements or seminars where you have been a speaker or panelist.</Typography>
        <Box>
          {this.state.seminarsForms.map((form, index) => (
            <Box key={form.id}>
              <Box style={webStyle.BoxTwoStyle}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <Typography style={webStyle.labelStyle}>Title</Typography>
                    <FormControl style={webStyle.textField} variant="outlined">
                      <CustomTextField
                        variant="outlined"
                        placeholder="Enter Title here"
                        value={form.Seminars_Title}
                        onChange={(e) => this.handleLicenseChange(e, form.id, 'Seminars_Title')}
                        data-test-id='Seminars_Title'
                        error={!!this.state.seminarsErrors[index]?.Seminars_Title}
                      />
                      {this.state.seminarsErrors[index]?.Seminars_Title && (
                      <Typography color="error">{this.state.seminarsErrors[index].Seminars_Title}</Typography>
                    )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography style={webStyle.labelStyle}>Description</Typography>
                    <FormControl style={webStyle.textField} variant="outlined">
                      <CustomTextField
                        placeholder="Enter Description here"
                        value={form.Seminars_Description}
                        onChange={(e) => this.handleLicenseChange(e, form.id, 'Seminars_Description')}
                        data-test-id='Seminars_Description'
                        variant="outlined"
                        error={!!this.state.seminarsErrors[index]?.Seminars_Description}
                      />
                       {this.state.seminarsErrors[index]?.Seminars_Description && (
                      <Typography color="error">{this.state.seminarsErrors[index].Seminars_Description}</Typography>
                    )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography style={webStyle.labelStyle}>Add Link</Typography>
                    <FormControl style={webStyle.textField} variant="outlined">
                      <CustomTextField
                        placeholder="Enter Link here"
                        value={form.Seminars_Add_Link}
                        onChange={(e) => this.handleLicenseChange(e, form.id, 'Seminars_Add_Link')}
                        data-test-id='Seminars_Add_Link'
                        variant="outlined"
                        error={!!this.state.seminarsErrors[index]?.Seminars_Add_Link}
                      />
                         {this.state.seminarsErrors[index]?.Seminars_Add_Link && (
                      <Typography color="error">{this.state.seminarsErrors[index].Seminars_Add_Link}</Typography>
                    )}
                    </FormControl>
                  </Grid>
                
                </Grid>
              </Box>
              <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: '26px' }}>
                <Grid item>
                  {this.state.seminarsForms.length < 5 && (
                    <Button variant="text" style={webStyle.removeText} onClick={this.handleAddLicenseForm}>
                       <Box style={{ padding: "0 14px 3px 0" }}>
                       <img  src={addBtn}
                         className="Add_btn_icon" />
                  </Box>
                  Add another 
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  {this.state.seminarsForms.length > 1 && (
                    <Button variant="text" style={webStyle.removeText} onClick={() => this.handleRemoveLicenseForm(form.id)}>
                      Remove
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          ))}
        </Box>
      </Box>

     





        <Grid container justifyContent="space-between" alignItems="center" style={{marginTop:"26px"}}>
      <Grid item>
        <Back_button_achievement variant="outlined" 
          onClick={this.handlebackpage.bind(this)}
          data-test-id="handlebackpage"
        >
          Back
        </Back_button_achievement>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item>
          <Button
           variant="text"
           className="laterText"
           onClick={this.doItLater}

        >
           I'll do it later
        </Button>
          </Grid>
          <Grid item>
          <Next_button_achievement
          variant="contained"
          style={webStyle.nextButton}
          onClick={this.handleNext}
        data-test-id="navidateTOnext"
        >
          FINISH
        </Next_button_achievement>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
       
    {this.state.do_It_Later?<>
              <RemindMeDialogbox navigation={this.props.navigation} id={""}/>
            </>:""}
      

   </Styled_wrapper>
        <FooterWeb />
      </>
      // Customizable Area End
    );
  }

  
}

const webStyle = {

  mainSubBox:{
    marginTop:"28px"
  },
  basicDetailsHead:{
    fontFamily:"Rubik",
    fontSize:"36px",
    fontWeight:600,
  },

  BoxTwoStyle:{
    border: "1px solid #B1B1B1",
    padding: "28px",
    borderRadius:"12px",
    marginTop:"20px"
  },
  blueBackgroundBox:{
    background:"#EDF9FF",
    padding: "28px",
    borderRadius:"8px",
    marginTop:"20px"
  },
  imageBoxStyle:{
    display:"flex",
    gap :"48px"
  },
  nameStyle:{
    fontFamily:"Rubik",
    fontSize:"28px",
    fontweight:500,
    color:"#0A0A0A"
  },
  emailStyle:{
    fontFamily:"Rubik",
    fontSize:"18px",
    color:"#0A0A0A"
  },
  updateProfileBtn:{
    color: "#364BA0",
  border: "1px solid #364BA0",
  borderRadius: "26px",
  marginTop: "16px",
  display: "flex",
  justifyContent: "center",
  padding: "10px",
  fontSize: "20px",
  fontFamily:"Rubik",
  fontWeight: 500
  },
  labelStyle:{
    fontFamily:"Rubik",
    fontSize:"16px",
    color:"#0A0A0A",
    fontWeight:500,
    marginBottom:"8px"
  },
  withoutlabelStyle:{
    fontFamily:"Rubik",
    fontSize:"16px",
    color:"#0A0A0A",
    fontWeight:500,
    marginBottom:"8px",
paddingTop:"23px"
  },
  textField: {
    width: "100%",
    "&.MuiFormLabel-root.Mui-focused ": {
      color: "#6F6E6E !important",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline ": {
      borderColor: "#6F6E6E !important",
      borderRadius: "12px !important"
    },
    "& .MuiOutlinedInput-root":{
      borderRadius: "12px !important"
    }
  },
   
  chipStyle: {
    background: "#364BA0",
    borderRadius: "26px",
    display: "flex",
    padding: "8px",
    gap: "6px",
    justifyContent: "center",
  },
  buttonStyle: {
    margin: '10px 0',
    fontWeight: 500,
  },
  backButton: {
    borderColor: '#364BA0',
    color: '#364BA0',
    height:"40px",
    padding:"22px",
    fontFamily:"Rubik",
    fontSize:"18px",
    fontWeight:500
  },
  nextButton: {
    backgroundColor: '364BA0',
    color: '#fff',
    height:"40px",
    padding:"22px",
    fontFamily:"Rubik",
    fontSize:"18px",
    fontWeight:500
  },
  iconStyle: {
    color: '#fff',  
    fontSize: '20px',  
  },
  laterText:{
    color: '#364BA0',  
    textTransform: 'uppercase' as 'uppercase',
    textDecoration: 'underline' as 'underline',
    fontFamily:"Rubik",
    fontSize:"18px",
    fontWeight:500
  },
  removeText:{
    color: '#364BA0',  
    textTransform: 'initial' as 'initial',
    fontFamily:"Rubik",
    fontSize:"18px",
    fontWeight:500
  }
};
const Back_button_achievement = styled(Button)({
  borderColor: '#364BA0',
    color: '#364BA0',
    padding:"22px",
    fontFamily:"Rubik",
    fontSize:"18px",
    height:"40px",
    fontWeight:500,
    borderRadius:"10px",
    "@media(max-width:500px)": {
     padding:"14px"
    },
    "& .MuiButton-label":{
      fontSize:"14px"


    }

});
const Styled_wrapper = styled(Box)({
    padding: "18px 48px",
    maxWidth: "1500px",
    "@media(max-width:700px)": {
      padding: "12px 25px",
      },

  "& .Add_btn_icon":{
    height: "34",
    width:"34",

    maxWidth: "150px", 
       display: "block",  
       "@media(max-width:500px)": {
          height: "30",
          width:"30"
        },
 },
  '& .basicDetailsHead': {
    fontFamily: "Rubik",
    fontSize: "32px",
    fontWeight: 600,
    "@media(max-width:700px)": {
    fontSize: "28px",
       
    },
    "@media(max-width:500px)": {
      fontSize: "24px",
      },
    },

 
   "& .laterText":{
    color: '#364BA0',  
    fontFamily:"Rubik",
    fontSize:"18px",
    textTransform: 'uppercase' as 'uppercase',
    textDecoration: 'underline' as 'underline',
    fontWeight:500,
    "@media(max-width:500px)": {
      fontSize:"15px",

    },
  },
  "& .main_subheading":{
    fontFamily:"Rubik",
    fontWeight:400,
    fontSize:"20px",
    "@media(max-width:500px)": {
      fontSize:"15px",

    },
  },
  

});
const InputField = styled(FormControl)({
  "@media(max-width:500px)": {
    "&  .MuiOutlinedInput-input" :{
        padding: "11.5px 12px",
      }
    },

});

const Next_button_achievement = styled(Button)({
  backgroundColor: '364BA0',
    color: '#fff',
    height:"40px",
    padding:"22px",
    fontFamily:"Rubik",
    fontSize:"18px",
    fontWeight:500,
    borderRadius:"10px",
    "@media(max-width:500px)": {
     padding:"14px"

    },
    "& .MuiButton-label":{
      fontSize:"14px"
    }

});
const Stepper_customAchi_design = styled(Box)({
  display:"flex",
  justifyContent:"space-between",
  alignItems:"center",
  margin:"30px 0 ",
  height:"40px",
  "@media (max-width: 1050px)": {
    justifyContent:"flex-start",
    flexWrap:"wrap",

  },
  "& .img_stepper":{
    height:"50px",
    width:"100%"
  },
  "& .box_1":{
    width:"auto",
  },
  "& .img_second":{
    "@media (max-width: 1050px)": {
      width:"auto",
 
  
    },

  },
  "& .img_stepper_2":{
    height:"50px",
    width:"92%",
  "@media (max-width: 1050px)": {
    width:"100%",

  }

  },

 
 });
 const Media_icon_wrapper = styled(Box)({
  display:"flex",
  alignItems:"center",
  gap: "10px",
  padding: "20px 0",
  "& .media_icon":{
    width:"32px",
    height:"32",
  }
 })
// Customizable Area End