import React from "react";
// Customizable Area Start
import { Box, Button, FormControl, Grid, IconButton, InputAdornment, MenuItem, OutlinedInput, Select, TextField, Typography ,styled} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import FreelancerNavBar from "../../dashboard/src/FreelancerNavBar.web";
import FooterWeb from "../../dashboard/src/Components/Footer.web";
import { step_1,step_22,step_3_active,step_4,step_5,addBtn, viewBarActive, viewBarInActive } from "./assets";
import  RemindMeDialogbox  from "./RemindMeDialogbox.web";


// Customizable Area End

import ExpertiseAndFeesFormController, {
  Props,
} from "./ExpertiseAndFeesFormController.web";
export default class ExpertiseAndFeesForm extends ExpertiseAndFeesFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.doItLater = this.doItLater.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  expertiseAreaError = () => {
    return (
      this.state.expertiseAreaError && (
        <div style={webStyle.formError}>
          {this.state.expertiseAreaError}
        </div>
      )
    );
  }

  renderCountryExpertiseDropdown = () => {
    return(
      <Box style={webStyle.mainSubBox}>
     <Typography className="basicDetailsHead">Add your Countries of Expertise</Typography>
      <Box style={webStyle.BoxTwoStyle}>
        <Grid container spacing={3}>
        <Grid item xs={12}>
            <Typography style={webStyle.labelStyle}>Country*</Typography>
            {this.state.addCountry.length > 0 &&
              this.state.addCountry.map((itm: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <InputField style={{ ...webStyle.textField, marginTop: '12px' }} variant="outlined">
                      <Select
                        value={itm.countryExpertise || ""}
                        data-test-id="country-expertise"
                        displayEmpty
                        error={!!this.state.countryExpertiseError[index]}
                        name="country"
                        onChange={(event) => this.handleCountrySelect(event, index)}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        <SelectManu_fee_style value="" disabled>
                           Select Country
                        </SelectManu_fee_style>
                        <SelectManu_fee_style value="All Countries">All Countries</SelectManu_fee_style>
                       {this.state.countryList.map((country) => (
                          <SelectManu_fee_style key={country.id} value={country.name}>
                            {country.name}
                          </SelectManu_fee_style>
                        ))}
                      </Select>
                    </InputField>
                    {this.state.countryExpertiseError[index] && (
                        <Typography style={webStyle.formError}>
                          Please select a country.
                        </Typography>
                      )}
                      {itm.countryExpertise !== "All Countries" && itm.countryExpertise && (
                        <Grid container spacing={3} style={{ marginTop: '5px', marginBottom: '10px' }}>
                          <Grid item xs={12}>
                            <InputField style={webStyle.textField} variant="outlined">
                              <OutlinedInput
                                name="cities"
                                placeholder="Enter cities"
                                data-test-id="city-expertise"
                                value={this.state.cityIndex === index ? this.state.cityExpertise : ""}
                                onChange={(event) => this.handleCitySelect(event, index)}
                                onKeyPress={(event) => this.handleCityKeyPress(event, index)}
                              />
                            </InputField>
                          </Grid>{Array.isArray(itm.cityExpertise) &&
                            itm.cityExpertise.map((city: string, cityIndex: number) => (
                              <Box key={cityIndex} style={{ margin: '12px 10px 10px 10px' }}>
                                <Box style={webStyle.chipStyle}>
                                  <Typography style={webStyle.skillStyle}>{city}</Typography>
                                  <IconButton size="small" onClick={() => this.handleDeleteCity(city, index)}>
                                    <CloseIcon style={webStyle.iconStyle} />
                                  </IconButton>
                                </Box>
                              </Box>
                            ))}
                        </Grid>
                      )}
                      
                    </React.Fragment>
                  );
                })}
            </Grid>
          </Grid>
        </Box>
        {this.state.addCountry.some(itm => itm.countryExpertise !== "All Countries") && ( 
        <Box
          style={{
            display: "flex",
            gap: "10px",
            marginTop: "32px",
            marginBottom: "40px",
            cursor: "pointer",
          }}
          data-test-id="country-exp"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              e.preventDefault();
            }
          }}
          onClick={this.handleAddCountry}
        >
          <img src={addBtn} className="Add_btn_icon" />
          <Typography style={webStyle.addStyle}>Add another country</Typography>
        </Box>
      )}
    </Box>
  );
};
     
  countryExpertiseError = (index: number) => {
    return (
      this.state.countryExpertiseError[index] && (
        <Typography style={webStyle.formError}>
          {this.state.countryExpertiseError[index]}
        </Typography>
      )
    );
  }

  industryFocusError = () => {
    return (
      this.state.industryFocusError && (
        <div style={webStyle.formError}>
          {this.state.industryFocusError}
        </div>
      )
    );
  }

  servicesError = () => {
    return (
      this.state.servicesError && (
        <div style={webStyle.formError}>
          {this.state.servicesError}
        </div>
      )
    );
  }
  proficiencyError = (index: any) => {
    return this.state.proficiencyError[index] ? (
      <Typography style={webStyle.formError}>* Please select your language proficiency</Typography>
    ) : null;
  };


  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
      <FreelancerNavBar navigation={this.props.navigation} isFromHomePage={true} />
        <Styled_wrapper>
        <Stepper_customfee_design>
          <Box className="box_1">  <img src={step_1} /></Box>
          <img src={viewBarActive} className="view_bar"/>
          <Box className="box_1 img_second"> <img src={step_22}/></Box>
          <img src={viewBarActive} className="view_bar" />
          <Box className="box_1" > <img src={step_3_active} /></Box>
          <img src={viewBarInActive} className="view_bar"/>
          <Box className="box_1"> <img src={step_4} /></Box>
          <img src={viewBarInActive} className="view_bar"/>
          <Box className="box_1"> <img src={step_5} style={{height:"33px"}}/></Box>
        </Stepper_customfee_design>

          <Box style={webStyle.mainSubBox}>
            <Typography className="basicDetailsHead">Add your Area of Expertise, Industry Focus and Services</Typography>
            <Box style={webStyle.BoxTwoStyle}>
              <Grid container spacing={3}>

                <Grid item xs={12}>
                  <Typography style={webStyle.labelStyle}>Area of Expertise*</Typography>
                  <InputField style={webStyle.textField} variant="outlined">
                    <OutlinedInput
                      name="skills"
                      placeholder="Add your Area of Expertise"
                      value={this.state.expertiseArea}
                      onChange={this.handleExpertiseInputChange}
                      onKeyPress={this.handleExpertiseKeyPress}
                      error={!!this.state.expertiseAreaError}
                    />
                  </InputField>
                  {this.expertiseAreaError()}
                </Grid>
                {this.state.expertiseSkills.map((skill, index) => (
                  <Box key={index} style={{ margin: '12px 10px 10px 10px' }}>
                    <Box style={webStyle.chipStyle}>
                      <Typography style={webStyle.skillStyle}>{skill}</Typography>
                      <IconButton size="small" onClick={() => this.handleDeleteSkill(skill)}>
                        <CloseIcon style={webStyle.iconStyle} />
                      </IconButton>
                    </Box>
                  </Box>
                ))}

                <Grid item xs={12}>
                  <Typography style={webStyle.labelStyle}>Industry Focus*</Typography>
                  <InputField style={webStyle.textField} variant="outlined">
                     <Select
                      value={this.state.industryFocus}
                      defaultValue=""
                      displayEmpty
                      name="industryFocus"
                      onChange={this.handleIndustrySelect}
                      error={!!this.state.industryFocusError}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <SelectManu_fee_style value="" disabled>
                        Add your Industries
                      </SelectManu_fee_style>
                      {this.state.industryExpertiseArray.map((industry) => (
                        <SelectManu_fee_style key={industry.id} value={industry.name}>
                          {industry.name}
                        </SelectManu_fee_style>
                      ))}
                    </Select>
                  </InputField>
                  {this.industryFocusError()}
                </Grid>
                <Grid container spacing={1} style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {this.state.selectedIndustries.map((industry, index) => (
                    <Box key={index} style={{ margin: '12px 14px' }}>
                      <Box style={webStyle.chipStyle}>
                        <Typography style={webStyle.skillStyle}>{industry}</Typography>
                        <IconButton size="small" onClick={() => this.handleDeleteIndustry(industry)}>
                          <CloseIcon style={webStyle.iconStyle} />
                        </IconButton>
                      </Box>
                    </Box>
                  ))}
                </Grid> 

               <Grid item xs={12}>
                  <Typography style={webStyle.labelStyle}>Services*</Typography>
                  <InputField style={webStyle.textField} variant="outlined">
                    <Select
                      value={this.state.services}
                      defaultValue=""
                      displayEmpty
                      error={!!this.state.servicesError}
                      name="services"
                      onChange={this.handleServicesInputChange}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <SelectManu_fee_style value="" disabled>
                        Add your Services
                      </SelectManu_fee_style>
                      {this.state.servicesArray.map((service) => (
                        <SelectManu_fee_style key={service.id} value={service.name}>
                          {service.name}
                        </SelectManu_fee_style>
                      ))}
                    </Select>
                  </InputField>
                  {this.servicesError()}
                </Grid>

                <Grid container spacing={1} style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {this.state.selectedServices.map((service, index) => (
                    <Box key={index} style={{ margin: '12px 14px' }}>
                      <Box style={webStyle.chipStyle}>
                        <Typography style={webStyle.skillStyle}>{service}</Typography>
                        <IconButton size="small" onClick={() => this.handleDeleteService(service)}>
                          <CloseIcon style={webStyle.iconStyle} />
                        </IconButton>
                      </Box>
                    </Box>
                  ))}
                </Grid> 

              </Grid>
            </Box>
          </Box>

         {this.renderCountryExpertiseDropdown()}


          <Box style={webStyle.mainSubBox}>
            <Typography className="basicDetailsHead">Add your Language</Typography>
            <Box style={webStyle.BoxTwoStyle}>
              <Grid container>
                {this.state.addLanguage.map((item, index) => (
                  <Grid container spacing={3} style={{ marginBottom: '20px' }} key={index} alignItems="center">
                    <Grid item xs={12} sm={6} style={{ marginTop: "10px" }}>
                      <Typography style={webStyle.labelStyle}>Language</Typography>
                      <InputField style={webStyle.textField} variant="outlined">
                        <Select
                          onChange={(event) => this.handleLanguageChange(index, event)}
                          value={item.language}
                          displayEmpty
                          name="language"
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          <SelectManu_fee_style value="" disabled>
                            Choose Language
                          </SelectManu_fee_style>
                          <SelectManu_fee_style value="English">English</SelectManu_fee_style>
                          <SelectManu_fee_style value="Hindi">Hindi</SelectManu_fee_style>
                          <SelectManu_fee_style value="Chinese">Chinese</SelectManu_fee_style>
                          <SelectManu_fee_style value="German">German</SelectManu_fee_style>
                          <SelectManu_fee_style value="French">French</SelectManu_fee_style>
                        </Select>
                      </InputField>
                    </Grid>

                    <Grid item xs={12} sm={6} style={{ marginTop: this.state.proficiencyError[index] ? "30px":"10px" }}>
                      <Typography style={webStyle.labelStyle}>Proficiency</Typography>
                      <InputField style={webStyle.textField} variant="outlined">
                        <Select
                          onChange={(event) => this.handleProficiencyChange(index, event)}
                          value={item.proficiency}
                          displayEmpty
                          error={!!this.state.proficiencyError[index]}
                          name="proficiency"
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          <SelectManu_fee_style value="" disabled>
                            My Level is
                          </SelectManu_fee_style>
                          <SelectManu_fee_style value="Intermediate">Intermediate</SelectManu_fee_style>
                          <SelectManu_fee_style value="Advanced">Advanced</SelectManu_fee_style>
                          <SelectManu_fee_style value="Expert">Expert</SelectManu_fee_style>
                        </Select>
                      </InputField>
                      <Typography>
                      {this.proficiencyError(index)}
                      </Typography>
                    </Grid>

                    {index > 0 && (
                      <Button
                        onClick={() => this.handleRemoveLanguage(index)}
                        style={webStyle.removeTextStyle}
                      >
                        Remove
                      </Button>
                    )}
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box
              style={{
                display: "flex",
                gap: "10px",
                marginTop: "32px",
                marginBottom: "40px",
                cursor: "pointer",
                width: '325px'
              }}
              data-test-id="language-change"
              tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      e.preventDefault();
                      this.handleAddLanguage()
                    }
                  }}
              onClick={this.handleAddLanguage}
            >
              <img src={addBtn}   className="Add_btn_icon"/>
              <Typography style={webStyle.addStyle}>Add another language</Typography>
            </Box>
          </Box>


          <Box style={webStyle.mainSubBox}>
            <Typography className="basicDetailsHead">Add your Fees Structure</Typography>
            <Grid item xs={12} sm={12} style={{ marginTop: "32px" }} >
              <Grid container>

                <Grid item xs={12} sm={10} >
                  <Typography className="subHeaderStyle">
                    Clients will see your project rate on your profile and in search results once you publish your profile.
                  </Typography>
                  <Typography className="subHeaderStyle_sub ">

                    You can adjust your rate every time you submit a proposal.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Box style={{ ...webStyle.feeItem, marginTop: "0px" }}>
                    <TextField
                      variant="outlined"
                      style={webStyle.feeInput}
                      value={this.state.projectRate}
                      defaultValue="$20"
                      onChange={this.handleProjectRateChange}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={10} style={{ marginTop: "32px" }}>
                  <Typography className="subHeaderStyle">
                    Services Fee
                  </Typography>
                  <Typography className="subHeaderStyle_sub ">
                    This helps us run the platform and provide services like payment and customer support.                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Box style={webStyle.feeItem}>
                    <TextField
                      variant="outlined"
                      value={this.state.servicesFee.data[0].percentage}
                      style={webStyle.feeInput}
                      defaultValue="$20"
                      disabled
                      InputProps={{
                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={10} style={{ marginTop: "32px" }}>
                  <Typography className="subHeaderStyle">
                    You’ll get
                  </Typography>
                  <Typography className="subHeaderStyle_sub ">
                    The estimated amount you’ll receive after service fees.
                  </Typography>
                  <Typography className="subHeaderStyle_subtitle ">
                    *Payment gateway and other fees will be deducted from your earnings.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Box style={webStyle.feeItem}>
                    <TextField
                      variant="outlined"
                      style={webStyle.feeInput}
                      defaultValue="$20"
                      value={this.state.projectRate - this.state.projectRate * (this.state.servicesFee.data[0].percentage / 100)}
                      onChange={this.handleEstimatedAmountChange}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Typography className="flagStyle">We do not deduct any tax. You need to pay taxes according to your country.</Typography>
          <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: "48px" }}>
            <Grid item>
              <Back_button variant="outlined" style={webStyle.backButton}
                onClick={this.handleback.bind(this)}
              >
                Back
              </Back_button>
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant="text"
                    className="laterText"
                    onClick={this.doItLater}

                  >
                    I'll do it later
                  </Button>
                </Grid>
                <Grid item>
                  <Next_button variant="contained" style={webStyle.nextButton} onClick={this.handleNext}>
                    Next
                  </Next_button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {this.state.do_It_Later?<>
              <RemindMeDialogbox navigation={this.props.navigation} id={""}/>
            </>:""}
        </Styled_wrapper>
        <FooterWeb />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Stepper_customfee_design = styled(Box)({
  display:"flex",
  justifyContent:"space-between",
  margin:"30px 0 ",
  height:"40px",
  alignItems:"center",
  "@media (max-width: 1050px)": {
    flexWrap:"wrap",
    justifyContent:"flex-start",

  },
  "& .img_stepper":{
    height:"50px",
    width:"100%"
  },
  "& .img_stepper_2":{
    height:"50px",
    width:"92%",
  "@media (max-width: 1050px)": {
    width:"100%",

  }

  },
  "& .box_1":{
    width:"auto",
  },
  "& .img_second":{
    "@media (max-width: 1050px)": {
      width:"auto",
 
  
    },

  }

 
 });
const webStyle = {
  stepperStyle: {
    width: "100%",
    height: "auto",
    maxWidth: "1500px",
    margin: "0 auto",
    display: "block",
  },
  removeTextStyle: {
    fontFamily: "Rubik",
    marginLeft: "auto",
    display: "block",
    color: "red",
    fontSize: "14px",
    textTransform: "inherit" as "inherit",
    marginRight: "10px"
  },

  mainSubBox: {
    marginTop: "40px"
  },
  basicDetailsHead: {
    fontFamily: "Rubik",
    fontSize: "36px",
    fontWeight: 600,
  },
  BoxTwoStyle: {
    border: "1px solid #B1B1B1",
    padding: "28px",
    borderRadius: "12px",
    marginTop: "32px"
  },
  labelStyle: {
    fontFamily: "Rubik",
    fontSize: "16px",
    color: "#0A0A0A",
    fontWeight: 500,
    marginBottom: "8px"
  },
  textField: {
    width: "100%",
    "&.MuiFormLabel-root.Mui-focused ": {
      color: "#6F6E6E !important",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline ": {
      borderColor: "#6F6E6E !important",
      borderRadius: "12px !important"
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px !important"
    }
  },
  chipStyle: {
    background: "#E4F5FF",
    borderRadius: "26px",
    display: "flex",
    padding: "8px",
    gap: "6px",
    justifyContent: "center",
  },
  skillStyle: {
    color: '#0A0A0A',
    padding: "2px 8px"
  },
  iconStyle: {
    color: '#0A0A0A',
    fontSize: '20px',
  },
  addStyle: {
    fontFamily: "Rubik",
    fontSize: "24px",
    fontWeight: 500,
    color: "#364BA0"
  },
  subHeaderStyle: {
    fontFamily: "Rubik",
    fontSize: "20px",
    fontWeight: 600,
    color: "#0A0A0A"
  },
  feeContainer: {
    marginTop: '30px',
    backgroundColor: '#FFFFFF',
    padding: '20px',
    borderRadius: '8px',
  },
  feeItem: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '32px',
  },

  feeInput: {
    width: '250px',
  },
  noteText: {
    backgroundColor: '#FFEB3B',
    padding: '8px',
    borderRadius: '4px',
    marginTop: '20px',
    fontSize: '14px',
    color: '#666666',
  },
  backButton: {
    borderColor: '#364BA0',
    color: '#364BA0',
    height: "40px",
    padding: "22px",
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 500
  },
  nextButton: {
    backgroundColor: '364BA0',
    color: '#fff',
    height: "40px",
    padding: "22px",
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 500
  },
  laterText: {
    color: '#364BA0',
    textTransform: 'uppercase' as 'uppercase',
    textDecoration: 'underline' as 'underline',
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 500
  },

  formError: {
    color: "red",
    fontSize: 14,
    fontFamily: "'Inter', sans-serif",
    marginTop: "4px"
  },
};
const SelectManu_fee_style = styled(MenuItem)({


  "&:hover": {
    backgroundColor: "364BA0",
    color:"white",
  },
});
const Styled_wrapper = styled(Box)({

  padding: "18px 48px",
  maxWidth: "1500px",
  margin:"auto",
  "@media(max-width:700px)": {
    padding: "12px 25px",
    },

  

  '& .basicDetailsHead': {
    fontFamily: "Rubik",
    fontSize: "32px",
    fontWeight: 600,
    "@media(max-width:700px)": {
    fontSize: "28px",
       
    },
    "@media(max-width:500px)": {
      fontSize: "24px",
      },
    },

   "& .Add_btn_icon":{
      height: "34",
      width:"34",

      maxWidth: "150px", 
         display: "block",  
         "@media(max-width:500px)": {
            height: "30",
            width:"30"
          },
   },
   "& .laterText":{
    color: '#364BA0',  
    textTransform: 'uppercase' as 'uppercase',
    textDecoration: 'underline' as 'underline',
    fontFamily:"Rubik",
    fontSize:"18px",
    fontWeight:500,
    "@media(max-width:500px)": {
      fontSize:"15px",

    },
  },
  "& .subHeaderStyle": {
    fontFamily: "Rubik",
    fontSize: "20px",
    fontWeight: 600,
    color: "#0A0A0A",
    "@media(max-width:500px)": {
      fontSize:"14px",

    },
  },

  "& .subHeaderStyle_sub ":{
    fontFamily: "Rubik",
    fontSize: "20px",
    fontWeight: 400,
    color: "#0A0A0A",
    "@media(max-width:500px)": {
      fontSize:"14px",
      padding :"10px 0"

    },
  },
  "& .subHeaderStyle_subtitle ":{
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 400,
    color: "#595959",
    "@media(max-width:500px)": {
      fontSize:"8px",
      padding :"10px 0"

    },
  },
  "& .flagStyle": {
    marginTop: "24px",
    background: "#FEF3C7",
    color: "#D97706",
    padding: "10px",
    width: "443px",
    borderLeft: "4px solid #D97706",
    borderRadius: "4px",
    fontSize: "12px",
    fontFamily: "Rubik",
    fontWeight: 500,
    "@media(max-width:500px)": {
      width: "auto",
    },
  },

});
const InputField = styled(FormControl)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "12px",
  },
  "@media(max-width:500px)": {
    "&  .MuiOutlinedInput-input" :{
        padding: "11.5px 12px",
      }
    },

});
const Back_button = styled(Button)({
  borderColor: '#364BA0',
    color: '#364BA0',
    height:"40px",
    padding:"22px",
    fontFamily:"Rubik",
    fontSize:"18px",
    fontWeight:500,
    borderRadius:"10px",
    "@media(max-width:500px)": {
     padding:"14px"

    },
    "& .MuiButton-label":{
      fontSize:"14px"
    }

});
const Next_button = styled(Button)({
  backgroundColor: '364BA0',
    color: '#fff',
    height:"40px",
    padding:"22px",
    fontFamily:"Rubik",
    fontSize:"18px",
    fontWeight:500,
    borderRadius:"10px",
    "@media(max-width:500px)": {
     padding:"14px"

    },
    "& .MuiButton-label":{
      fontSize:"14px"
    }

});
// Customizable Area End