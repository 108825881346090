import React from 'react';
import { Props } from './ViewJobController.web';
// Customizable Area Start
import ClientNavBar from './Components/ClientNavBar.web';
import FooterWeb from './Components/Footer.web';
import {
  Box,
  Button,
  ThemeProvider,
  Typography,
  createTheme,
  styled,
} from '@material-ui/core';
import HomePageController from './ClientHomePageController.web';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { dotted, homepage4,oval } from './assets';
import Avatar from '@material-ui/core/Avatar';
import { greeting, wrappedFormattedStringIST } from '../../../components/src/ActiveStep0.web';


const theme = createTheme({
  palette: {
    primary: {
      main: '#206FC4'
    }
  },
  typography: {
    fontFamily: 'Inter'
  }
});



// Customizable Area End
export default class HomePage extends HomePageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  render() {
    // Customizable Area Start
const{homepageData} = this.state
const getSectionData = (sectionId: any)=>{
  return homepageData.find((sectionData: { attributes: { section: any; }; })=>Number(sectionData.attributes.section)==sectionId)?.attributes
}
    return (
      //Merge Engine DefaultContainerj
      <ThemeProvider theme={theme}>
        <ClientNavBar />
        <Main_wrapper>
        <Box>
          <Box
            sx={webStyle.flexColumn}
            alignItems={'baseline'}
            gridColumnGap={'8px'}
            ml={'5%'}
            mb={'5rem'}
            mt={'48px'}
          >
            <Top_header
            
              marginBottom={0}
              alignItems={'baseline'}
            >
              <Typography
            className='username'
              >
              {greeting}, {this.state.firstName}!
              </Typography>
              <Typography
                style={{
                  ...webStyle.description,
                  marginLeft: '1rem',
                  marginBottom: '0',
                }}
              >{wrappedFormattedStringIST}
              </Typography>
              </Top_header>

            <Typography
              style={{
                ...webStyle.text,
                marginBottom: '0',
                fontWeight: 400,
                fontSize: '18px',
              }}
            >
              Have a nice day ahead
            </Typography>
          </Box>
          <FLexRow style={{height:"auto"}}>
            <WorkSpaceContainer>
              <Typography
                style={{
                  ...webStyle.text,
                  fontWeight: 600,
                  fontSize: '40px',
                  letterSpacing: '-0.5px',
                  marginBottom:"0rem"
                }}
              >
                Your Workspace
              </Typography>
              <Typography
                style={{
                  ...webStyle.text,
                  fontSize: '20px',
                  fontWeight: 500,
                  color: '#206FC4',
                  alignItems: 'center',
                  display: 'flex',
                  marginBottom:"0rem",
                  marginLeft: '20px'
                 
                }}
                onClick={this.handleJobPost}
                data-testid="job-post"
              >
                View all jobs
                <ArrowForwardIcon style={{marginLeft: '10px'}}/>
              </Typography>
            </WorkSpaceContainer>

            <PostJobButton
              variant="contained"
              color="primary"
              onClick={this.handlePostJob}
              data-testid="post-job"
              style={{marginRight:"2rem"}}
            >
              Post a Job
            </PostJobButton>
          </FLexRow>
          {/* section 1 */}
          {/* <Box sx={webStyle.flexRow}> */}
          <Image_wrapper>
            <img src={getSectionData(1)?.image} className='image_left' />
            <CenterBgImage>
              <img src={homepage4} />
              <img src={homepage4}  />
            </CenterBgImage>
            <img src={getSectionData(1)?.secondary_image} 
            className='image_left image_right' 
            />
          </Image_wrapper>
          {/* section 2 */}
          {/* <Box sx={webStyle.flexRow} ml={'5%'} marginTop={"110px"}> */}
          <Box className="flexRow" >

            <Box flex={0.45}>
              <div style={webStyle.text}  dangerouslySetInnerHTML={{__html:
                getSectionData(2)?.title}}/>
              <div style={webStyle.description} dangerouslySetInnerHTML={{__html:
              getSectionData(2)?.sub_title}
              }/>
              <Button variant="contained" color="primary" onClick={()=>window.open(getSectionData(2)?.button_url,"_blank")} data-testid={"joinMeeting"}>
              {getSectionData(2)?.button_text}
              </Button>
            </Box>

            <img
              src={ getSectionData(2)?.image}
              className='section2_img'
              height="100%"
              style={{ display: 'flex', flex: 0.48,borderRadius:"55px 0px 0px 55px",objectFit:"cover" }}
            />
          </Box>
         {/* section 3 */}
         <Dotted_img>

         <img src={dotted}  
         
         className='dotted_img'/>
         </Dotted_img>
          <Section_3
            sx={webStyle.flexRow}
            style={{  }}
          >
            <img
              src= {getSectionData(3)?.image}
              className='section2_img'
              height="100%"
              style={{ display: 'flex', flex: 0.45 ,borderRadius:"0px 55px 55px 0px",objectFit:"cover",zIndex:11}}
            />
            <Box flex={0.45}>
              <div style={webStyle.text} dangerouslySetInnerHTML={{__html:
              getSectionData(3)?.title}
              }/>
              <div style={webStyle.description}  dangerouslySetInnerHTML={{__html:
              getSectionData(3)?.sub_title}
              }/>
              <Button variant="contained" color="primary" onClick={this.handleMyJobs} data-testid={"FindWork"}>
              {getSectionData(3)?.button_text}

              </Button>
            </Box>
          </Section_3>
           {/* section 4 */}

          <Section_4 
          // sx={webStyle.flexRow} ml={'5%'}  marginTop={"110px"}
          >
            <div
              style={{
                ...webStyle.text,
                marginBottom: 0,
                display: 'flex',
                flex: 0.5
              }}
              dangerouslySetInnerHTML={{__html:
            
               getSectionData(4)?.title}
            }/>
            <div
              style={{
                ...webStyle.description,
                marginBottom: '0',
                display: 'flex',
                flex: 0.5
              }}
              dangerouslySetInnerHTML={{__html:
               getSectionData(4)?.sub_title}
            }/>
          </Section_4>
          <CardContainer>
            {getSectionData(4)?.content_cards?.data.map((item:any,index:number)=>
            <Box
              sx={{
                ...webStyle.infoCard,
                ...webStyle.flexColumn,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <AvatarView>{index+1}</AvatarView>
              <div
                style={{
                  ...webStyle.description,
                  marginBottom: '0',
                  color: '#fff',
                  opacity: 1
                }}
                dangerouslySetInnerHTML={{__html:
              
                item?.attributes?.sub_title}
            }/>
            </Box>)}
            
          </CardContainer>
          {/* section 5 */}

          <Section_5  >
            <img
            className='section2_img'
              src={ getSectionData(5)?.image}
              height="100%"
              style={{ display: 'flex', flex: 0.45 ,borderRadius:"0px 55px 55px 0px",objectFit:"cover",zIndex:10}}
            />
            <Box flex={0.45}>
              <div style={webStyle.text}  dangerouslySetInnerHTML={{__html:
              getSectionData(5)?.title}
              }/>
              <div style={webStyle.description}  dangerouslySetInnerHTML={{__html:
              getSectionData(5)?.sub_title}
              }/>
              <Button variant="contained" color="primary" onClick={()=>window.open(getSectionData(5)?.button_url,"_blank")} data-testid="UpdateJobPreference">
              {getSectionData(5)?.button_text}
              </Button>
            </Box>
          </Section_5>
        {/* section 6 */}
        <OverLAy_img>
        <img src={oval}
        className='overlay_img'
        style={{bottom:"-124px",position:"relative",opacity:"0.75",marginTop:"-360px"}}
        />
        </OverLAy_img>
          
          <Section_6 >
            <Box flex={0.4} ml={'5%'} className='section_6_text'>
              <div style={webStyle.text} dangerouslySetInnerHTML={{__html:
              getSectionData(6)?.title}
              }/>
              <div style={webStyle.description} dangerouslySetInnerHTML={{__html:
              getSectionData(6)?.sub_title}
              }/>
              <Button variant="contained" color="primary" onClick={()=>window.open(getSectionData(6)?.button_url,"_blank")} data-testid="contact">
              {getSectionData(6)?.button_text}
              </Button>
            </Box>

            <img
              src= {getSectionData(6)?.image}
            className='section2_img'
              height="100%"
              style={{ display: 'flex', flex: 0.45 ,borderRadius:"55px 0px 0px 55px",objectFit:"cover"}}
            />
          </Section_6>
        {/* section 7 */}
     

          <Box
            sx={webStyle.flexColumn}
            alignItems={'baseline'}
            gridColumnGap={'8px'}
            ml={'5%'}
            mb={'2rem'}
            marginTop={"110px"}
          >
            <div
              style={{
                ...webStyle.text,
                marginBottom: '-30',
                fontSize: '36px',
                lineHeight: '62px' /* 172.222% */,
                letterSpacing: '-0.5px'
              
              }}
              dangerouslySetInnerHTML={{__html:
              getSectionData(7)?.title}
            }/>

            <div style={{ ...webStyle.description, marginBottom: '0' }}
            dangerouslySetInnerHTML={{__html:
            getSectionData(7)?.sub_title}
            }/>
          </Box>

           <Last_section>   
         <Image_wrapper>
            <img src={getSectionData(7)?.image} className='image_left last_section' />
            <CenterBgImage>
              <img src={homepage4} />
              <img src={homepage4}  />
            </CenterBgImage>
            <img src={getSectionData(7)?.secondary_image}
            className='image_left image_right' 
            />
          </Image_wrapper>
          </Last_section>


        
        </Box>
        </Main_wrapper>
        <FooterWeb />
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const PostJobButton = styled(Button)({
    background: "#206FC4",
    fontSize: "16px",
    lineHeight: "19px",
    letterSpacing: "0px",
    color: "white",
    borderRadius: "10px",
    width: " 173px",
    height: " 56px",
    marginRight:"1rem",
    fontFamily:"'Inter', sans-serif",
    fontWeight:"bold",
    cursor:"pointer",
    '&:hover': {
      width: " 173px",
      height: " 56px",
      borderRadius: "10px",
      color:"#206FC4",
      border:"1px solid #206FC4",
      backgroundColor: "#fff",
    }
  })

const WorkSpaceContainer = styled(Box)({
  display: "grid",
  gridAutoFlow: "column",
  gridColumnGap: "20px",
  "@media(max-width:700px)": {
    gridAutoFlow: "row",
  }
}); 

const FLexRow = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '5rem',
    marginLeft: "5%",
    "@media(max-width:700px)":{
      flexDirection: "column",
      alignItems: "flex-start"
    },
    height:"434px",
}); 

const UserName =styled(Typography)({
    color: '#0A0A0A',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 100,
    marginBottom: '1rem',
    opacity: 0.9,
    fontSize: "36px",
    lineHeight: "62px",
    letterSpacing: '-0.5px',
    "@media(max-width:530px)":{
      fontSize: "22px"
    }
});


const CenterBgImage= styled(Box)({
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  zIndex: -1,
  margin: '0 -120px',
  flexDirection: 'column',
  
  "@media(max-width:992px)":{
    margin: "0 -181px"
   
  },
  "@media(max-width:768px)":{
    display: 'none',
    width:"auto",
  },

})

const CardContainer =styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2rem',
    marginLeft: "5%",
    marginRight:"2%",
    "@media(max-width:957px)":{
    flexDirection: "column",
    gap: "30px"
    }
})

const AvatarView = styled(Avatar)({
  background: '#FFF',
  width: '100px',
  height: '100px',
  marginBottom: '1.5rem',
  color: '#0A0A0A',
  fontSize: '28px',
  fontWeight: 100,
  "@media(max-width:882px)":{
    width: '70px',
    height: '70px',
    }
})
const Image_wrapper= styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '2rem',
  gap:"5rem",
  "@media (max-width: 700px)": {
    display:"block",
  },
  "& .image_left":{
    display: 'flex', 
    flex: '0.45',
    width:"600",
     maxHeight:"434",
    borderRadius:"0px 55px 55px 0px",
    objectFit:"cover",
    dispaly:"none",
  
    "@media (max-width: 1150px)": {
      width:"484",
  
    },
    "@media (max-width: 992px)": {
      width:"400",
    },
    "@media (max-width: 768px)": {
      width:"auto", 
    },
  },
  "& .image_right":{
    borderRadius:"0",

    "@media (max-width: 768px)": {
      width:"auto", 
      display:"none"

    },
  
  }

});
const Top_header=styled(Box)({
  height:"auto",
  marginBottom: '0' ,
alignItems:'baseline',
"@media (max-width: 700px)": {
  display:"block",

},
"& .username":{
  color: '#0A0A0A',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 100,
  opacity: 0.9,
  lineHeight: '62px' ,
  fontSize: '36px',
  marginBottom: '8px',
  "@media (max-width: 700px)": {
    fontSize: '28px',
    lineheight: "40px",
  
  },
}
})

const Main_wrapper=styled(Box)({
"& .flexRow": {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '2rem',
  marginLeft: "5%",

  "@media (max-width: 768px)": {
    display:"block",
    padding:"20px",
 },
 "& .section2_img":{
  width:"684", maxHeight:"434",

 "@media (max-width: 1150px)": {
  width:"484",

},
"@media (max-width: 1050px)": {
  width:"400",

},
"@media (max-width: 992px)": {
  marginTop:"20px",
 
},
"@media (max-width: 768px)": {
  width:"auto", 
 
},


}
}

});

const Dotted_img = styled(Box)({
 "& .dotted_img":{
  position:"relative",
  bottom:"-68px",
  zIndex:10,
  "@media (max-width: 600px)": {
    display:"none"
 },

}
});
const Section_3 = styled(Box)({
  backgroundColor: '#E6EBEF',
   padding: '4rem 0',
   "@media (max-width: 768px)": {
   padding:"30px",
   display:"block",
 },

 "& .section2_img":{
  width:"684", maxHeight:"434",


 "@media (max-width: 1150px)": {
  width:"484",

},
"@media (max-width: 1050px)": {
  width:"400",

},
"@media (max-width: 992px)": {
  marginTop:"20px",
 
},
"@media (max-width: 768px)": {
  width:"auto", 
 
},


}
 
});
const Section_4 = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '2rem',
  padding:" 0 30px",
  marginTop:"110px",
   "@media (max-width: 600px)": {
    display: 'block',
    padding:"20px",
    marginTop:"0"
 
 },
});
const Section_5 = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '2rem',
  marginTop:"110px",
   "@media (max-width: 768px)": {
    display: 'block',
    padding:"20px",
  marginTop:"0px",
 },
 "& .section2_img":{
  width:"684", maxHeight:"434",


 "@media (max-width: 1150px)": {
  width:"484",

},
"@media (max-width: 1050px)": {
  width:"400",

},
"@media (max-width: 992px)": {
  marginTop:"20px",
 
},
"@media (max-width: 768px)": {
  width:"auto", 
},
},
});
const OverLAy_img = styled(Box)({
  "& .overlay_img":{
    bottom:"-124px",position:"relative",opacity:"0.75",marginTop:"-360px",
    "@media (max-width: 768px)": {
      display: 'none',
   },
  }

})
const Section_6 = styled(Box)({
  backgroundColor: '#E6EBEF', padding: '4rem 0' ,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '2rem',
  "@media (max-width: 768px)": {
    display: 'block',
    padding:"20px",
    "& .section_6_text":{
      marginBottom:"30px"
    }
 
 },
 "& .section2_img":{
  width:"684", maxHeight:"434",


 "@media (max-width: 1150px)": {
  width:"484",

},
"@media (max-width: 1050px)": {
  width:"400",

},
"@media (max-width: 992px)": {
  marginTop:"20px",
 
},
"@media (max-width: 768px)": {
  width:"auto", 
},
},
});

const Last_section = styled(Box)({
"& .last_section":{
  borderRadius:"0",
}
})


const webStyle = {
  text: {
    color: '#0A0A0A',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '28px',
    fontWeight: 100,
    marginBottom: '1rem',
    opacity: 0.9
  },
  description: {
    color: '#0A0A0A',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '18px',
    fontWeight: 400,
    marginBottom: '2rem',
    opacity: 0.6
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2rem'
  },
  infoCard: {
    borderRadius: '24px',
    background: '#206FC4',
    color: '#fff',
    flex: 0.25,
    padding: "20px 20px 20px 20px"
  },
  avatar: {
    background: '#FFF',
    width: '100px',
    height: '100px',
    marginBottom: '1.5rem',
    color: '#0A0A0A',
    fontSize: '28px',
    fontWeight: 100
  },
  GridColumn: {
    display: 'grid',
    gridAutoFlow: 'column',
    marginBottom: '2rem'
  },
  CenterBgImage: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    zIndex: -1,
    margin: '0 -120px',
    flexDirection: 'column'
  }
};
// Customizable Area End
