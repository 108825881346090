  // Customizable Area Start
import { IBlock } from 'framework/src/IBlock';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';
// Customizable Area Start
// Customizable Area End
export const webConfigJSON = require('./config.js');


export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  activeButton: string;
  selectedTab:number|string;

  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ActiveContractsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      activeButton: "offers",
      selectedTab:0
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  // Customizable Area Start
  goToResolvePage = () => {
    this.props.navigation.navigate("ResolveDispute")
  }
  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ selectedTab: newValue });
  };
  // Customizable Area End
}
// Customizable Area End

