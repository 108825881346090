// Customizable Area Start
import React, { ChangeEvent, Component, ReactElement } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Button, Divider, InputAdornment, TextField,Popover, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { profile ,messageIcon} from '../assets';
import { clientlogo } from './assets';
import SearchIcon from '@material-ui/icons/Search';
import storage from 'framework/src/StorageProvider';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Message } from 'framework/src/Message';
import MessageEnum, { getName } from 'framework/src/Messages/MessageEnum';
import { runEngine } from 'framework/src/RunEngine';
import { IBlock } from 'framework/src/IBlock';

const configJSON = require('../config.js');

const StyledMenuItem = withStyles(() => ({
  root: {
    color: '#000',
    fontSize: '12px',
    fontWeight: 500,
    width: 'inherit',
    height: '32px',
    '&:focus': {
      backgroundColor: 'aliceblue',
      borderLeft: '2px solid blue',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: 'white'
      }
    },
    '&:hover': {
      backgroundColor: 'aliceblue',
      borderLeft: '2px solid blue'
    }
  }
}))(MenuItem);
// Customizable Area End
// Customizable Area Start
interface OptionItem {
  options: any;
  label: string;
  path?: string;
  profileAnchorEl:null|HTMLDivElement
  currentStatus:number;
  accountHolderName:string |null;
  updateOnlineStatusId:string;
  getProfileStatusId:string; 
  isTokenExpired:boolean;

}
// Customizable Area End

class ClientNavBar extends Component {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as unknown as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  [x: string]: any;
  state = {
    anchorEl: null,
    isFromHomePage: false, 
    searchValue: '',
    profileAnchorEl:null,
    currentStatus:0,
    accountHolderName:"",
    updateOnlineStatusId:"",
    getProfileStatusId:"",
    isTokenExpired:false,

  };
  getColorValue = (statusVal:number)=>{
    const activeBtn =  this.state.currentStatus == statusVal
    let   colorItem = {
      color:"#ffffff",
      backgroundColor:"#2275CD"
    };
    if(!activeBtn){
           
          colorItem= {
              color:"#2275CD",
              backgroundColor:"#ffffff"
            }

    }
      return colorItem;
 }
  
  handleClick = (event: any) => {
    if (Boolean(this.state.anchorEl)) {
      // If the same item is clicked again, close the popover
      this.setState({ anchorEl: null });
    } else {
      this.setState({ anchorEl: event.currentTarget });
    }
  };
  updateOnlineStatus = async(currentStatus:number) => {
    const updateOnlineStatusRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      'Content-Type': configJSON.apiContentType,
      token: await storage.get('authToken')
    };
    

    this.state.updateOnlineStatusId = updateOnlineStatusRequest.messageId;
    updateOnlineStatusRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateClientProfileStatusEndPoint +`?online_status=${currentStatus===0?"online":"away"}`
        
    );
    updateOnlineStatusRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    updateOnlineStatusRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethodType
    );

    runEngine.sendMessage(
      updateOnlineStatusRequest.id,
      updateOnlineStatusRequest
    );
  };

  getProfileStatus =async () => {
    const getProfileStatusRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      'Content-Type': configJSON.apiContentType,
      token: await storage.get('authToken')
    };
    

    this.state.getProfileStatusId = getProfileStatusRequest.messageId;
    getProfileStatusRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getClientProfileStatusEndPoint
        
    );
    getProfileStatusRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getProfileStatusRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(
      getProfileStatusRequest.id,
      getProfileStatusRequest
    );
  };

  handleResponse=(responseJson: {errors?:any; exception?: string; current_user: { online_status: string; }; })=>{
    if(responseJson.exception?.includes("#<JWT") ||responseJson.errors?.some((error: any) => error.toLowerCase().includes("token"))){
      this.setState({isTokenExpired: true});

    }else {
      this.setState({ currentStatus: responseJson.current_user.online_status =="away"?1:0 });

    }
  }
  async componentDidMount() {
    let accountHolderName = await storage.get("accountHolderName")
    this.setState({
      accountHolderName:accountHolderName 
    });
    this.getProfileStatus()
  }
  handleredirection = async () => {
    const first_login = await storage.get("is_first_time_login");
  
    if (first_login !== "null") {
      window.location.pathname = '/postjob';
    } else {
      window.location.pathname = '/JobPosting';
    }
  };
  
  handleRedirect = (path: any) => {
    this.setState({ anchorEl: null });
    if (path === '/postjob') {
      this.handleredirection();
    } else if (path) {
      window.location.pathname = path;
    }
  };
  

  handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };
  keyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
        const { searchValue } = this.state;
        storage.set('searchedValue', searchValue); // Store search value
        this.handleRedirect('/searchtalent'); // Navigate to search talent page
    }
};

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchValue: event.target.value });
  };
  handleClose = () => {
    this.setState({ profileAnchorEl: null });
  };
  handleProfileClick = (event:React.MouseEvent<HTMLImageElement, MouseEvent>)=> {
    this.setState({profileAnchorEl:event.currentTarget});
  };
  handleLogout=(componentName: any)=>{
    storage.remove("authToken");
    storage.remove("accountId");
    storage.remove("accountHolderName");
    storage.remove("userType")
    this.handleRedirect(componentName)
  }
   async receive(_from: string, _message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === _message.id) {
      const requestCallId = _message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if(this.state.getProfileStatusId === requestCallId){
        const responseJson = _message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
       
        if(responseJson){
          this.handleResponse(responseJson);
          storage.set("isTwofactorEnableClient", responseJson?.current_user?.is_twofactor_enable);
          storage.set("clientLastPasswordUpdatedAt", responseJson?.last_password_updated_at);

        } 
      }
    }
  }
  // Customizable Area End
  // Customizable Area Start
  render(): ReactElement {
    const open = Boolean(this.state.profileAnchorEl);    
    return (
      <div style={styles.root}>
        <AppBar position="static">
          <Toolbar style={styles.toolBar}>
            <Box style={styles.boxStyle}>
            <img src={clientlogo} style={{width:"229px",height:"36px",objectFit:"cover", marginRight:"22px"}} onClick={() => this.handleRedirect('/clienthomepage')} data-test-id='handleRedirect'/>

              <Box style={styles.boxStyle}>
                {configJSON.navbarMenu.map(
                  (item: OptionItem, index: number) => (
                    <Typography
                      key={item.label}
                      variant="h6"
                      aria-describedby={`popover-${item.label}`}
                      onMouseEnter={
                        item.options
                          ? (
                              event: React.MouseEvent<HTMLElement, MouseEvent>
                            ) => {
                              this.handlePopoverOpen(event);
                            }
                          : undefined
                      }
                      onMouseLeave={
                        item.options ? this.handlePopoverClose : undefined
                      }
                      onClick={
                        item.options
                          ? this.handleClick
                          : () => this.handleRedirect(item.path)
                      }
                      style={styles.menuItem}
                      data-testid={`navbar-option-${index}`}
                    >
                      {item.label}
                      {item.options &&
                        (Boolean(this.state.anchorEl) &&
                        ((this.state
                          .anchorEl as unknown) as HTMLElement).getAttribute(
                          'aria-describedby'
                        ) === `popover-${item.label}` ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        ))}

                      {item.options && (
                        <Popper
                          key={`popover-${item.label}`}
                          id={`popover-${item.label}`}
                          open={
                            Boolean(this.state.anchorEl) &&
                            ((this.state
                              .anchorEl as unknown) as HTMLElement).getAttribute(
                              'aria-describedby'
                            ) === `popover-${item.label}`
                          }
                          anchorEl={this.state.anchorEl}
                          placement="bottom"
                          disablePortal={true}
                          modifiers={{
                            flip: {
                              enabled: true
                            },
                            preventOverflow: {
                              enabled: true,
                              boundariesElement: 'scrollParent'
                            },
                            arrow: {
                              enabled: true,
                              element: this.state.anchorEl
                            }
                          }}
                          style={{
                            background: '#FFF',
                            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                            zIndex: 1,
                            padding: '10px 5px',
                            width: 'inherit'
                          }}
                        >
                          {item.options.map(
                            (option: OptionItem, index: number) => (
                              <StyledMenuItem
                                key={option.label}
                                onClick={() => this.handleRedirect(option.path)}
                                style={styles.options}
                                data-testid={`menu-option-${index}`}
                              >
                                {option.label}
                              </StyledMenuItem>
                            )
                          )}
                        </Popper>
                      )}
                    </Typography>
                  )
                )}
              </Box>
            </Box>

            <Box style={styles.sectionRight}>
              {!this.state.isFromHomePage && (
                <Avatar
                  alt="image"
                  style={{
                    ...styles.imgAvatar,
                    overflow: 'visible',
                    height: '45px',
                    width: '45px'
                  }}
                  src={messageIcon}
                />
              )}
              <Avatar
                  alt="image"
                  src={profile}
                  data-testid={"profileImage"}
                  style={{
                    ...styles.imgAvatar,
                   
                    height: '45px',
                    width: '45px'
                  }}
                  onClick={this.handleProfileClick} // onClick={() => this.handleRedirect('settings')}
                />
                 <Popover
              data-testid="account_card"
                open={open}
                anchorEl={this.state.profileAnchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                
              
              >
               <Box style={{padding: '7px 0',display: 'grid',gap: '7px',minWidth:"245px"}}>
               <Box
               display={"flex"}
               flexDirection={"column"}
               justifyContent={"center"}
               alignItems={"center"}
               style={{paddingTop:"10px"}}>
                
                <Avatar

                alt="image"
                src={profile}
                style={{
                
                  height: '88px',
                  width: '88px',
                  borderRadius: '50%'
                }}
                
              />
              <Typography style={{fontWeight: 500,fontFamily: 'Inter',fontSize:"16px",marginTop:"10px",padding:"0 15px",color:"#181818"}}>{this.state.accountHolderName}</Typography> 
              <Typography style={{fontWeight:400,fontFamily: 'Inter',fontSize:'11px',color:"#676767"}}>Client</Typography>          
              </Box>
              {/* online/offline byttons */}
   <Box
      style={{
        border: "2px solid #d9d9d9",
        borderRadius: "10rem",
        display:"flex",
        justifyContent: "center",
        margin:"0px 10px"
      }}
    >
      <Button
        variant={this.state.currentStatus === 0 ? "contained" : "text"}
        size="medium"
        style={{
          margin: 0,
          padding:"10px",
          borderRadius: "10rem",
          backgroundColor:this. getColorValue(0).backgroundColor,
          fontWeight: 500,
          fontFamily: 'Inter',
          textTransform:"none",
          color: this. getColorValue(0).color,
          flex:0.5,
        }}
        data-testid="prf-online"
        onClick={() => {this.setState({currentStatus:0});this.updateOnlineStatus(0)}}
      >
        Online
      </Button>
      <Button
        variant={this.state.currentStatus === 1 ? "contained" : "text"}
        onClick={() => {this.setState({currentStatus: 1});this.updateOnlineStatus(1)}}
        size="medium"
        style={{
          margin: 0,
          padding:"10px",
          borderRadius: "10rem",
          backgroundColor: this. getColorValue(1).backgroundColor,fontWeight: 500,
          fontFamily: 'Inter',
          textTransform:"none",
          color: this. getColorValue(1).color,
          flex:0.5
        }}
        data-testid="prf-invisible"
            >
          Invisible
        </Button>
        </Box>
              <Box display={"flex"} flexDirection={"column"} style={{gap:"10px",margin:"10px 20px"}}>
              <Button startIcon={<AccountCircleIcon/>}style={styles.actionButton} >
              <Box display={"flex"} flexDirection={"column"} >
              <Typography  style={{...styles.actionButtonContent,textTransform:"none"}}>{this.state.accountHolderName}</Typography>
              <Typography  style={{...styles.actionButtonContent,fontSize:"11px",color:"#676767",textTransform:"none",textAlign:"left"}}>Freelancer</Typography>
              </Box>
              </Button>
              <Button  data-testid="prf-setting" startIcon={<SettingsIcon/>}  onClick={() => this.handleRedirect('settings')} style={styles.actionButton} >
              
                <Typography  style={{...styles.actionButtonContent,textTransform:"none"}}>Settings</Typography>
                </Button>
                <Button   data-testid="prf-logout" startIcon={<ExitToAppIcon/>} onClick={() => this.handleLogout('/landingpage')} style={styles.actionButton}>
               
                <Typography style={{...styles.actionButtonContent,textTransform:"none"}}>Log out</Typography>
                </Button>    
               </Box>
               </Box> 
              </Popover>
            </Box>
          </Toolbar>
        </AppBar>
        <div>
    <Dialog
      open={this.state.isTokenExpired}
      data-testid='handleClose'
    
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Session expired"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        Please log in again as your session has ended due to inactivity.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button data-testid='handleCloseDialog' onClick={()=>this.handleLogout('LandingPage')} color="primary" autoFocus>
         Ok
        </Button>
      </DialogActions>
    </Dialog>
  </div>
      </div>
    );
  }
}

export default ClientNavBar;

// Customizable Area End
// Customizable Area Start
const styles = {
  root: {},
  sectionRight: {
    display: 'flex',
    alignItems: 'center',
    flex: 0.75,
    justifyContent: 'end',
    gap: '20px'
  },
  title: {
    color: '#0A0A0A',
    fontFamily: 'poppins',
    fontSize: '26px',
    fontWeight: 275,
    lineHeight: 'normal',
    letterSpacing: '-1.244px',
    paddingRight: '1rem'
  },
  menuItem: {
    marginRight: '10px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '2rem',
    // textAlign: 'left',
    color: '#4A4A4A',
    fontSize: '20px',
    fontWeight: 500,
    fontFamily: 'Inter'
  },
  options: {
    color: '#000',
    fontSize: '12px',
    fontWeight: 500
  },
  toolBar: {
    backgroundColor: ' #F2F5F7',
    height: '5rem',
    display: 'flex',
    justifyContent: 'space-between'
  },
  imgAvatar: {
    cursor: 'pointer',

    '&.MuiAvatar-img': {
      borderLeft: '2px solid #E6E9EA',
      lineHeight: '5rem',
      paddingRight: '1rem',
      overflow: 'visible',
      width: '45px',
      height: '45px'
    }
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '2rem',
    borderRadius: '20px !important'
  },
  searchInput: {
    borderRadius: '15rem',
    marginLeft: '1rem',
    width: '25rem'
  },
  imgAvatarBox: {
    display: 'flex',
    gap: '30px',
    paddingLeft: '1rem'
  },
  boxStyle: {
    display: 'flex',
    alignItems: 'center',
    height: 'inherit',
    cursor: 'pointer'
  },
  actionButton:{
    cursor:"pointer",
    justifyContent:"left",
    
  },
  actionButtonContent:{
    marginLeft:"15px",
    fontWeight: 400,
    fontFamily: 'Inter',
    fontSize:"15px",
    color:"#181818",
    
  },
};
// Customizable Area End
