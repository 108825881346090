//Customizable Area Start
import React from "react";
//Customizable Area Start
import { Box, Chip, CircularProgress, Grid, Tab, Tabs, Typography, styled } from "@material-ui/core";
import FooterWeb from "./Components/Footer.web";
import ViewProposalsController, { Props } from "./ViewProposalsController.web";
export const webConfigJSON = require("./config");
import Rating from '@material-ui/lab/Rating';
import AllProposals from "./AllProposals.web";
import { locationIcon } from "./Components/assets";
import ClientNavBar from "./Components/ClientNavBar.web";



const CustomTabs = styled(Tabs)({
  '& .MuiTab-textColorPrimary-25.Mui-selected': {
    fontWeight: "600",
    fontSize: "22px",
    textTransform: "inherit"
  },
  '& .MuiTab-textColorPrimary-25': {
    fontWeight: "600",
    fontSize: "22px",
    textTransform: "inherit"
  },
  '& .PrivateTabIndicator-root-31': {
    height: "4px"
  }

})
const CustomSide = styled(Box)({
  display: 'grid',
  width: "70%",
  "@media (max-width: 1000px)": {
    marginLeft: '50px',
    marginRight: '15px',
  },
  "@media (max-width: 950px)": {
    marginLeft: '20px',
  },
  "& .projectBudget": {
    display: 'flex',
    "@media (max-width: 870px)": {
      marginTop: '12px',
    },
  },
  "& .projectProposal": {
    display: 'flex',
    marginTop: '10px',
    justifyContent: 'end',
    "@media (max-width: 870px)": {
      justifyContent: 'start',
    },
  },
  "& .jobContentBox": {
    width: '100%',
    "@media (max-width: 870px)": {
      width: 'auto',
    },
  },
  "& .mainContentBox": {
    display: 'flex',
    justifyContent: 'space-between',
    "@media (max-width: 870px)": {
      display: 'block',
    },
  },

});
// Customizable Area End

export default class ViewProposals extends ViewProposalsController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    // Customizable Area End
  }

  //Customizable Area Start



  // Customizable Area End

  render() {
    //Customizable Area Start
    const skills = this.state.jobPostedData?.attributes?.skills || [];
    const states = this.state.jobPostedData?.attributes?.states || [];
    const countryName = this.state.jobPostedData?.attributes?.country?.name;
    const proposalsCount = this.state.jobPostedData?.attributes?.proposals;

    const { selectedTab } = this.state;



    if (this.state.loading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      );
    }
    return (
      <>

        <ClientNavBar />
        <Grid container spacing={2} lg={12} style={webStyle.gridBox}>

          <Grid item lg={12} xs={12}>
            <Box style={{ marginTop: "30px" }}>
              <HeaderTxt>Job Proposals</HeaderTxt>
              <CustomSide>
                <Box
                  style={{
                    borderRadius: '12px',
                    backgroundColor: '#F6F6F6',
                    padding: '18px',
                    marginTop: "3%"
                  }}
                  className='jobContentBox'
                >

                  <Box className="mainContentBox">
                    <Box style={{ marginLeft: '5px', width: '60%' }}>
                      <Typography style={{ ...webStyle.text, fontSize: '20px', fontWeight: 400 }}>
                        {this.state.jobPostedData?.attributes?.name}
                      </Typography>
                      <Box display={'flex'} alignItems={'center'}>
                        <img src={locationIcon} style={{ marginRight: '10px' }} />
                        <Typography style={{ ...webStyle.text, marginTop: '10px', display: 'contents' }}>
                          {states.length > 0 && <span style={{ marginRight: '5px' }}>{states[0].name},</span>}{countryName && <span>({countryName})</span>}
                        </Typography>
                        <Rating
                          name="simple-controlled"
                          value={this.state.jobPostedData?.attributes?.rating}
                          disabled
                          style={{
                            padding: '8px 0px',
                            width: '90px',
                            marginLeft: '7px',
                            marginRight: '15px'
                          }}
                        />
                        <Typography style={{ ...webStyle.text, marginLeft: '30px' }}>
                          {this.state.jobPostedData?.attributes?.rating}
                        </Typography>
                      </Box>
                      <Box display={'flex'} style={{ alignItems: 'center', marginTop: '12px' }}>
                        <Typography style={{ fontSize: '14px', fontFamily: 'Rubik', marginRight: '5px' }}>
                          {this.state.jobPostedData?.attributes?.experience_level} - Est. Time:{' '}

                        </Typography>
                        <Typography style={{ fontSize: '14px', fontFamily: 'Rubik' }}>
                          {this.state.jobPostedData?.attributes?.project_length}
                        </Typography>
                      </Box>
                      <Box style={{ marginTop: '10px', marginBottom: '24px' }}>
                        <Typography style={{ fontSize: '14px', fontFamily: 'Rubik', color: '#6b6969' }}>
                          {this.state.jobPostedData?.attributes?.description}
                        </Typography>
                      </Box>
                      <Box>
                        {
                          skills && skills.length > 0 && (
                            skills.map((item: string, index: number) => (
                              <Chip
                                key={index}
                                label={item}
                                style={webStyle.chiplabel}
                              />
                            ))
                          )
                        }
                      </Box>
                    </Box>
                    <Box>
                      <Box className='projectBudget'>
                        <Typography style={{ fontSize: '18px', fontFamily: 'Rubik', whiteSpace: 'nowrap', margin: '0px 5px', color: "#0A0A0A" }}>
                          Project Budget: {' '}
                        </Typography>
                        <Typography style={{ fontSize: '18px', fontFamily: 'Rubik' }}>
                          ${this.state.jobPostedData?.attributes?.est_budget}
                        </Typography>
                      </Box>
                      <Box className='projectProposal'>
                        <Typography
                          style={{
                            fontSize: '14px',
                            fontFamily: 'Rubik',
                            whiteSpace: 'nowrap',
                            margin: '0px 5px',
                            color: '#6b6969'
                          }}
                        >
                          Proposals: {' '}
                        </Typography>
                        <Typography style={{ fontSize: '16px', fontFamily: 'Rubik', color: '#6b6969' }}>
                          {this.state.jobPostedData?.attributes?.proposals}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="proposalButton" justifyContent={'space-between'} style={{ marginTop: '10px' }}>
                    <Box style={{ marginTop: "5%" }}>
                      <Typography
                        style={{
                          ...webStyle.text,
                          fontWeight: 400,
                          fontFamily: 'Rubik',
                          alignContent: 'end'
                        }}
                      >
                        {this.state.jobPostedData?.attributes?.created_at}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </CustomSide>

            </Box>
          </Grid>
          <Box display={"flex"} flexDirection={"column"} sx={{ width: "70%" }}>

            <CustomTabs
              value={selectedTab}
              onChange={this.handleTabChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label={(<Box display="inline-flex" alignItems="center"><>All Proposals{proposalsCount && <span style={{ marginLeft: "5px" }}> ({proposalsCount})</span>} </></Box>)} style={{ textTransform: "none", fontFamily: "Rubik", fontSize: "16px", color: "#3F3F3F", fontWeight: 500 }} />
              <Tab label="Accepted (00)" style={{ textTransform: "none", fontFamily: "Rubik", fontSize: "16px", color: "#3F3F3F", fontWeight: 500 }} />
              <Tab label="Rejected (00)" style={{ textTransform: "none", fontFamily: "Rubik", fontSize: "16px", color: "#3F3F3F", fontWeight: 500 }} />
              <Tab label="Invitations (00)" style={{ textTransform: "none", fontFamily: "Rubik", fontSize: "16px", color: "#3F3F3F", fontWeight: 500 }} />

            </CustomTabs>

            {selectedTab === 0 && (
              <AllProposals navigation={this.props.navigation} proposalsData={this.state.proposalsData} />
            )}

          </Box>

        </Grid>
        <FooterWeb />
      </>
    );
    //Customizable Area End
  }
}

//Customizable Area Start

const webStyle = {

  gridBox: {
    display: 'flex',
    margin: "45px 35px 45px 35px",
    overflow: "hidden"
  },
  chiplabel: {
    fontWeight: 500,
    marginRight: '10px',
    color: '#364BA0',
    backgroundColor: '#E4F5FF',
    fontSize: '16px',
    padding: '12px',
    fontFamily: "Rubik",
    '& .MuiChip-label': {
      textTransform: 'uppercase',
      fontWeight: 700,
      fontSize: '16px',
    }
  },
  text: {
    color: '#0A0A0A',
    fontFamily: 'Rubik',
    fontSize: '14px',
    fontWeight: 300
  },
  rating: {},
  modalText: {
    fontFamily: 'Inter',
    fontWeight: 500,
    color: '#515354',
    fontSize: '18px',
  },
  proposalNumber: {
    display: 'flex',
    alignItems: 'center',
    color: 'rgb(107,105,105'
  },
  description: {
    color: '#0A0A0A',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '18px',
    fontWeight: 400,
    marginBottom: '2rem',
    opacity: 0.6
  }
}

const TitleTxt = styled(Typography)({
  margin: "20px 0px",
  display: "flex",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "16px",
  width: "91%",
  color: "#353535"
});


const HeaderTxt = styled(Typography)({
  fontSize: "30px",
  color: "#3F3F3F",
  fontFamily: "Rubik",
  fonSize: "36px",
  fontWeight: 700,
  lineHeight: "44px",
  letterSpacing: "0px",
  textAlign: "left"
});




//Customizable Area End
// Customizable Area End
