import React from "react";
//Customizable Area Start
import {
  Grid,
  styled,
  Radio,
  Typography,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Box,
} from "@material-ui/core";
import DashboardController, { Props } from "../DashboardController.web";
import { ellipse } from "./assets";
const CustomTypography = styled(Typography)({
  fontFamily:"Rubik",
  fontSize: "16px",
  fontWeight: "bold",
  color: "#FFFFFF",
  padding: "15px",
  textDecoration: "none",
  cursor:"pointer",
  background: '#364BA0',
  borderRadius: 10,
  textAlign:"center",
  width:"100%",
  maxWidth: "161px",
  maxHeight: "55px",
  '&:hover': {
    color:"#364BA0",
    border:"1px solid #364BA0",
    backgroundColor: "#fff",
  }
})
const CustomBox = styled(Box)({
    flexGrow: 1,
    maxWidth: "1288px",
    margin: "0 auto",
      '& .forth-step-content': {
        '@media(max-width: 600px)': {
        width: "93% !important",
      },
    },
    "@media (max-width: 950px)": {
    display: "flex",
    justifyContent: "center",
    margin:"auto",
    width:"100%",
    paddingInline: "12px"
  },
})
//Customizable Area End

export default class WorkScope extends DashboardController {
  //Customizable Area Start
  constructor(props: Props) {
    super(props);
    
  }
  //Customizable Area End
  render() {
    //Customizable Area Start
    return (
      <>
        <CustomBox>
          <Grid
            container
            spacing={2}
            style={styles.gridContainer as React.CSSProperties}
          >
            <Grid item xs={12} md={6}>
              <div style={styles.rightdiv as React.CSSProperties}>
                <Typography style={styles.rigHeading as React.CSSProperties}>
                  Next, estimate the scope of your work.
                </Typography>
              </div>
              <Typography style={styles.subHeading as React.CSSProperties}>
                Consider the size of your project and the time it will take.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={this.props.estimate}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="large"
                    data-testid="workscope"
                    control={<Radio style={{ color: "#206FC4" }} />}
                    label="Large"
                    //@ts-ignore
                    onClick={() => this.props.getEstimateSet("large")}
                  />
                  <Typography className="forth-step-content" style={styles.secgridTypo as React.CSSProperties}>
                    Longer term or complex initiatives(ex. develop and execute a
                    brand strategy(i.e., graphics, positioning))
                  </Typography>
                  <FormControlLabel
                    value="medium"
                    control={<Radio style={{ color: "#206FC4" }} />}
                    label="Medium"
                    data-testid="workscope"
                    //@ts-ignore
                    onClick={() => this.props.getEstimateSet("medium")}
                  />
                  <Typography className="forth-step-content" style={styles.secgridTypo as React.CSSProperties}>
                    Well-defined projects (ex. design business rebrand
                    package(i.e., logos,icons))
                  </Typography>
                  <FormControlLabel
                    value="small"
                    control={<Radio style={{ color: "#206FC4" }} />}
                    label="Small"
                    data-testid="workscope"
                    //@ts-ignore
                    onClick={() => this.props.getEstimateSet("small")}
                  />
                  <Typography className="forth-step-content" style={styles.secgridTypo as React.CSSProperties}>
                    Quick and straightforward tasks(ex. create logo for a new
                    product)
                  </Typography>
                </RadioGroup>
                <Typography style={styles.howwillTxt as React.CSSProperties}>
                  How long will your work take ?
                </Typography>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={this.props.duration}
                  name="radio-buttons-group"
                  data-test-id = "duration"
                  onChange={(e) => {
                    //@ts-ignore
                      this.props.getDurationSet(e.target.value); 
                  }}
                >
                   <FormControlLabel
                    value="less than 1 month"
                    control={<Radio style={{ color: "#206FC4" }} />}
                    label="Less than 1 month"
                  />
                    <FormControlLabel
                    value="1 to 3 months"
                    control={<Radio style={{ color: "#206FC4" }} />}
                    label="1 to 3 months"
                    />
                  <FormControlLabel
                    value="3 to 6 months"
                    control={<Radio style={{ color: "#206FC4" }} />}
                    label="3 to 6 months"
                  />
                    <FormControlLabel
                    value="6 to 12 months"
                    control={<Radio style={{ color: "#206FC4" }} />}
                    label="6 to 12 months"
                  />
                    <FormControlLabel
                    value="more than 12 months"
                    control={<Radio style={{ color: "#206FC4" }} />}
                    label="more than 12 months"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
           <img src={ellipse} alt="ellipse" style={styles.ellipseImg}/>
        </CustomBox>
          <Box style={styles.lowerSectionDiv as React.CSSProperties}>
            <Box style={styles.lowerSectionDiv2}>
              <Typography
                style={{
                  ...styles.lowerSectionItem1Text,
                  ...(this.state.isHovered && styles.lowerSectionItem1TextHovered),
                }}
                onClick={this.handleWorkScopeBack}
                onMouseEnter={this.handleHover}
                onMouseLeave={this.handleLeave}
              >
                BACK
              </Typography>
                <CustomTypography 
                onClick={this.handleWorkScopeContinue}
                >
                  CONTINUE
                </CustomTypography>
                </Box>
          </Box>
      </>
      //Customizable Area End
    );
  }
}
//Customizable Area Start
const theme = {
  spacing: {
    2: "8px",
  },
  breakpoints: {
    down: (size: any) => `@media (max-width: ${size}px)`,
  },
};

const styles = {
  heading: {
    fontFamily: "Rubik",
    fontSize: "36px",
    fontWeight: 400,
    color: "#000000",
    marginTop: "44px",
  },
  gridContainer: {
    marginTop: "35px",
  },
  rigHeading: {
    fontSize: "38px",
    lineHeight: "58px",
    letterSpacing: "0px",
    color: "#000000",
    fontWeight: 500,
    fontFamily: "Rubik",
  },

  rightdiv: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "center",
    },
  },
  secgridTypo: {
    marginLeft: "10px",
    fontFamily: "Rubik",
    fontSize: "16px",
    lineHeight: "30px",
    letterSpacing: "0px",
    textAlign: "left",
    color: "#000000",
    maxWidth: "523px",
    width: '100%',
  },
  lowerSectionDiv: {
    display: 'flex',
    justifyContent: 'space-between', 
    alignItems: 'center', 
    position: 'absolute', 
    bottom: 20, 
    left: 0, 
    width: '100%'
  },
  lowerSectionDiv2:{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingInline: 20,
    width: '100%'
  },
  subHeading: {
    fontSize: "14px",
    fontFamily: "Rubik",
    lineHeight: "30px",
    letterSpacing: "0px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "50px",
    },
  },
  howwillTxt: {
    fontSize: "16px",
    lineHeight: "35px",
    fontWeight: "bold",
    letterSpacing: "0px",
    textAlign: "left",
    color: "#000000",
    marginTop: "1rem",
    fontFamily: "Rubik",
  },
  lowerSectionItem1Text: {
    fontFamily: "Rubik" as "Rubik",
    fontSize: "16px",
    color: "#535353",
    lineHeight: "27px",
    cursor: "pointer" as "pointer",
    fontWeight: "bold" as "bold",
    zIndex: 1

  },
lowerSectionItem1TextHovered:{
  color :""
},

  lowerSectionBtn: {
    maxWidth: "161px",
    maxHeight: "55px",
    backgroundColor: "#206FC4",
    borderRadius: "10px",
    cursor:"pointer",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-30px",
    },
  },
  btnText: {
    fontFamily: "Rubik",
    fontSize: "16px",
    color: "#FFFFFF",
    padding: "15px",
    textDecoration: "none",
    fontWeight:"bold",
  },
  ellipseImg:{
    position:'absolute' as 'absolute',
    bottom:'0',
    width:'340px',
    left:'0'
  }
  //Customizable Area End
};
