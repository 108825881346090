import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  TextField,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  Dialog,
  DialogContent
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
export const config = require("./config");
import { authImage } from "./assets";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Horizontalinventohub } from "../../landingpage/src/assets";
import CloseIcon from "@material-ui/icons/Close";
const CustomDialog = styled(Dialog)({
  "& .MuiPaper-rounded": {
    borderRadius: "18px",
    width: "480px"
  }
});
const MainContainer = styled(Box)({
  paddingInline: 15,
  '@media(max-width: 959px)': {
    '& .dots-img': {
      display: 'none',
    },
    '& .firstContainer': {
      padding: "20px 10px", 
      margin:"auto"
    },
    '& .authImage': {
      display: "none", 
    }
  },
  '@media(min-width: 960px)': {
    '& .firstContainer': {
      padding: "4% 9%",
    },
  }
});

// Customizable Area End

import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import { image_Success } from "../../email-account-registration/src/assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#206FC4"
    }
  },
  typography: {
    fontFamily: "Inter, Rubik, Roboto"
  }
});

export default class NewPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderDialogBox = () => {
    return (
      <CustomDialog
        open={this.state.isDialogOpen}
        onClose={this.handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ textAlign: "center", padding: "38px" }}>
          <img
            src={image_Success}
            alt="Success"
            style={{
              width: "38%",
              borderRadius: "50%",
              marginTop: "21px",
              marginBottom: "10px"
            }}
          />
          <Typography gutterBottom style={styles.congratsText}>
            Password Changed!
          </Typography>
          <Typography style={styles.profileCreatedText}>
           Your account password is updated successfully!
          </Typography>
        </DialogContent>
      </CustomDialog>
    );
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <MainContainer>
      <ThemeProvider theme={theme}>
        <Grid container >
          <Grid item xs={12} sm={6} className="firstContainer">
            <img
              src={Horizontalinventohub}
              style={styles.invento as React.CSSProperties}
              onClick={this.handleLogoClick}
              data-test-id="handleLogoClick"
              className="resetPassword"
            />

            <Box style={{marginBottom:"68px"}} className="resetPassword">
            <Typography style={styles.resetPassword}>
              {config.resetPassword}
            </Typography>
            <Typography style={styles.passwordDetails}>
            Create a new password to secure your account.
            </Typography>
            </Box>

            <div style={{marginBottom:"51px"}}>
            <Box>
              <Typography style={styles.passwordLable}>
                {config.newPasswordLable}<span style={{  fontWeight: 800,
    color: "#FF5E5B",}}>*</span>
              </Typography>
              <TextField
                variant="outlined"
                data-testid="new-password"
                type={this.state.showPassword ? "text" : "password"}
                value={this.state.newPassword}
                placeholder={config.newPasswordPlaceholder}
                onChange={this.handleNewPassword}
                fullWidth
                InputProps={{
                  style: styles.inputPropsStyle,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        data-testid="newPassword"
                      >
                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                FormHelperTextProps={{
                  style: {
                    color: '#FF0000', 
                    fontSize: '14px', 
                    fontFamily: 'Rubik'
                  },
                }}
                
                error={this.state.validationError || this.state.requireError}
                helperText={(this.state.validationError|| this.state.requireError) ? this.state.errorMessage : ""}
              />
            </Box>

            <Box style={styles.confirmPasswordBox}>
              <Typography style={styles.passwordLable}>
                {config.confirmPasswordLable}<span style={{  fontWeight: 800,
    color: "#FF5E5B",}}>*</span>
              </Typography>
              <TextField
                variant="outlined"
                data-testid="confirm-password"
                type={this.state.showConfirmPassword ? "text" : "password"}
                value={this.state.confirmPassword}
                placeholder={config.confirmPasswordPlaceholder}
                onChange={this.handleConfirmPassword}
                fullWidth
                InputProps={{
                  style: styles.inputPropsStyle,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        data-testid="password-show"
                        aria-label="toggle password visibility"
                        onClick={this.handleClickConfirmShowPassword}
                      >
                        {this.state.showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={this.state.confirmValidation || this.state.confirmRequireError}
                helperText={
                  (this.state.confirmValidation || this.state.confirmRequireError) &&
                  this.state.confirmErrorMessage
                }
                FormHelperTextProps={{
                  style: {
                    color: '#FF0000', 
                    fontSize: '14px', 
                    fontFamily: 'Rubik'
                  },
                }}
              />
            </Box>
            </div>

            <Button
              variant="contained"
              data-testid="reset-btn"
              style={styles.buttonContainer}
              onClick={this.handleResetPassword}
            >
              {config.resetPasswordBtn}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} className="dots-img">
            <img
              src={authImage}
              alt="forgot-password"
              style={styles.authImage}
              className="authImage"
            />
          </Grid>
        </Grid>
        {this.renderDialogBox()}
      </ThemeProvider>
      </MainContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  firstContainer: {
    padding: "4% 9%"
  },
  invento: {
    width: "264px",
    height: "48px",
    objectFit: "cover",
    marginBottom: "115px",
  },
  resetPassword: {
    fontWeight: 500,
    color: "#364BA0",
    fontFamily: "Rubik",
    fontSize: "32px",
    marginBottom: "9px",
  },
  passwordDetails: {
    fontFamily: "Rubik",
    fontSize: "18px",
    color: "#000000",
    lineHeight: "21.78px",
  },
  passwordLable: {
    fontSize: "20px",
    color: "#535353",
    fontWeight: 500,
    fontFamily: "Rubik",
    marginBottom: "12px",
  },
  inputPropsStyle: {
    borderRadius: "10px"
  },
  buttonContainer: {
    background: "#364BA0",
    borderRadius: "10px",
    width: "100%",
    fontWeight: 700,
    padding: "16px",
    fontFamily: "Rubik",
    fontSize: "16px",
    color: "#ffffff",
    marginBottom: "40px",
    border: "1px solid #364BA0",
  },
  backToText: {
    fontSize: "1.25rem",
    color: "#6F6E6E",
    fontWeight: 500
  },
  loginText: {
    fontWeight: 600,
    marginLeft: "9px",
    cursor: "pointer"
  },
  authImage: {
    height: "100%",
    position:"absolute" as "absolute",
    width: "50%",
    top: 0,
    right:0,
    objectPosition:"top right",
    objectFit:"contain" as "contain",
    "@media (max-width: 768px)": {
      display: "none"
    }
  },
  confirmPasswordBox: {
    marginTop: "7%"
  },
  congratsText: {
    marginTop: "18px",
    fontSize: "24px",
    color: "#059669",
    fontWeight: 600,
    fontFamily: "Rubik"
  },
  profileCreatedText: {
    fontSize: "16px",
    marginTop: "26px",
    fontFamily: "Rubik"
  }
};
// Customizable Area End
