//Customizable Area Start
import React from "react";
//Customizable Area Start
import { Box, Button, Chip, Grid, Typography, styled } from "@material-ui/core";
import AllProposalsController, { Props } from "./AllProposalsController.web";
export const webConfigJSON = require("./config");
import Rating from '@material-ui/lab/Rating';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { avatar, locationIcon } from "./Components/assets";
// Customizable Area End
export default class AllProposals extends AllProposalsController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    // Customizable Area End
  }
  //Customizable Area Start
  // Customizable Area End
  render() {
    //Customizable Area Start
    return (
      <Box>
        {this.props.proposalsData.map((item: any) => {
          const skills = item?.attributes?.account_attributes?.skills || [];
          return (
            <CustomLeftSide>
              <Box
                style={{
                  borderRadius: '12px',
                  backgroundColor: '#F6F6F6',
                  padding: '18px',
                  marginTop: "3%"
                }}
                className='jobContentBox'
              >
                <Box className="mainContentBox">
                  <Box style={{ marginLeft: '5px' }}>
                    <Grid container style={{ gap: "10px" }}>
                      <div style={webStyle.profileContainer}>
                        <img src={item?.attributes?.account_attributes?.profile_image?.url || avatar} alt="avtar" style={webStyle.userProfileImg} />

                        <FiberManualRecordIcon style={webStyle.onlineIcon} />
                      </div>
                      <div>
                        <Box>
                          <Typography style={webStyle.userName}>{item?.attributes?.account_attributes?.user_name}</Typography>
                          <Typography style={webStyle.subText} >{item?.attributes?.account_attributes?.designation}</Typography>
                        </Box>
                      </div>
                    </Grid>
                    <Typography style={{ ...webStyle.subText, marginTop: "10px" }}>Rating:</Typography>
                    <Box display={'flex'} alignItems={'center'}>
                      <Rating
                        name="simple-controlled"
                        value={3}
                        disabled
                        style={{
                          padding: '8px 0px',
                          width: '90px',
                          marginRight: '15px'
                        }}
                      />
                      <Typography style={{ ...webStyle.text, marginLeft: '30px' }}>
                        2.91/5
                      </Typography>
                      <img src={locationIcon} style={{ marginLeft: '10px', marginRight: '10px' }} />
                      <Typography style={{ ...webStyle.text, marginTop: '10px', display: 'contents' }}>
                        {item?.attributes?.account_attributes?.location}- 12:57 PM Local Time
                      </Typography>
                    </Box>
                    <Box display={'flex'} style={{ alignItems: 'center', marginTop: '12px', marginBottom: "10px" }}>
                      <Typography style={{ ...webStyle.subText, marginRight: '5px' }}>
                        Skills
                      </Typography>

                    </Box>
                    <Box>
                      {skills?.length && skills?.map((item: string, index: number) => (
                        <Chip
                          key={index}
                          label={item}
                          style={webStyle.chiplabel}
                        />
                      ))}
                    </Box>
                  </Box>
                  <Box style={{ width: "21%" }}>
                    <Box className='projectBudget'>
                      <Back_button_Custom variant="outlined">
                        View Proposal
                      </Back_button_Custom>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </CustomLeftSide>
          )
        })}
      </Box>
    );
    //Customizable Area End
  }
}

//Customizable Area Start

const webStyle = {
  gridBox: {
    display: 'flex',
    margin: "45px 35px 45px 35px",
    overflow: "hidden"
  },
  chiplabel: {
    fontWeight: 300,
    marginRight: '10px',
    color: '#364BA0',
    fontSize: '16px',
    padding: '12px',
    fontFamily: "Rubik",
    '& .MuiChip-label': {
      textTransform: 'uppercase',
      fontWeight: 700,
      fontSize: '16px',
    }
  },
  text: {
    color: '#0A0A0A',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 300
  },
  rating: {},
  modalText: {
    fontFamily: 'Inter',
    fontWeight: 500,
    color: '#515354',
    fontSize: '18px',
  },
  proposalNumber: {
    display: 'flex',
    alignItems: 'center',
    color: 'rgb(107,105,105'
  },
  description: {
    color: '#0A0A0A',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '18px',
    fontWeight: 400,
    marginBottom: '2rem',
    opacity: 0.6
  },
  profileContainer: {
    position: 'relative' as 'relative',
    display: "flex" as "flex",
    justifyContent: "center"
  },
  userProfileImg: {
    width: '50px',
    height: '50px',
  },
  userName: {
    fontSize: '24px',
    fontWeight: 400,
    color: "#364BA0",
    fontFamily: "Rubik",

  },
  subText: {
    fontSize: '16px',
    color: "#0A0A0A",
    fontFamily: "Rubik",
    fontWeight: 300
  },
  onlineIcon: {
    position: 'absolute' as 'absolute',
    color: 'green',
    fontSize: '12px',
    left: '35px',
    top: '37px'
  },
  locationContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  locationIcon: {
    width: '16px',
    height: '15px',
    marginLeft: "-4px"
  },
}

const CustomLeftSide = styled(Box)({
  display: 'grid',
  "@media (max-width: 1000px)": {
    marginLeft: '50px',
    marginRight: '15px',
  },
  "@media (max-width: 950px)": {
    marginLeft: '20px',
  },
  "& .jobContentBox": {
    width: '100%',
    "@media (max-width: 870px)": {
      width: 'auto',
    },
  },
  "& .mainContentBox": {
    display: 'flex',
    justifyContent: 'space-between',
    "@media (max-width: 870px)": {
      display: 'block',
    },
  },
  "& .projectBudget": {
    display: 'flex',
    "@media (max-width: 870px)": {
      marginTop: '12px',
    },
  },
  "& .projectProposal": {
    display: 'flex',
    marginTop: '10px',
    justifyContent: 'end',
    "@media (max-width: 870px)": {
      justifyContent: 'start',
    },
  }

});
const Back_button_Custom = styled(Button)({
  color: '#364BA0',
  borderColor: '#364BA0',
  width: "100%",
  height: "50px",
  fontFamily: "Rubik",
  padding: "22px",
  fontWeight: 500,
  fontSize: "18px",
  borderRadius: '12px',
  "@media(max-width:500px)": {
    padding: "10px",
    fontSize: "14px",
    height: "40px",
  },
  "& .MuiButton-label": {
    fontSize: "inherit",
  }
});
//Customizable Area End
// Customizable Area End
