  // Customizable Area Start
import { IBlock } from 'framework/src/IBlock';
import { Message } from 'framework/src/Message';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';
import { callApi } from '../../../components/src/Toolkit';
import storage from '../../../framework/src/StorageProvider';
// Customizable Area Start
// Customizable Area End
export const webConfigJSON = require('./config.js');


export interface Props {
  navigation: any;
  // Customizable Area Start
  proposalsData:any
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  activeButton: string;
  selectedTab:number|string;
  skills:any;
  loading: boolean;
  jobPostedData:any;
  proposalsData:any;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ViewProposalsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getJobPostDetailCallId: string = '';
  getProposalsCallId: string = '';

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      activeButton: "offers",
      selectedTab:0,
      skills:["IP Lawyer","IP Licensing","IP Valuation"],
      loading: false,
      jobPostedData:[],
      proposalsData:[]

    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const jobId = await storage.get('jobId');
    const authToken = await storage.get('authToken');

    this.getJobDetails({ jobId, authToken });
    this.getProposals({ jobId, authToken });

    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson != null) {
        if (responseJson) {
          await this.parseApiSuccess(responseJson, apiRequestCallId);
        }
      }
    }
  }

  // Customizable Area End

  // Customizable Area Start
  
  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ selectedTab: newValue });
  };

  getJobDetails = async ({ jobId, authToken }: any) => {
    this.setState({ loading: true });
    this.getJobPostDetailCallId = callApi(
      {
        contentType: webConfigJSON.contentTypeApi,
        method: webConfigJSON.getJobPostDetailMethod,
        endPoint: `${webConfigJSON.getJobPostDetailEndPoint}/${jobId}`,
        headers: { token: authToken }
      },
      runEngine
    );
  };

  getProposals = async ({ jobId, authToken }: any) => {
    this.setState({ loading: true });

    this.getProposalsCallId = callApi(
      {
        contentType: webConfigJSON.contentTypeApi,
        method: webConfigJSON.getJobPostDetailMethod,
        endPoint: `bx_block_proposals/proposals/job_proposals?post_id=${jobId}`,
        headers: { token: authToken }
      },
      runEngine
    );
  };
  parseApiSuccess = async (responseJson: any, apiRequestCallId: string) => {
    if (apiRequestCallId === this.getJobPostDetailCallId) {
      if (responseJson.data) {
        this.setState({ jobPostedData: responseJson.data, loading: false });
      }
    }
    if (apiRequestCallId === this.getProposalsCallId) {
      if (responseJson.data) {
        this.setState({ proposalsData: responseJson.data, loading: false });
      }
    }
  };

  // Customizable Area End
}
// Customizable Area End

