import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import storage from 'framework/src/StorageProvider';


// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
interface SocialMediaForm {
    Media_Link: string;
    id: string;
   
  }
// Customizable Area End

interface S {
  // Customizable Area Start
  isDialogOpen: boolean;
  socialMediaForm: SocialMediaForm[];
  authToken:string,
  percentage:number,

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class RemindMeDialogboxController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiExperienceID:string ="";
  profilecomplete_status_APIid="";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      socialMediaForm: [{  id: '', Media_Link: '', }],
      isDialogOpen: true,
      authToken:"",
      percentage:0


      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.status === 500) {
          toast.error("Something went wrong!");
        } else if (responseJson.errors) {
          toast.error(responseJson.errors);
        }
        if (apiRequestCallId === this.apiExperienceID) {
            console.log(responseJson); 
        }
        if (apiRequestCallId === this.profilecomplete_status_APIid) {
          // this.setFormData(responseJson)
          this.setState({percentage:responseJson.completion_percentage})
          console.log("res123=======>",responseJson.completion_percentage);
          
         }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.setState({
      authToken: await storage.get('authToken'),
    });
    this.profilePercentage()
  }
  handleHomeBtn = () => {
    this.setState({ isDialogOpen: false });
  }
  handleCloseDialogBtn = () => {
    this.setState({
         isDialogOpen: false 
        });
  };
  remindMeLaterBtn=()=>{
    this.props.navigation.navigate("FreelancerHomePage")
    this.setState({ isDialogOpen: false });
  }



profilePercentage = () => {
  const header = {
    "Content-Type": "application/json",
    "token": this.state.authToken
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.profilecomplete_status_APIid = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `account_block/freelancers/freelancer_profile_status`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};









  // Customizable Area End
}