import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
interface SocialMedia {
    Media_Name: string;
   
   
  }




 




// Customizable Area End

interface S {
  // Customizable Area Start
  
  isDialogOpen: boolean;
  socialMedia: SocialMedia[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class SuccessDialogboxController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiSuccessID:string ="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isDialogOpen: true,
      socialMedia: [{  Media_Name: '', }],

      // Customizable Area End
    };

    // Customizable Area Start

    

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
       
        if (apiRequestCallId === this.apiSuccessID) {    console.log(responseJson);   }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
  }

  handleCloseDialog = () => 
    {
    this.setState({ isDialogOpen: false });
  };

  handleHome = () => {
    this.setState({ isDialogOpen: false });
    this.props.navigation.navigate("SearchJob")
  }
  remindMeLater=()=>{
    this.props.navigation.navigate("FreelancerCompleteProfile")
    this.setState({ 
      
      
      isDialogOpen: false });
  }
 











  // Customizable Area End
}