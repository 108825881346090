import React from "react";
// Customizable Area Start
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Typography, styled } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import FooterWeb from "./Components/Footer.web";
import ClientNavBar from "./Components/ClientNavBar.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#206FC4",
        },
        secondary: {
            main: "rgb(96, 96, 96)",
        }
    },
    typography: {
        fontFamily: 'Rubik',
    }
});
const CustomButton = styled(Button)({
    background: "#364BA0",
    fontSize: "16px",
    lineHeight: "19px",
    letterSpacing: "0px",
    color: "white",
    borderRadius: "10px",
    width: " 173px",
    height: " 56px",
    marginRight:"1rem",
    fontFamily:"Rubik",
    fontWeight:"bold",
    cursor:"pointer",
    marginTop: "20px",
    '&:hover': {
      width: " 173px",
      height: " 56px",
      borderRadius: "10px",
      color:"#206FC4",
      border:"1px solid #206FC4",
      backgroundColor: "#fff",
    }
  })
// Customizable Area End
import JobPostingController, {
    Props,
    webConfigJSON
} from "./JobPostingController";

export default class JobPosting extends JobPostingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const nonDraftedJobs = this.state.jobListData.filter(job => !job.attributes.is_drafted);
        return (
            //Merge Engine DefaultContainer
            <>
            <ThemeProvider theme={theme}>
               <ClientNavBar/>
               <Grid container style={webStyles.gridMainContainer}>
                   <Grid item xs={12} sm={7} md={7}>
                       <Box>
                           <Typography style={webStyles.dashboardText}>{webConfigJSON.yourDashboard}</Typography>
                           <Typography style={webStyles.firstNameStyle}>{this.state.firstName}</Typography>
                           <Grid container>
                               <Grid item xs={12} sm={12} md={12}>
                                   <Box style={webStyles.postingContainer}>
                                       <Box style={webStyles.seePost}>
                                           <Typography style={webStyles.yourPostText}>{webConfigJSON.yourPosting}</Typography>
                                           <Typography 
                                               style={webStyles.seeAllPostText} 
                                               data-test-id = "handleAllPost"
                                               onClick={this.handleAllPost}
                                           >
                                               {webConfigJSON.seeAllPost}
                                           </Typography>
                                       </Box>
                                       {nonDraftedJobs.length > 0 ? (
                                         <>
                                           <Typography style={webStyles.DescriptionStyle} color="secondary">{nonDraftedJobs[0]?.attributes?.name}</Typography>
                                           <Grid container>
                                               <Grid item sm={6} md={6}>
                                                   <Typography color="secondary" style={webStyles.createdAtStyle}>{nonDraftedJobs[0].attributes?.created_at}</Typography>
                                               </Grid>
                                               <Grid item sm={6} md={6}>
                                                   <Box style={webStyles.countBox}>
                                                       <Box>
                                                           <Typography color="primary">{nonDraftedJobs[0].attributes?.proposals}</Typography>
                                                           <Typography>{webConfigJSON.proposals}</Typography>
                                                       </Box>
                                                       <Box>
                                                           <Typography color="primary">{nonDraftedJobs[0].attributes?.messaged}</Typography>
                                                           <Typography>{webConfigJSON.messaged}</Typography>
                                                       </Box>
                                                       <Box>
                                                           <Typography color="primary">{nonDraftedJobs[0].attributes?.hired}</Typography>
                                                           <Typography>{webConfigJSON.hired}</Typography>
                                                       </Box>
                                                   </Box>
                                               </Grid>
                                           </Grid>
                                         </>
                                       ) : (
                                         <Typography color="secondary">No active job postings</Typography>
                                       )}
                                   </Box>
                                   <Box style={webStyles.postingContainer}>
                                       <Box style={webStyles.seePost}>
                                           <Typography style={webStyles.yourPostText}>{webConfigJSON.yourDraft}</Typography>
                                           <Typography style={webStyles.seeAllPostText} data-test-id = "handleAllPost2"
                                               onClick={this.handleAllPost}>{webConfigJSON.seeAllDraft}</Typography>
                                       </Box>
                                       <Box style={webStyles.seeDraft}>
                                           <Typography style={webStyles.DescriptionStyle} color="secondary">{this.state.draftListData && this.state.draftListData[0]?.attributes?.name}</Typography>
                                       </Box>
                                       <Typography color="secondary" style={webStyles.createdAtStyle}>{this.state.draftListData && this.state.draftListData[0]?.attributes?.drafted_at}</Typography>
                                   </Box>
                                   <Box style={webStyles.postingContainer}>
                                       <Grid container>
                                           <Grid item xs={12} sm={12} md={12}>
                                               <Box style={webStyles.workContainer}>
                                                   <Typography style={webStyles.howToWork}>{webConfigJSON.howToWork}</Typography>
                                                   <Typography style={webStyles.details} color="secondary">
                                                    Best practices for collaborating with IP Professionals to protect and manage your innovations effectively.</Typography>
                                               </Box>
                                               <Box style={webStyles.workContainer}>
                                                   <Typography style={webStyles.lists}>{webConfigJSON.postAJob}</Typography>
                                                   <Typography style={webStyles.details} color="secondary">
                                                   Provide enough detail for great IP Professionals to figure out if the work is right for them.(You can always edit your
                                                    post, or send an invite to reach out to people directly.)
                                                   </Typography>
                                               </Box>
                                               <Box style={webStyles.workContainer}>
                                                   <Typography style={webStyles.lists}>{webConfigJSON.getProposals}</Typography>
                                                   <Typography style={webStyles.details} color="secondary">
                                                   A strong working relationship starts with open communications. Here’s your chance to ask about experience
                                                   set expectations for what you need, and discuss terms of the work.
                                                   </Typography>
                                               </Box>
                                               <Box style={webStyles.workContainer}>
                                                   <Typography style={webStyles.lists}>{webConfigJSON.startWork}</Typography>
                                                   <Typography style={webStyles.details} color="secondary">
                                                   Once you both agree on terms, collaborate with simple and secure tools like chat, file sharing.
                                                   </Typography>
                                               </Box>
                                               <Box style={webStyles.workContainer}>
                                                   <Typography style={webStyles.lists}>{webConfigJSON.payForWork}</Typography>
                                                   <Typography style={webStyles.details} color="secondary">
                                                   Active Contracts are useful for keeping track of payments and reviewing work. As you complete jobs, you can build
                                                   trusting relationships with IP Professionals in a way that helps you both grow.
                                                   </Typography>
                                               </Box>
                                           </Grid>
                                       </Grid>
                                   </Box>
                               </Grid>
                           </Grid>
                       </Box>
                   </Grid>
                   <Grid item xs={12} sm={4} md={4}>
                       <Box>
                           <Grid container>
                               <Grid item xs={12} sm={12} md={12} style={webStyles.btnContainer}>
                                   <CustomButton variant="contained" onClick={this.handlePostJob} data-test-id="handlePostJob">{webConfigJSON.postJobBtn}</CustomButton>
                               </Grid>
                               <Grid item xs={12} sm={12} md={12}>
                                   <Box style={webStyles.accordionContainer}>
                                       <Accordion style={webStyles.accordion}>
                                           <AccordionSummary
                                               expandIcon={<ArrowForwardIosRoundedIcon />}
                                               aria-controls="panel1a-content"
                                               id="panel1a-header"
                                           >
                                               <Box>
                                                   <Typography style={webStyles.exploreMoreStyle}>{webConfigJSON.exploreMore}</Typography>
                                                   <Typography style={webStyles.descriptionStyleGrey}>{webConfigJSON.descriptionExploreMore}</Typography>
                                               </Box>
                                           </AccordionSummary>
                                           <AccordionDetails>
                                               <Typography>{webConfigJSON.dummyText}</Typography>
                                           </AccordionDetails>
                                       </Accordion>
                                   </Box>
                               </Grid>
                               <Grid item xs={12} sm={12} md={12}>
                                   <Box style={webStyles.accordionContainer}>
                                       <Accordion style={webStyles.accordion}>
                                           <AccordionSummary
                                               expandIcon={<ArrowForwardIosRoundedIcon />}
                                               aria-controls="panel2a-content"
                                               id="panel2a-header"
                                           >
                                               <Box>
                                                   <Typography style={webStyles.exploreMoreStyle}>{webConfigJSON.AddBilling}</Typography>
                                               </Box>

                                           </AccordionSummary>
                                           <AccordionDetails>
                                               <Typography>{webConfigJSON.dummyText}</Typography>
                                           </AccordionDetails>
                                       </Accordion>
                                   </Box>
                               </Grid>
                           </Grid>
                       </Box>
                   </Grid>
               </Grid>
           </ThemeProvider>
           <FooterWeb/>
           </>
           
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const webStyles = {
    gridMainContainer: {
        padding: '52px',
        justifyContent: 'space-between'
    },
    dashboardText: {
        fontFamily:"Rubik",
        fontSize: '32px',
        fontWeight: 400,
    },
    firstNameStyle:{
        fontFamily:"Rubik",
        fontSize: '20px',
        fontWeight: 400,
    },
    postingContainer: {
        background: '#FAFBFC',
        padding: '15px',
        borderRadius: '18px',
        marginTop: '3%'
    },
    accordionContainer: {
        background: '#FAFBFC',
        borderRadius: '5px',
        marginBottom:"2%"
    },
    seePost: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '2%'
    },
    seeDraft: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '1%'
    },
    yourPostText: {
        fontWeight: 400,
        fontSize:"18px"
    },
    seeAllPostText: {
        fontSize: '16px',
        cursor:"pointer"
    },
    createdAtStyle:{
        fontSize: '16px',
    },
    countBox: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    DescriptionStyle: {
        fontSize: '22px',
        width: '65%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap' as 'nowrap',
        marginBottom: '2%'
    },
    howToWork: {
        fontSize: '22px',
        fontFamily:"Rubik"
    },
    workContainer: {
        marginBottom: '2.5%'
    },
    details: {
        fontSize: '14px',
        fontFamily:'Rubik',
        marginTop: '1%'
    },
    lists: {
        fontSize: '22px',
        fontFamily:'Rubik',
    },
    btnContainer: {
        textAlign: 'end' as 'end',
        marginBottom: '20px'
    },
    accordion: {
        background: 'none',
        boxShadow: 'none'
    },
    subText: {
        fontSize: '0.8rem',
        marginTop: '2%'
    },
    iconButton: {
        background: '#DBE7F4',
        borderRadius: '50%'
    },
    exploreMoreStyle:{
        fontSize:"16px",
        fontFamily:"Rubik"
    },
    descriptionStyleGrey:{
        fontSize:"14px",
        fontFamily:"Rubik",
        color:"#767677",
        marginTop:"11px",
        maxWidth:"400px"

    }


};

// Customizable Area End
