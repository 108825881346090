import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { config } from "./NavBar.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorEl: null | HTMLElement;
  ipJobs: null | HTMLElement;
  ipTalent: null | HTMLElement;
  anchorElNav: null,
  anchorElUser: null,
  pages:any[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class NavBarController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
    ];

    this.state = {
      anchorEl: null,
      ipJobs: null,
      ipTalent: null,
      anchorElNav: null,
      anchorElUser: null,
      pages : [config.home, config.howToHire, config.howToFindWork, config.about]

    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  handleIpJobClick = (event: React.MouseEvent<HTMLElement>) => {
    this.props.navigation.navigate("LandingPage");
  }
  handleIpTalentClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ ipTalent: event.currentTarget })
  }
  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget })
  }
  handleClose = () => {
    this.setState({ anchorEl: null, ipJobs: null, ipTalent: null })
  }
  handleUsertypeSelection2 = () => {
      this.props.navigation.navigate("EmailAccountLoginBlock");
  }
    
  handleUsertypeSelection = () => {
    const loginmessage: Message = new Message(getName(MessageEnum.NavigationMessage))
    loginmessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'UserTypeSelection'
    );
    loginmessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(loginmessage);
    runEngine.sendMessage(loginmessage.id, loginmessage)
  }

  handleAbout = () => {
    const aboutMessage: Message = new Message(getName(MessageEnum.NavigationMessage))
    aboutMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'About'
    );
    aboutMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(aboutMessage);
    runEngine.sendMessage(aboutMessage.id, aboutMessage)
  }
  handleRedirection = (componentName: string) => {
    if (componentName) {
      this.props.navigation.navigate(componentName);
    }
  };
  handleOpenNavMenu = (event: { currentTarget: any; }) => {
    this.setState({ anchorElNav: event.currentTarget });
};

handleOpenUserMenu = (event: { currentTarget: any; }) => {
    this.setState({ anchorElUser: event.currentTarget });
};

handleCloseNavMenu = () => {
    this.setState({ anchorElNav: null });
};

handleCloseUserMenu = () => {
    this.setState({ anchorElUser: null });
};
  // Customizable Area End
}