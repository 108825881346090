import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import { imgPasswordVisible, imgPasswordInVisible } from "./assets";
import storage from "../../../framework/src/StorageProvider";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountType: string;
  emailSchema: any;
  phoneSchema: any;
  otpSchema: any;
  passwordSchema: any;
  accountStatus: any;
  passwordRules: any;
  emailValue: any;
  phoneValue: any;
  countryCodeSelected: any;
  token: any;
  enablePasswordField: Boolean;
  btnConfirmPasswordShowHide: Boolean;
  emailId: string;
  validationError: boolean;
  errorMessage: string;
  confirmErrorMessage: string;
  confirmRequireError: boolean;
  requireError: boolean;
  newPassword: string;
  showPassword: boolean;
  confirmPassword: string;
  showConfirmPassword: boolean;
  confirmValidation: boolean;
  popUpMessage: boolean;
  authToken: string;
  userEmail: string;
  mailNotification: string;
  passwordToken: string | null;
  isDialogOpen:boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationAPICallId: any;
  requestEmailOtpCallId: any;
  requestPhoneOtpCallId: any;
  requestChangePasswordCallId: any;
  requestGoToConfirmationCallId: any;
  otpToken: any;
  isChangePassword: boolean = false;
  forgotPasswordId:string;
  newPasswordId: string;

  //Properties from config
  labelTextIsAccountRecovery: string = configJSON.labelTextIsAccountRecovery;
  secondLabelText: string = configJSON.secondLabelText;
  thirdLabelText: string = configJSON.thirdLabelText;
  forthLabelText: string = configJSON.forthLabelText;
  fifthLabelText: string = configJSON.fifthLabelText;
  sixthLabelText: string = configJSON.sixthLabelText;
  firstInputAutoCompleteType: any = configJSON.firstInputAutoCompleteType;
  firstInputKeyboardStyle: any = configJSON.firstInputKeyboardStyle;
  firstInputPlaceholder: string = configJSON.firstInputPlaceholder;
  firstInputErrorColor: any = configJSON.firstInputErrorColor;
  buttonTextIsNext: string = configJSON.buttonTextIsNext;
  buttonColorForNextButton: any = configJSON.buttonColorForNextButton;
  secondInputAutoCompleteType: any = configJSON.secondInputAutoCompleteType;
  secondInputKeyboardType: any = configJSON.secondInputKeyboardType;
  secondInputPlaceholder: string = configJSON.secondInputPlaceholder;
  secondInputErrorColor: any = configJSON.secondInputErrorColor;
  thirdInputPlaceholder: string = configJSON.thirdInputPlaceholder;
  thirdInputErrorColor: any = configJSON.thirdInputErrorColor;
  buttonTitleIsSMSPhoneAccount: string =
    configJSON.buttonTitleIsSMSPhoneAccount;
  buttonTitleIsEmailAccount: string = configJSON.buttonTitleIsEmailAccount;
  labelTextIsPleaseEnterYourNewPassword: string =
    configJSON.labelTextIsPleaseEnterYourNewPassword;
  labelTextIsYourPasswordHasBeenSuccessfullyChanged: string =
    configJSON.labelTextIsYourPasswordHasBeenSuccessfullyChanged;
  placeholderIsPassword: string = configJSON.placeholderIsPassword;
  imgPasswordInVisible: any = imgPasswordInVisible;
  imgPasswordVisible: any = imgPasswordVisible;
  placeholderIsReTypePassword: string = configJSON.placeholderIsReTypePassword;
  buttonTitleIsOk: string = configJSON.buttonTitleIsOk;
  buttonColorForOkButton: any = configJSON.buttonColorForOkButton;
  countryCodeSelectorPlaceholder: string =
    configJSON.countryCodeSelectorPlaceholder;
  emailReg = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  passwordReg = /^(?=.*\d)(?=.*[a-z])(?=.*[^a-zA-Z0-9])(?!.*\s).{7,15}$/;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.forgotPasswordId = ""
    this.newPasswordId = ""
    //email schema
    let emailSchema = {
      email: Yup.string()
        .email(configJSON.pleaseEnterAValidEmail)
        .required(configJSON.emailIsRequired),
    };

    //phone schema
    let phoneSchema = {
      // countryCode: Yup.number()
      // .required("Country code is required"),

      phone: Yup.string()
        .matches(configJSON.phoneRegExp, configJSON.phoneNumberIsNotValid)
        .required(configJSON.phoneNumberIsRequired),
    };

    //otpSchema
    let otpSchema = {
      otpCode: Yup.number().min(4).required(configJSON.otpCodeIsRequired),
    };

    //passwordSchema
    let passwordSchema = {
      password: Yup.string()
        .required(configJSON.pleaseEnterAPassword)
        .min(2, configJSON.passwordMustBeAtLeast2Characters),
      confirmPassword: Yup.string()
        .required(configJSON.pleaseConfirmYourPassword)
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            configJSON.passwordsMustMatch
          ),
        }),
    };

    this.state = {
      accountType: "sms",
      accountStatus: "ChooseAccountType",
      emailValue: "",
      phoneValue: "",
      countryCodeSelected: "",
      passwordRules: "",
      emailSchema: emailSchema,
      phoneSchema: phoneSchema,
      otpSchema: otpSchema,
      passwordSchema: passwordSchema,
      token: "",
      enablePasswordField: true,
      btnConfirmPasswordShowHide: true,
      emailId:'',
      validationError:false,
      errorMessage:'',
      confirmErrorMessage: '',
      requireError:false,
      confirmRequireError: false,
      newPassword:'',
      showPassword:false,
      confirmPassword:'',
      showConfirmPassword:false,
      confirmValidation:false,
      popUpMessage: false,
      authToken:'',
      userEmail:'',
      mailNotification:'',
      passwordToken:'',
      isDialogOpen:false
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.validationRulesRequest();
    // Customizable Area Start
    const queryParams = new URLSearchParams(window.location.search);
    this.setState({
      authToken: await storage.get("authToken"),
      userEmail : await storage.get("UserEmail"),
      passwordToken: queryParams.get('token')
    })
    // Customizable Area End
  }

  validationRulesRequest = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileValidationSettingsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );

      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ token: otpAuthTkn });
        if (this.isChangePassword) {
          this.setState({ accountStatus: "ChangePassword" });
        }
        this.otpToken = this.state.token;
        // runEngine.debugLog("otpAuthTkn", this.state.token);
      } else {
        const accountType = message.getData(
          getName(MessageEnum.NavigationForgotPasswordPageInfo)
        );
        if (accountType) {
          this.startForgotPassword(accountType);
        }
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.validationAPICallId &&
      this.validationAPICallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson === undefined) {
        return;
      }

      if (responseJson && responseJson.data[0]) {
        const passRegex = RegExp(
          responseJson.data[0].password_validation_regexp
        );
        const emailRegex = RegExp(responseJson.data[0].email_validation_regexp);
        const passwordRulesFromValidation =
          responseJson.data[0].password_validation_rules;

        this.setState({
          //email schema
          emailSchema: {
            email: Yup.string()
              .email(configJSON.pleaseEnterAValidEmail)
              .required(configJSON.emailIsRequired)
              .matches(emailRegex, configJSON.invalidEmailAddress),
          },
          //password schema
          passwordSchema: {
            password: Yup.string()
              .required(configJSON.pleaseEnterAPassword)
              .matches(passRegex, configJSON.invalidPassword),

            confirmPassword: Yup.string()
              .required(configJSON.pleaseConfirmYourPassword)
              .when("password", {
                is: (val) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                  [Yup.ref("password")],
                  configJSON.passwordsMustMatch
                ),
              }),
          },
          passwordRules: passwordRulesFromValidation,
        });
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.requestEmailOtpCallId !== null &&
      this.requestEmailOtpCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      // console.log("entered email!!!!");
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (
        responseJson !== undefined &&
        responseJson.meta &&
        responseJson.meta.token
      ) {
        this.otpToken = responseJson.meta.token;

        this.setState({ token: this.otpToken });

        //navigate to OTP page
        const msg: Message = new Message(
          getName(MessageEnum.NavigationMobilePhoneOTPMessage)
        );

        msg.addData(
          getName(MessageEnum.AuthTokenDataMessage),
          this.state.token
        );

        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

        msg.addData(
          getName(MessageEnum.AuthTokenEmailMessage),
          this.state.emailValue
        );

        msg.addData(getName(MessageEnum.EnterOTPAsForgotPasswordMessage), true);

        this.send(msg);
      }
      //error handling
      else if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.requestPhoneOtpCallId !== null &&
      this.requestPhoneOtpCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      // console.log("entered phone!!!!");
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (
        responseJson !== undefined &&
        responseJson.meta &&
        responseJson.meta.token
      ) {
        this.otpToken = responseJson.meta.token;
        this.setState({ token: this.otpToken });

        const msg: Message = new Message(
          getName(MessageEnum.NavigationMobilePhoneOTPMessage)
        );
        msg.addData(
          getName(MessageEnum.AuthTokenDataMessage),
          this.state.token
        );

        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

        msg.addData(
          getName(MessageEnum.AuthTokenPhoneNumberMessage),
          this.state.phoneValue
        );

        msg.addData(getName(MessageEnum.EnterOTPAsForgotPasswordMessage), true);

        this.send(msg);
      }
      //error handling
      else if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.requestGoToConfirmationCallId !== null &&
      this.requestGoToConfirmationCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      // console.log("entered 3");
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson !== undefined && responseJson.data) {
        this.setState({
          accountStatus: "Confirmation",
        });
      } else if (responseJson !== undefined && responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode,
        });
      }
    }
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      
      if (this.forgotPasswordId === requestCallId) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson.message) {
          this.setState({
            popUpMessage: true,
            mailNotification: responseJson.message
          });
        } else if (responseJson.error) {
          this.setState({
            popUpMessage: true,
            mailNotification: responseJson.error
          });
        } else {
          const errorMessage = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.parseApiCatchErrorResponse(errorMessage);
        }
      }
      
      if (this.newPasswordId === requestCallId) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        console.log("API response:", responseJson);
        
        if (responseJson && responseJson.message === "Your password has been reset successfully") {
          this.setState({ isDialogOpen: true });
        } else if (responseJson && responseJson.error === "New password cannot be the same as the old password.") {
          this.setState(
            {
              validationError: true,
              errorMessage: "* New password cannot be the same as the old password."
            },
          );
        }
        else {
          const errorMessage = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.parseApiCatchErrorResponse(errorMessage);
        }
      }
    }
  
    // Customizable Area End
  }

  startForgotPassword(accountType: String) {
    this.setState({
      accountStatus: accountType === "sms" ? "EnterPhone" : "EnterEmail",
    });
  }

  goToOtpAfterEmailValidation(values: { accountType: string; email: string }) {
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestEmailOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryStartOtpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({
      emailValue: values.email ? values.email : "",
    });

    const data = {
      type: values.accountType ? values.accountType : "email_account",
      attributes: {
        email: values.email ? values.email : "",
      },
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToOtpAfterPhoneValidation(values: { phone: string }) {
    // console.log("entered phone validation code");
    if (
      !this.state.countryCodeSelected ||
      this.state.countryCodeSelected.length === 0
    ) {
      this.showAlert(
        configJSON.goToOtpAfterPhoneValidationErrorTitle,
        configJSON.goToOtpAfterPhoneValidationErrorBody
      );
      return;
    }
    console.log(this.state.countryCodeSelected);
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestPhoneOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryStartOtpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({
      phoneValue:
        this.state.countryCodeSelected && values.phone
          ? this.state.countryCodeSelected + values.phone
          : "",
    });

    const data = {
      type: "sms_account",
      attributes: {
        full_phone_number: this.state.phoneValue,
      },
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToChangePasswordAfterOtp(values: { otpCode: string }) {
    //change status to change password
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestChangePasswordCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryConfirmOtpAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: this.otpToken ? this.otpToken : "",
      otp_code: values.otpCode ? values.otpCode : "",
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToConfirmationAfterPasswordChange(values: {
    password: any;
    confirmPassword: any;
  }) {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestGoToConfirmationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryChangePasswordAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: this.otpToken ? this.otpToken : "",
      new_password: values.password,
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  // Customizable Area Start
  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ emailId: event.target.value });
    if (!this.emailReg.test(event.target.value)) {
      this.setState({
        validationError: true,
        errorMessage: "Please enter a valid email address."
      });
    } else {
      this.setState({ validationError: false, errorMessage: "" });
    }
  };

  handleSubmit = () => {
    switch (true) {
      case !this.state.emailId:
        this.setState({
          requireError: true,
          validationError: false,
          errorMessage: "Please enter your email address.",
        });
        break;
      case !this.emailReg.test(this.state.emailId):
        this.setState({
          requireError: false,
          validationError: true,
          errorMessage: "Please enter a valid email address.",
        });
        break;
      default:
        this.setState({
          requireError: false,
          validationError: false,
          errorMessage: "",
          emailId: ""
        });
        this.handleRequestLink();
        break;
    }
  };

  handleBackToLogin = () => {
    const signInmessage: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    signInmessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLoginBlock"
    );
    signInmessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(signInmessage);
  };


  handleNewPassword = (event: any) => {
    const password = event.target.value;
    this.setState({ newPassword: password });

    let errorMessage = "";

    if (password.length < 8 || password.length > 12) {
        errorMessage = "Password must be between 8 and 12 characters long.";
    }
    else if (!/[a-z]/.test(password)) {
        errorMessage = "Password must contain at least one lowercase letter.";
    }
    else if (!/[A-Z]/.test(password)) {
        errorMessage = "Password must contain at least one uppercase letter.";
    }
    else if (!/[0-9]/.test(password)) {
        errorMessage = "Password must contain at least one number.";
    }
    else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
        errorMessage = "Password must contain at least one special character.";
    }

    if (errorMessage) {
        this.setState({
            validationError: true,
            requireError: false, 
            errorMessage: `* ${errorMessage}`
        });
    } else {
        this.setState({
            validationError: false,
            requireError: false,
            errorMessage: ""
        });
    }
};


  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
  handleConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const confirmPassword = event.target.value;
    
    this.setState((prevState) => {
        const passwordsMatch = prevState.newPassword === confirmPassword;

        return {
            confirmPassword,
            confirmRequireError: false,
            confirmValidation: !passwordsMatch,
            confirmErrorMessage: passwordsMatch ? "" : "* Passwords did not match"
        };
    });
};

  handleClickConfirmShowPassword = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  };
  handleResetPassword = () => {
    const { newPassword, confirmPassword, validationError } = this.state;
    if (validationError) {
        this.setState({
            errorMessage: "* Please resolve the validation errors before resetting the password"
        });
        return;
    }

    switch (true) {
        case !newPassword || !confirmPassword:
            this.setState({
                requireError: true,
                confirmRequireError: true,
                errorMessage: "Please enter a new password.",
                confirmErrorMessage: "Please enter a confirm password."
            });
            break;

        case newPassword !== confirmPassword:
            this.setState({
                confirmValidation: true,
                errorMessage: "* Passwords did not match"
            });
            break;

        default:
            this.setState({
              validationError: false,
              requireError: false,
              errorMessage: '',
              confirmValidation: false,
              confirmRequireError: false,
              confirmErrorMessage: ''
            });
            this.handleNewPasswordAPI();
    }
};
  handleDialog = () => {
    this.setState({
      popUpMessage: !this.state.popUpMessage
    });
  };
  handleCloseDialog = () => {
    this.setState({ isDialogOpen: false });
    this.goToLogin()
  };
  handleRequestLink = () => {
    const passwordMessage = new Message(
     getName(MessageEnum.RestAPIRequestMessage)
   );
   const header = {
     "Content-Type": configJSON.forgotPasswordAPiContentType
   };
   const body = {
    "email":this.state.emailId
  };
   this.forgotPasswordId = passwordMessage.messageId;
   passwordMessage.addData(
     getName(MessageEnum.RestAPIResponceEndPointMessage), 
     configJSON.apiEndPointForgotPassword
   );
   passwordMessage.addData(
     getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(header)
   );
   passwordMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  )
  passwordMessage.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.httpPostMethod
   );
   runEngine.sendMessage(passwordMessage.id, passwordMessage);
 }

 handleNewPasswordAPI = () => {
  const newPasswordMessage = new Message(
   getName(MessageEnum.RestAPIRequestMessage)
 );
 const header = {
   "Content-Type": configJSON.forgotPasswordAPiContentType
 };
 const body = {
  "new_password":this.state.newPassword,
  "password_confirmation":this.state.confirmPassword
};
 this.newPasswordId = newPasswordMessage.messageId;
 newPasswordMessage.addData(
   getName(MessageEnum.RestAPIResponceEndPointMessage), 
   configJSON.apiEndPointNewPassword + this.state.passwordToken
 );
 newPasswordMessage.addData(
   getName(MessageEnum.RestAPIRequestHeaderMessage),
   JSON.stringify(header)
 );
 newPasswordMessage.addData(
  getName(MessageEnum.RestAPIRequestBodyMessage),
  JSON.stringify(body)
)
newPasswordMessage.addData(
   getName(MessageEnum.RestAPIRequestMethodMessage),
   configJSON.httpPutMethod
 );
 runEngine.sendMessage(newPasswordMessage.id, newPasswordMessage);
}

handleLogoClick = ()=>{
  this.props.navigation.navigate("LandingPage");
}

handleLogoClick1 = ()=>{
  this.props.navigation.navigate("LandingPage");
}
handleLogoClick2 = ()=>{
  this.props.navigation.navigate("LandingPage");
}
handleLogoClick3 = ()=>{
  this.props.navigation.navigate("LandingPage");
}
  // Customizable Area End
}
