import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    openBillingModal: boolean
    openPaymentCardModal:boolean
    selectedValue: string,
    showPayPalSection: boolean
    showAddPaymentCard: boolean
    // Customizable Area End
}
interface SS { }

export default class FreelancerBillingInformationController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            openBillingModal: false,
            openPaymentCardModal:false,
            selectedValue: 'PaymentCard',
            showPayPalSection: false,
            showAddPaymentCard:false
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getDashboardData();
        // Customizable Area Start
        // Customizable Area End
    }

    getDashboardData(): boolean {
        // Customizable Area Start

        // Customizable Area End
        return true;
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start 
    handleCloseBillingModal =()=>{
        this.setState({ openBillingModal: false });
    }
    handleNextButton = () => {
        if (this.state.selectedValue === 'PayPal') {
            this.setState({ showPayPalSection: true });
        } else if (this.state.selectedValue === 'PaymentCard') {
            this.setState({ openPaymentCardModal: true });
        }
        this.handleCloseBillingModal();
    };
    handleBillingMethod = ()=>{
        this.setState({ openBillingModal: true });
    }
    handlePaymentChange = (event: { target: { value: any; }; }) => {
        const { value } = event.target;
        this.setState({
            selectedValue: value,
            showPayPalSection: value === 'PayPal' || this.state.showPayPalSection,
            showAddPaymentCard: value === 'PaymentCard' || this.state.showAddPaymentCard,
        });
    };
    
    handleClosePaymentCardModal = ()=>{
        this.setState({ openPaymentCardModal: false });
    }
    handlePaymentCardSaveBtn = ()=>{
        this.setState({ openPaymentCardModal: false,showAddPaymentCard:true });
    }
    handleDeleteMethod = (method: string) => {
        if (method === "PayPal") {
          this.setState({ showPayPalSection: false });
        } else if (method === "PaymentCard") {
          this.setState({ showAddPaymentCard: false });
        }
      };
        // Customizable Area End
}