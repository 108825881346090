import { IBlock } from 'framework/src/IBlock';
import { Message } from 'framework/src/Message';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
import MessageEnum, { getName } from 'framework/src/Messages/MessageEnum';
export const webConfigJSON = require('./config.js');
import { callApi } from '../../../components/src/Toolkit';
import storage from 'framework/src/StorageProvider';
// Customizable Area Start
// Customizable Area End
export interface Props {
  navigation: any;
  // Customizable Area Start
// Customizable Area End
}
interface S {
  // Customizable Area Start
  activeStep: any;
  errorMsg: string;
  loading: boolean;
  jobPostedData:  {
    id: string,
    type: string,
    attributes: {
        id: number,
        name: string,
        description: string,
        body: string,
        job_title: string,
        job_description: string,
        per_day_charge: string,
        budget: string,
        duration: string,
        employment_term: string,
        estimate: string,
        skills: any[],
        location: string,
        file: {
            url: null
        },
        account_id: number,
        created_at: string,
        updated_at: string,
        proposals: number,
        messaged:number,
        hired:number,
        is_drafted: boolean,
        est_budget: number,
        last_viewed_by_client: string,
        rating: number,
        intermediate: string,
        model_name: string,
        images_and_videos: any[],
        file_name: string,
        project_length:string
        patents: Array<{
          id: number;
          categories: {
            id: number;
            name: string;
            sub_categories: Array<{
              id: number;
              name: string;
              sub_category_menus: Array<{
                id: number;
                title: string;
                sub_category_sub_menus: Array<{
                  id: number;
                  title: string;
                  description: string;
                }>;
              }>;
            }>;
          };
        }>;
    }
},
  proposalsData: any[];
  firstName: any;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
// Customizable Area End
}

export default class ViewJobController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getJobPostDetailCallId: string = '';
  getProposalsCallId: string = '';
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start

    this.state = {
      activeStep: 'VIEW_JOB_POST',
      errorMsg: '',
      loading: false,
      jobPostedData:  {
        id: "",
        type: "",
        attributes: {
            id: 654,
            name: "",
            description: "",
            body: " ",
            job_title: "",
            job_description: "",
            per_day_charge: "",
            budget: "",
            duration: "",
            employment_term: "",
            estimate: "",
            skills: [],
            location: "",
            file: {
                url: null
            },
            account_id: 965,
            created_at: "",
            updated_at: "",
            proposals: 0,
            messaged: 0,
            hired: 0,
            is_drafted: false,
            est_budget: 0,
            last_viewed_by_client: "",
            rating: 0,
            intermediate: "",
            model_name: "",
            images_and_videos: [],
            file_name: "",
            project_length:"",
            patents: [
              {
                id: 0,
                categories: {
                  id: 0,
                  name: "",
                  sub_categories: [
                    {
                      id: 0,
                      name: "",
                      sub_category_menus: [
                        {
                          id: 0,
                          title: "",
                          sub_category_sub_menus: [
                            {
                              id: 0,
                              title: "",
                              description: "",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              },
            ],
        }
    },
      proposalsData: [],
      firstName: localStorage.getItem("accountHolderName"),

    };
    
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionResponseData)
    ];
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
 // Customizable Area Start
    const jobId = await storage.get('jobId');
    const authToken = await storage.get('authToken');

    this.getJobDetails({ jobId, authToken });
    this.getProposals({ jobId, authToken });
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson != null) {
        if (responseJson) {
          await this.parseApiSuccess(responseJson, apiRequestCallId);
        }
      }
    }

    // Customizable Area End
  }
  // Customizable Area Start
  handleredirection = async () => {
    const first_login = await storage.get("is_first_time_login")

    if (first_login !== "null") {
      this.props.navigation.navigate('PostJob');
    } else {
      this.props.navigation.navigate('JobPosting');

    }
  };
  handleNext = () => {

    const { activeStep } = this.state;

    if (activeStep === 'VIEW_JOB_POST') {
      this.setState({ activeStep: "REVIEW_PROPOSALS" });
    }
    else if (activeStep === 'REVIEW_PROPOSALS') {
      this.setState({ activeStep: "HIRE" });
    }
  };

  handleBack = () => {
    const { activeStep } = this.state;

    if (activeStep === 'REVIEW_PROPOSALS') {
      this.setState({ activeStep: 'VIEW_JOB_POST' });
    } else if (activeStep === 'HIRE') {
      this.setState({ activeStep: 'REVIEW_PROPOSALS' });
    }
  };


  changeCurrentStep(currentStep: any) {
    this.setState({ activeStep: currentStep });
  }

  getJobDetails = async ({ jobId, authToken }: any) => {
    this.setState({ loading: true });
    this.getJobPostDetailCallId = callApi(
      {
        contentType: webConfigJSON.contentTypeApi,
        method: webConfigJSON.getJobPostDetailMethod,
        endPoint: `${webConfigJSON.getJobPostDetailEndPoint}/${jobId}`,
        headers: { token: authToken }
      },
      runEngine
    );
  };

  getProposals = async ({ jobId, authToken }: any) => {
    this.setState({ loading: true });

    this.getProposalsCallId = callApi(
      {
        contentType: webConfigJSON.contentTypeApi,
        method: webConfigJSON.getJobPostDetailMethod,
        endPoint: `bx_block_proposals/proposals/job_proposals?post_id=${jobId}`,
        headers: { token: authToken }
      },
      runEngine
    );
  };

  parseApiSuccess = async (responseJson: any, apiRequestCallId: string) => {
    if (apiRequestCallId === this.getJobPostDetailCallId) {
      if (responseJson.data) {
        this.setState({ jobPostedData: responseJson.data, loading: false });
      }
    }
    if (apiRequestCallId === this.getProposalsCallId) {
      if (responseJson.data) {
        this.setState({ proposalsData: responseJson.data, loading: false });
      }
    }
  };
  // Customizable Area End
}
