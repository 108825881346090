import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    IconButton,
    Menu,
    MenuItem,
    AppBar,
    Toolbar,
    Container,
    styled
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import MenuIcon from '@material-ui/icons/Menu';
export const config = require("./config");
import {Horizontalinventohub} from "./assets"

const CustomAppBar = styled(AppBar)({
    background: '#E6EBEF',
    padding: '48px',
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow:"none",
    '& .parent-navbar': {
        maxWidth: 1470,
        margin: 'auto',
        width: '100%',
    },
    '@media(max-width: 1100px)': {
        padding: 24,
    },
    '@media(max-width: 600px)': {
        '& .logo-img': {
            width: '150px !important'
        }
    },
});
// Customizable Area End

import NavBarController, { Props } from "./NavBarController";
import { cursorTo } from "readline";

const theme = createTheme({
    palette: {
        primary: {
            main: "#206FC4"
        }
    },
    typography: {
        fontFamily: "Inter,Poppins,Roboto"
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
});

export default class NavBar extends NavBarController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            // Required for all blocks

            <ThemeProvider theme={theme}>
            <CustomAppBar position="static">
                <Box className="parent-navbar">
                    <Toolbar disableGutters style={{display:"flex",justifyContent:"space-between"}}>
                    <Box style={styles.navOuterContainer}>
                      <Box style={styles.navInnerContainer}>
                        <img
                            className="logo-img"
                            src={Horizontalinventohub}
                            alt="Logo"
                            style={styles.invento as React.CSSProperties}
                            onClick={this.handleIpJobClick}
                        />

                        {/* Menu for larger screens */}
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                              <Box style={styles.menuOuterBox}>
                                <IconButton
                                        data-testid="ipJob-menu"
                                        aria-label="more"
                                        aria-controls="ip-jobs"
                                        onClick={this.handleIpJobClick}
                                        style={styles.iconButton}
                                    >
                                        <Typography style={styles.menuText}>{config.home}</Typography>
                                    </IconButton>
                                    <IconButton
                                        data-testid="ipTalent-menu"
                                        aria-label="more"
                                        aria-controls="ip-talent"
                                        onClick={(event)=>{this.handleIpTalentClick(event);this.handleRedirection("HowToHire");}}
                                        style={styles.iconButton}
                                    >
                                        <Typography style={styles.menuText}>{config.howToHire}</Typography>
                                    </IconButton>
                                    <IconButton
                                        data-testid="reports-menu"
                                        aria-label="more"
                                        aria-controls="reports"
                                        onClick={(event)=>{this.handleClick(event);this.handleRedirection("HowToFindWork");}}
                                        style={styles.iconButton}
                                    >
                                        <Typography style={styles.menuText}>{config.howToFindWork}</Typography>
                                    </IconButton>
                                    <IconButton
                                        data-testid="about-btn"
                                        aria-label="more"
                                        aria-controls="reports"
                                        style={styles.iconButton}
                                        onClick={this.handleAbout} 
                                    >
                                    <Typography style={styles.menuText}>{config.about}</Typography>
                                    </IconButton>
                                </Box>
                                </Box>
                                </Box>
                        </Box>
                            <Box sx={{...styles.buttonContainer, display: { xs: 'none', md: 'flex' }}} >
                                <Button style={styles.loginBtn} data-testid="login" onClick={this.handleUsertypeSelection2}>{config.login}</Button>
                                <Button variant="contained" color="primary" data-testid="signUp" style={styles.signUpBtn} onClick={this.handleUsertypeSelection}>{config.signUp}</Button>
                            </Box>

                         <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' },justifyContent:"end",alignItems:"center" }}>
                            <IconButton
                                aria-label="open navigation menu"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={this.handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon style={{color:"#364BA0"}} />
                            </IconButton>
                            
                            <Menu
                                id="menu-appbar"
                                anchorEl={this.state.anchorElNav}
                                keepMounted
                                getContentAnchorEl={null} 
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                  }}
                                open={Boolean(this.state.anchorElNav)}
                                onClose={this.handleCloseNavMenu}
                            >
                                <MenuItem onClick={this.handleCloseNavMenu}>
                                    <Typography onClick={this.handleIpJobClick} style={styles.menuText}>
                                        {config.home}
                                    </Typography>
                                </MenuItem>
                                <MenuItem onClick={this.handleCloseNavMenu}>
                                    <Typography 
                                     data-testid="ipTalentMenu2"
                                     onClick={(event) => { this.handleIpTalentClick(event); this.handleRedirection("HowToHire"); }} style={styles.menuText}>
                                        {config.howToHire}
                                    </Typography>
                                </MenuItem>
                                <MenuItem onClick={this.handleCloseNavMenu}>
                                    <Typography 
                                    data-testid="handleClick"
                                    onClick={(event) => { this.handleClick(event); this.handleRedirection("HowToFindWork"); }} style={styles.menuText}>
                                        {config.howToFindWork}
                                    </Typography>
                                </MenuItem>
                                <MenuItem onClick={this.handleCloseNavMenu}>
                                    <Typography onClick={this.handleAbout} style={styles.menuText}>
                                        {config.about}
                                    </Typography>
                                </MenuItem>
                                <MenuItem 
                                data-testid="handleUsertypeSelection2" 
                                onClick={() => { 
                                    this.handleCloseNavMenu();
                                    this.handleUsertypeSelection2(); 
                                }}>
                                    <Typography style={styles.menuText}>
                                        Login
                                    </Typography>
                                </MenuItem>

                                <MenuItem 
                                data-testid="handleUsertypeSelection" 
                                onClick={() => { 
                                    this.handleCloseNavMenu();
                                    this.handleUsertypeSelection(); 
                                }}>
                                    <Typography style={styles.menuText}>
                                        Sign Up
                                    </Typography>
                                </MenuItem>

                            </Menu>
                        </Box>

                    </Toolbar>
                </Box>
            </CustomAppBar>
        </ThemeProvider>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = {
    menuText: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '1rem',
        fontWeight: 500,
        color: 'gray',
        cursor: 'pointer',
    },
    navOuterContainer: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap' as 'wrap',
    },
    navInnerContainer: {
        display: 'flex',
        gap: '50px',  // Adjust for larger screens
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-between',
            width: '100%',
            gap: '20px',  // Reduced gap on smaller screens
        },
    },
    menuOuterBox: {
        display: 'flex',
        gap: '10px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        flexWrap: 'wrap' as 'wrap',
        marginTop: '6px',
    },
    invento: {
        width: '223px',
        height: '40px',
        objectFit: 'cover',
        cursor: 'pointer',
    },
    menuIcon: {
        marginLeft: 'auto',  // Pushes the icon to the right
        [theme.breakpoints.down('sm')]: {
            marginLeft: '20px',  // Add space between logo and icon on small screens
        },
    },
    buttonContainer: {
        gap: '30px',
    },
    iconButton: {
        height: '0',
    },
    signUpBtn: {
        borderRadius: '15px',
        padding: '0 20px',
    },
    loginBtn: {
        textTransform: 'capitalize' as 'capitalize',
    },
};


// Customizable Area End

