import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    // Customizable Area Start
    id: string;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    openPasswordModal: boolean
    isDialogOpen: boolean
    countdown: number,
    // Customizable Area End
}
interface SS { }

export default class FreelancerContactInformationController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            openPasswordModal: false,
            isDialogOpen: false,
            countdown: 3,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getDashboardData();
        // Customizable Area Start
        // Customizable Area End
    }

    getDashboardData(): boolean {
        // Customizable Area Start

        // Customizable Area End
        return true;
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start 
    handleOpenPasswordModal = () => {
        this.setState({ openPasswordModal: true });
    };

    handleClosePasswordModal = () => {
        this.setState({ openPasswordModal: false });
    };

    handleOpenDialog = () => {
        this.setState({ isDialogOpen: true });
        const countdownInterval = setInterval(() => {
            this.setState((prevState) => ({
                countdown: prevState.countdown - 1,
            }));
        }, 1000);
        setTimeout(() => {
            clearInterval(countdownInterval);
            this.setState({ isDialogOpen: false, countdown: 3 });
            this.props.navigation.navigate('LandingPage')
        }, 3000);
    };
    // Customizable Area End
}