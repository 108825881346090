export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const login = require("../assets/login.png");
export const facebookLogo = require("../assets/facebooklogo.png");
export const firmLogo = require("../assets/firm.png");
export const firmframe = require("../assets/firmframe.png");
export const freelancefirm = require("../assets/freelanceFrame.png");
export const freelancer = require("../assets/Group9.png");
export const customSvgIcon = require("../assets/downArrow.svg");
export const greenCheck = require("../assets/greenCheck.png");
export const planCheck = require("../assets/planCheck.png");
export const details = require("../assets/Shape.png");
export const radioCheck = require("../assets/radioCheck.png");
export const unCheck = require("../assets/uncheck.png");
export const checkIcon = require("../assets/checkIcon.png");
export const uploadDoc =require("../assets/uploaddoc.png")
export const successImage =require("../assets/image-1.png");
export const ellipse = require("../assets/eliipse.png");
export const image_Success = require("../assets/image_Success.png");

