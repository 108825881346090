import React from "react";
// ...


// Customizable Area Start
import {
  Typography,
  styled,
  Dialog,
  DialogContent,
  withStyles,
  Box,
  Button,
} from "@material-ui/core";
// Customizable Area End

import SuccessDialogboxController, {
  Props,
  configJSON
} from "./SuccessDialogboxController.web";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slider from '@material-ui/core/Slider';

import {
  image_Success,remind_icon
} from "./assets";
export  class SuccessDialogbox extends SuccessDialogboxController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    
    return (
        <>
     
     <CustomDialogBox
      open={this.state.isDialogOpen}
      onClose={this.handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
  >
       
      <DialogContent style={{
         textAlign: 'center',padding:"24px" }}>
          <img src={image_Success} alt="Success" style={{ width: "35%",
           borderRadius: "50%",marginTop:"20px" ,marginBottom:"10px"}} />
          <Typography gutterBottom style={webStyle.congratsText}>
              Congratulations!  </Typography>
          <Typography style={webStyle.profileCreatedText}>
          Your profile has been completed successfully!</Typography>
          <Box style={{ display: "flex",
           flexDirection: "column" }}>
              <Button
                  variant="outlined"
                  style={webStyle.blueButton}
                  onClick={this.handleHome}
              >
                 Find Work
              </Button>
              <Button
                  variant="outlined"
                  style={webStyle.transButton}
                  data-test-id="remindMeLater"
                  onClick={this.remindMeLater}
              >
                Remind me later
              </Button>
          </Box>
      </DialogContent>
  </CustomDialogBox>
      
         
        </>
        //Customizable Area End
      );
  }
}

// Customizable Area Start
export const PrettoSlider = withStyles({
  root: {
    color: '#206FC4',
    marginTop:0,
    height: 8,
  },


  track: {
    borderRadius: 4,
    height: 8,
  },
  thumb: {
    display:"none",
    height: 24,
    width: 24,
    marginTop: -8,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit'
    }
  },
  rail: {
    height: 8,
    borderRadius: 4
  },
  valueLabel: {
    left: 'calc(-50% + 4px)'
  },
})(Slider);
const CustomDialogBox = styled(Dialog)({
  '& .MuiPaper-rounded':{
    width:"480px",
    borderRadius: "18px",
  }
})
// Customizable Area End
const webStyle = {
  //Customizable Area Start
  congratsText:
  {
    marginTop:"10px",
    fontWeight: 600,
    fontFamily: "Rubik",
    fontSize: "22px",
  color: "#059669",
  },
  profileCreatedText:{
   marginBottom: '20px',
   marginTop: "18px",
   fontFamily: "Rubik",
fontSize: "14px",
fontWeight: 500,
},
profileCreatedText_remind:{
  fontWeight: 500,
  fontSize: "14px",
  fontFamily: "Rubik",
  marginBottom: '-7px',
  marginTop: "18px",
},
blueButton:{
  backgroundColor: '#364BA0',
  height:"40px",
  borderRadius:"10px",
  borderColor: '#364BA0',
  padding:"22px",
  color: '#fff',
  fontSize:"18px",
  fontFamily:"Rubik",
  marginBottom: '10px',
  fontWeight:500,
},
transButton: {
  fontFamily:"Rubik",
borderColor: '#4A4A4A',
padding:"22px",
fontSize:"18px",
height:"40px",
fontWeight:500,
color: '#4A4A4A',
borderRadius:"10px" 
},
  //Customizable Area End
  
};
export default withStyles(webStyle)
(SuccessDialogbox);
// Customizable Area End