import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import storage from "../../../framework/src/StorageProvider";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  projectType:string;
  preference:string;
  selectedLevel: string;
  areaOfExpertise: string[];
  industryFocus: string[];
  // Customizable Area End
}
interface SS { }

export default class FreelancerProfileSettingsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  freelancerProfileAPIid: string;
  ExperienceLevelAPIid:string;
  PreferencesAPIid:string;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.freelancerProfileAPIid=""
    this.ExperienceLevelAPIid=""
    this.PreferencesAPIid=""

    this.state = {
        projectType:'',
        preference:"",
        selectedLevel: "expert",
        areaOfExpertise: ['IP Licensing', 'Industrial Design', 'IP Valuation'],
        industryFocus: ['Technology', 'Media', 'Consumer Electronics', 'Automotive'],

    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.getFreelancerProfileRes()
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start

    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
    if(this.freelancerProfileAPIid === requestCallId){
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
     
      if(responseJson){
        this.handleProfileRes(responseJson)
        
      }
    }
    if(this.ExperienceLevelAPIid === requestCallId){
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson){
       this.getFreelancerProfileRes()
      } 
    }if(this.PreferencesAPIid === requestCallId){
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson){
       this.getFreelancerProfileRes()
      } 
    }
    
    
  }
    // Customizable Area End
  }
  // Customizable Area Start
  handleSelectChange = (event: any) => {
    this.setState({ preference: event.target.value as string });
    this.updatePreferences( event.target.value as string)
  };

  handleLevelChange = (level: string) => {
    this.setState({ selectedLevel: level });
    this.updateExperienceLevel(level)
  };

  getFreelancerProfileRes =async () => {
    const FreelancerProfileResponse = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "token":await storage.get('authToken')
    };
    this.freelancerProfileAPIid = FreelancerProfileResponse.messageId;
    FreelancerProfileResponse.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     "account_block/freelancers/fetch_freelancer_profile_setting"
    );
    FreelancerProfileResponse.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    FreelancerProfileResponse.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(FreelancerProfileResponse.id, FreelancerProfileResponse);
    
  }
 
  
  handleProfileRes = (res: any) => {
    if (res) {
      // Handle project preferences
      let preferenceValue = '';
  switch (res.project_preferences) {
    case "short_term_project":
      preferenceValue = 'shortTerm';
      break;
    case "long_term_project":
      preferenceValue = 'longTerm';
      break;
    case "both_short_term_and_long_term":
      preferenceValue = 'both';
      break;
    default:
      preferenceValue = ''; // No preference selected
  }
  
  this.setState({ preference: preferenceValue });
  
  // Handle selected level
  let selectedLevelValue = '';
  
  if (res.experience_level === "entry_level") {
    selectedLevelValue = "entry_level";
  } else if (res.experience_level === "intermediate") {
    selectedLevelValue = "intermediate"; 
  } else if (res.experience_level === "expert") {
    selectedLevelValue = "expert";
  } else {
    selectedLevelValue = ""; 
  }
  
  this.setState({ selectedLevel: selectedLevelValue });
  
    }
  };
  
  updateExperienceLevel=async (level:any) => {
    const ExperienceLevelResponse = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const formdata = new FormData();
  formdata.append("account[experience_level]", level);
    const header = {
      "token": await storage.get("authToken")
    };
    const accId =  await storage.get('accountId')
    this.ExperienceLevelAPIid = ExperienceLevelResponse.messageId;
    ExperienceLevelResponse.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `account_block/freelancers/${accId}/freelancer_profile_setting_experience_level`
    );
    ExperienceLevelResponse.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    ExperienceLevelResponse.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    )
    ExperienceLevelResponse.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateUserMethodeType
    );
    runEngine.sendMessage(ExperienceLevelResponse.id, ExperienceLevelResponse);
  }
  updatePreferences=async (preference:any) => {
    const PreferencesResponse = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    let pref=""
    if(preference==="shortTerm"){
     pref="short_term_project"
    }else if(preference==="longTerm"){
      pref="long_term_project"
    }else if(preference==="both"){
      pref="both_short_term_and_long_term"}

      
    const formdata = new FormData();
  formdata.append("account[project_preferences]", pref);
    const header = {
      "token": await storage.get("authToken")
    };
    const accId =  await storage.get('accountId')
    this.PreferencesAPIid = PreferencesResponse.messageId;
    PreferencesResponse.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `account_block/freelancers/${accId}/freelancer_profile_setting_project_preferences`
    );
    PreferencesResponse.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    PreferencesResponse.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    )
    PreferencesResponse.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateUserMethodeType
    );
    runEngine.sendMessage(PreferencesResponse.id, PreferencesResponse);
  }
  
  // Customizable Area End
}
