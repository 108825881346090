import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {setStorageData,getStorageData} from "../../../framework/src/Utilities"
import storage from "framework/src/StorageProvider";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  authToken: string;
  jobId:number;
  jobDetails:any;
  jobSavePopup: boolean;
  favouritesJobIds:any[]
  activityData:{label:string,value:string}[]
  clientData:{label:string,value:string}[]

  
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class SendProposalsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fetchCurrentProposalsJobDetailsId: string
  showFavouritesId: string;
  saveforLaterJobsId: string

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    console.disableYellowBox = true;
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.fetchCurrentProposalsJobDetailsId="";
    this.showFavouritesId="";
    this.saveforLaterJobsId="";
    this.state = {
      authToken: "",
      jobId: 0,
      jobDetails:{},
      favouritesJobIds:[],
      jobSavePopup: false,
       activityData:[
        { label: 'Proposals', value: 'proposals' },
        { label: 'Last viewed by client', value: 'lastViewedByClient' },
        { label: 'Interviewing', value: 'interviewing' },
        { label: 'Invites send', value: 'invitesSend' },
        { label: 'Unanswered Invites', value: 'unansweredInvites' }
      ],
      
     clientData : [
        { label: 'Jobs Posted', value: 'jobsPosted' },
        { label: 'Total Client', value: 'totalClient' },
        { label: 'Avg hourly rate paid', value: 'avgHourlyRate' },
        { label: 'Invites send', value: 'invitesSend' },
        { label: 'Unanswered Invites', value: 'unansweredInvites' }
      ]
    };
   
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    this.setState({
      authToken: await storage.get('authToken')   
    });
    this.setState({jobId:await storage.get('currentProposalJobId')});
    this.fetchCurrentProposalsJobDetails()
    this.fetchShowFavourites();
  
    // Customizable Area End
  }

  // Customizable Area Start
  handleApply = () =>{
    this.props.navigation.navigate("SubmitProposals")
  }
 
  

  // Web Event Handling
  fetchCurrentProposalsJobDetails = () => {
    
    const fetchCurrentProposalsJobDetails = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      'Content-Type': configJSON.dashboarContentType,
      "token": this.state.authToken    
    };
    
    this.fetchCurrentProposalsJobDetailsId = fetchCurrentProposalsJobDetails.messageId;
    fetchCurrentProposalsJobDetails.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchCurrentProposalsJobDetailsEndPoint+`${this.state.jobId}`
    );
    fetchCurrentProposalsJobDetails.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    fetchCurrentProposalsJobDetails.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(fetchCurrentProposalsJobDetails.id, fetchCurrentProposalsJobDetails);
  };

  fetchShowFavourites = () => {
    
    const showFavouritesRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      'Content-Type': configJSON.dashboarContentType,
      "token": this.state.authToken
     
    };
    
  
    this.showFavouritesId = showFavouritesRequest.messageId;
    showFavouritesRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.showFavouritesRequestEndPoint 
    );
    showFavouritesRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    showFavouritesRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
  
    runEngine.sendMessage(showFavouritesRequest.id, showFavouritesRequest);
  };

  handleSaveforLaterJobs = (jobId:string|number) => {
    // this.handleSavePopup()
    const saveforLaterJobs = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type":  configJSON.dashboarContentType,
    };
    const body = {
      "favourites": {
         "favourable_type":"BxBlockPosts::Post",
          "favourable_id":jobId
      },
            "token": this.state.authToken
    }
    this.saveforLaterJobsId = saveforLaterJobs.messageId;
    saveforLaterJobs.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveLaterEndPoint
    );
    saveforLaterJobs.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    saveforLaterJobs.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    saveforLaterJobs.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addMilestoneMethodeType
    );
    runEngine.sendMessage(saveforLaterJobs.id, saveforLaterJobs);
  }
  
  handleShowFavouritesRes = (message: Message,requestCallId: string) => {
    if (this.showFavouritesId === requestCallId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({ favouritesJobIds: responseJson.data.map((item: { attributes: { favourable_id: any; }; })=>item.attributes.favourable_id) });
      } 
    }
  }

  handleSaveForLaterRes = (message: Message,requestCallId: string) => {
    if(this.saveforLaterJobsId == requestCallId){
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson) {
        this.setState(prevState => ({
          favouritesJobIds: [...prevState.favouritesJobIds, responseJson.favourable_id]
        }));
        
      }

    }
  }

  handleSavePopup = () => {
    this.setState({jobSavePopup: !this.state.jobSavePopup})
  }

  handleDownload = async () => {
    const fileUrl = this.state.jobDetails.file?.url;
    const fileType = this.state.jobDetails?.file_type; 
  
    if (!fileUrl) {
      console.error("File URL is not available.");
      return;
    }
  
    try {
      let extension = "file"; 
  
      if (fileType) {
        if (fileType.includes("pdf")) {
          extension = "pdf";
        } else if (fileType.includes("msword")) {
          extension = "doc";
        } else if (fileType.includes("vnd.openxmlformats-officedocument.wordprocessingml.document")) {
          extension = "docx";
        } else if (fileType.includes("text") || fileType.includes("plain")) {
          extension = "txt";
        } else if (fileType.includes("image")) {
          extension = fileType.split("/")[1]; 
        }
      }
  
      // Fetch the file
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error(`Failed to fetch the file. Status: ${response.status}`);
      }
  
      // Download the file
      const blob = await response.blob();
      const link = document.createElement("a");
      const objectUrl = URL.createObjectURL(blob);
      link.href = objectUrl;
      link.download = `document.${extension}`; 
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objectUrl);
    } catch (error) {
      console.error("Error during file download:", error);
    }
  };
   
  // Customizable Area End

  // Customizable Area Start
  async receive(form:string,message:Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (this.fetchCurrentProposalsJobDetailsId === requestCallId) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (responseJson.data) {
          this.setState({ jobDetails: responseJson.data.attributes });
          
        } else {
          const errorMessage = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.parseApiCatchErrorResponse(errorMessage);
        }
      }
      this.handleShowFavouritesRes(message,requestCallId)
      this.handleSaveForLaterRes(message,requestCallId)
    }
    
    // Customizable Area End
  }
  // Customizable Area End
}
