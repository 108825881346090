Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = 'application/json';
exports.validationApiMethodType = 'GET';
exports.exampleAPiEndPoint = 'EXAMPLE_URL';
exports.exampleAPiMethod = 'POST';
exports.exampleApiContentType = 'application/json';
exports.textInputPlaceHolder = 'Enter Text';
exports.labelTitleText = 'LandingPage';
exports.labelBodyText = 'LandingPage Body';

exports.btnExampleTitle = 'CLICK ME';
exports.invento = 'INVENTO HUB';
exports.createPost = 'CREATE NEW POST';
exports.reusePost = 'REUSE PREVIOUS POST';
exports.shortTerm = 'Short Term';
exports.work = 'WORK';
exports.lessThan = '(Less than 6 months)';
exports.longTerm = 'Long Term';
exports.moreThan = '(More than 6 months)';
exports.getStarted = 'Getting Started !!';
exports.continue = 'Continue';
exports.tellUs = 'Tell us about your budget.';
exports.helpUs = 'This will help us match you to talent within your range';
exports.perDayRate = 'Per Day Charge:';
exports.projectBudget = 'Per Project:';
exports.lorem =
  'Lorem Impsum is simply dummy text of the printing and typesetting industry';
exports.back = 'BACK';
exports.next = 'Next Description';
exports.hour = '/project';
exports.day = '/day';
exports.errorMessage = 'This is a average rate of similar projects.';
exports.next = 'NEXT DESCRIPTION';
exports.inventoHub = 'INVENTOHUB';
exports.home = 'Home';
exports.howToHire = 'How To Hire';
exports.howToFindWork = 'How To Find Work';
exports.about = 'About Us';
exports.login = 'Log in';
exports.signUp = 'Sign Up';
exports.myJob = 'My Jobs';
exports.myJob = 'My Jobs';
exports.allJobPost = 'All Job Posts';
exports.postJob = 'Post a Job';
exports.yourHire = 'Your Hire';
exports.savedTalent = 'Saved Talent';
exports.transaction = 'Transaction History';
exports.unlock = 'Unlock Your Potential:';
exports.joinInventoHub = 'Join InventoHub - Empowering Inventors,';
exports.cnnectingIp = 'Connecting IP Professionals!';
exports.whereInnovation = '"Where Innovation Meets Expertise - Ignite Your';
exports.career = 'Career at InventoHub!"';
exports.searchPlaceholder = 'Search';
exports.popular = 'Popular:';
exports.inventoFacility = 'Unleashing Innovation: How InventoHub Facilitates';
exports.inventoreandIp = 'Inventors and IP Professionals!';
exports.unitCollaborate =
  'Unite, Collaborate, Innovate - Your Gateway to Intellectual Property Success!';
exports.exploreCategory = 'Explore Our Trending Categories: Where Innovative';
exports.ideasMeet = 'Ideas Meet Expertise on InventoHub!';
exports.categoryDetails =
  'From Tech Marvels to Creative Wonders - Find Your Niche Among the Trending Categories at InventoHub!';
exports.unlockIp =
  'Unlock Your IP Potential: Embrace Expert Solutions for Your Toughest Challenges';
exports.openToOpportunity =
  'You can show clients that you’re open to opportunites that start with a contract and may turn into a full-time job later.';
exports.startHere = 'Start Now';
exports.learnMore = 'Learn More';
exports.forClients = 'For clients';
exports.cityWiseProfessional =
  'Find your IP Professional’s with city wise and country wise';
exports.forFreelancer = 'For freelancers';
exports.unlockingInnovation =
  "Unlocking Innovation: Empowering NGOs and Governments with Invento Hub's IP Marketplace!";
exports.ipProfessional = 'IP Professionals';
exports.videoScreen = 'Video Section for IP Professionals';
exports.videoDescription =
  'Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus montes, nascetur ridiculus mus.Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus montes, nascetur ridiculus mus.Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus montes, nascetur ridiculus mus.';
exports.earnMoney = 'Do you want to earn more money on top Invento Hub?';
exports.attendMeeting =
  'Attend these meeting on how to earn more money in less time in guidance of the experts, they will guide you perfectly on how to make more moeney in less time.';
exports.feedbackHeading = 'Feedback’s from our Invento Family';
exports.feedbackDescription =
  'Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus montes, nascetur ridiculus mus.';
exports.emailPlaceholder = 'Enter your email ID';
exports.joinInvento = 'Join on INVENTO HUB';
exports.emailDetails =
  'Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh';
exports.subscribe = 'Subscribe';
exports.getSkillEndPoint = 'account_block/accounts/get_skills';
exports.clearFilter = 'Clear Filter';
exports.results = 'Results for “IP Lawyer”';
exports.available = 'Projects available';
exports.ipTalent = 'IP Talent';
exports.ipJobs = 'IP Jobs';
exports.chooseCategoryResponseEndPoint="bx_block_categories/categories"
exports.personal = "Personal Details";
exports.profile = "My Profile";
exports.profileSetting = "Profile Settings"
exports.pswSecurity = "Password & Security";
exports.details = "Details";
exports.edit = "Edit";
exports.experience = "Experience";
exports.userId = "User Id:";
exports.title = "Title";
exports.editDetails = "Edit Details";
exports.workHistory = "Work History";
exports.skills = "Skills";
exports.googleInc = "Google Inc.";
exports.price = "Price(per day)";
exports.save = "Save";
exports.contactInfo = "Contact Info";
exports.bills = "Bills & Payments";
exports.clientProfile = "Profile";
exports.aboutMe = "About me";
exports.name = "Name:";
exports.email = "Email:";
exports.address = "Address:";
exports.phone = "Phone:";
exports.country = "Country";
exports.search = "Search";
exports.clearFilter = "Clear Filter";
exports.day = "/day";
exports.earned = "earned";
exports.jobSuccess = "% Job Success";
exports.websiteDevelop = "Website Development Languages";
exports.websiteSkills = "Website Development Skills";
exports.fourMore = "4 more";
exports.saveLater = "Save for later";
exports.removeFromSave = "Saved";
exports.inviteToJob = "Invite to job";
exports.filterBy = "Filter By";
exports.clearAll = "Clear All";
exports.experienceLevel = "Experience Level";
exports.noOfProposals = "Number Of Proposals";
exports.clearInfo = "Clear Info";
exports.hourlyRate = "Hourly Rate";
exports.hourlyBilled = "Hourly Billed";
exports.englishLevel = "English Level";
exports.otherLanguage = "Other Language";
exports.validationApiContentType = "application/json";
exports.apiEndPointGetUserData = "account_block/accounts/";
exports.remainingEndPoint = "/show_profile"
exports.getUserDetailsMethodeType = "GET";
exports.accountSwitch = "Switch Account";
exports.apiEndPointUpdateUser = "account_block/accounts/";
exports.updateUserMethodeType = "PATCH";
exports.successMessage = "User details Updated Successfully";
exports.failedMessage = "Failed to Update User details";
exports.okBtnText = "Ok";
exports.onlineStatus = "ONLINE";
exports.offlineStatus = "OFFLINE";
exports.getSearchResult = "account_block/freelancers?query=";
exports.availableStatus = "AVAILABLE NOW";
exports.notAvailable = "NOT AVAILABLE NOW";
exports.getFilteredEndPoint = "account_block/freelancers";
exports.to = "to";
exports.proposal ="Proposals";
exports.noProfile = "No Profiles to show";
exports.personalDetails = "Personal Details";
exports.profileSettings = "Profile Settings";
exports.pswdSecur = "Password & Security";
exports.awards = "Awards";
exports.articles = "Articles";
exports.successStory = "Success Stories";
exports.addExperience = "Add Experience";
exports.companyLabel = "Company:";
exports.jobTitleLabel = "Job title:";
exports.locationLabel = "Loaction:";
exports.startDateLabel = "Start Date:";
exports.endDateLabel = "End Date:";
exports.descriptionLabel = "Description(Optional:)";
exports.companyPlaceholder = "Enter Company Name";
exports.titlePlaceholder = "Enter title";
exports.jobTitlePlaceholder = "Enter Job Title";
exports.locationPlaceholder = "Enter Location";
exports.projectPreference = "Project Preference:";
exports.projectTypeDetails = "What kind of projects do you prefer to take on, long term(3 months+), short term(Less than 3 months), or both?";
exports.longTerm = "LONGER TERM"
exports.shortTerm = "SHORT TERM"
exports.bothTerm = "Both short term and long term";
exports.oldPasswordLable = "Enter Old Password";
exports.newPasswordLable = "Enter New Password";
exports.confirmPasswordLable = "Confirm New Password";
exports.passSecurityHead = "Password & Security";
exports.seemsGood = "Seems good, we suggest to make it stronger";
exports.uppercase = "Contain atleast one uppercase letter";
exports.numberCase = "Contain atleast one number";
exports.specialCharCase = "Contain atleast one special character";
exports.required = "This field is required";
exports.saveLaterEndPoint = "bx_block_favourite/favourites";
exports.bankDetails = "Bank Details";
exports.account = "Account No:";
exports.panNumber = "PAN No:";
exports.IfcsCode = "IFSC Code:";
exports.editContact = "Edit Contact Info";
exports.editBank = "Edit Bank Details";
exports.accountNoPlaceholder = "Enter Account No.";
exports.panNumPlaceholder = "Enter PAN No.";
exports.IFSCPlaceholder = "Enter IFSC Code";
exports.invalidEmail ="Enter Valid email Id";
exports.emailPlaceholder = "Enter email address";
exports.phoneNumPlaceholder = "Enter Phone number";
exports.usernamePlaceholder = "Enter User name";
exports.addressPlaceholder = "Enter address";
exports.titleErrorMessage = "Title should contain only letters and have maximum of 100 characters"
exports.previousClientsEndPoint="bx_block_contract/terms/previous_client"
exports.priceErrorMessage = "Price should contain only number and have maximum of 6 digits";
exports.titleDetailsErrorMessage = "Title Details should contain only letters and have maximum of 500 characters";
exports.workHistoryErrorMessage = "Work History should contain only letters have maximum of 1000 characters";
exports.nameFailed="Please Enter Valid Name";
exports.phoneReg=/^\d{10,15}$/;
exports.nameReg=/^(?! )(?=.*[a-zA-Z\s']).{2,}(?<! )$/
exports.freelancerProfileImageEndPoint="account_block/accounts"
exports.httpPatchMethod = "PATCH";
exports.httpDeleteMethod = "DELETE";
exports.nameReg=/^(?! )(?=.*[a-zA-Z\s']).{2,}(?<! )$/;
exports.addressRegs= /^\S(?:.*\S)?$/;
// Customizable Area End
