import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import storage from "../../../framework/src/StorageProvider";
import { getStorageData } from "framework/src/Utilities";
interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

interface ValidResponseType

{
  message: object;
  data: object;
}

interface InvalidResponseType
 {
  errors: Array<string>;
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  isClient?: boolean;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  showOldPassword: boolean;
  oldPassword: string;
  showNewPassword: boolean;
  newPassword: string;
  showConfirmPassword: boolean;
  confirmPassword: string;
  confirmValidation: boolean;
  errorMessage: string;
  upperCaseCheck:boolean;
  numberCheck:boolean;
  specialCharCheck:boolean;
  requiredError:boolean;
  passwordErr: string;
  oldPasswordError:string;
  currentPassword:string;
  alertSamePopUp:boolean;
  alertSuccessPopUp:boolean;
  toggleEmail:boolean |null;
  openPasswordModal: boolean;
  isDialogOpen:boolean;
  confirmNewPassword:string;
  authToken:string;
  apiRasStatus:boolean;
  freelancerPasswordUpdate:string
  currentPasswordError: string,
  newPasswordError: string,
  confirmPasswordError: string,
  isTyping: boolean,
  toggleEmailClient:boolean,
  toggleEmailFreelancer:boolean,
  accountID:any,
  clientPasswordUpdate:any
    // Customizable Area End
}
interface SS { }

export default class FreelancerPasswordSettingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  forgatePasswordAPICallID:string='';
  upDatePasswordID: string="";
  initiateTwoFactorAuthID:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.forgatePasswordAPICallID='';
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];

    this.state = {
      authToken:"",  
      showOldPassword:false,
      oldPassword:'',
      showNewPassword: false,
      newPassword:'',
      showConfirmPassword: false,
      confirmPassword:'',
      confirmValidation:false,
      errorMessage:'',
      upperCaseCheck:false,
      numberCheck:false,
      specialCharCheck:false,
      requiredError:false,
      passwordErr:"",
      oldPasswordError:"",
      currentPassword:"",
      alertSamePopUp:false,
      alertSuccessPopUp:false,
      toggleEmail:null,
      openPasswordModal: false,
      isDialogOpen:false,
      confirmNewPassword: '',
      apiRasStatus:false,
      freelancerPasswordUpdate:"",
      currentPasswordError: '',
      newPasswordError: '',
      confirmPasswordError: '',
      isTyping: false,
      toggleEmailClient:false,
      toggleEmailFreelancer:false,
      accountID:"",
      clientPasswordUpdate:""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    const profileInfo = await storage.get('freelancerProfileInfo');
    if (profileInfo) {
      const parsedProfileInfo = JSON.parse(profileInfo);
      const apiResupdate = parsedProfileInfo?.attributes?.last_password_updated_at;
      
      this.setState({ freelancerPasswordUpdate:apiResupdate });
    }
    this.setState({
      authToken: await storage.get('authToken'),
      toggleEmailClient: await storage.get("isTwofactorEnableClient") === "true",
      toggleEmailFreelancer:await storage.get("isTwofactorEnableFreelancer") === "true",
      accountID: await getStorageData("accountId"),
      clientPasswordUpdate:await getStorageData("clientLastPasswordUpdatedAt")
    })
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start

    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
 
    runEngine.debugLog('Message Recived', message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
     if (apiRequestCallId === this.upDatePasswordID) {
        this.validation(responseJson)
        this.setState({
        apiRasStatus:responseJson.status,
        freelancerPasswordUpdate:responseJson.last_password_updated_at
        })
        if(responseJson.status){
          this.handleClosePasswordModal();
          this.handleOpenDialog()
        }
      }
    
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleChange = async (event: { target: { checked: any; }; }) => {
    const isChecked = event.target.checked;

    if (this.props.isClient) {
      this.setState({ toggleEmailClient: isChecked }, () => {
        this.initiateTwoFactorAuth("client");
      });
    } else {
      this.setState({ toggleEmailFreelancer: isChecked }, () => {
        this.initiateTwoFactorAuth("freelancer");
      });
    }
  };
  
  handleOpenPasswordModal = () => {
    this.setState({ openPasswordModal: true });
  };

  handleClosePasswordModal = () => {
    this.setState({ openPasswordModal: false });
  };

  handleOpenDialog = () => {
    this.setState({ isDialogOpen: true, openPasswordModal: false  });
    setTimeout(() => {
      this.setState({ isDialogOpen: false},()=>this.handleLogout("EmailAccountLoginBlock"));
    }, 3000);
  };
  handleLogout=(componentName: any)=>{
    storage.remove("authToken");
    storage.remove("accountId");
    storage.remove("accountHolderName");
    storage.remove("userType")
    this.props.navigation.navigate(componentName)
    console.log("logout")
  }

  handleCurrentPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentPassword = event.target.value.trim();
    const { newPassword, confirmNewPassword } = this.state;
  
    let newPasswordError = '';
    let confirmPasswordError = '';
    let currentPasswordError = '';
  
    if (!currentPassword) {
      currentPasswordError = "Current password is required";
    }
  
    if (newPassword && newPassword === currentPassword) {
      newPasswordError = "New password cannot be the same as the current password";
    }
  
    if (confirmNewPassword && confirmNewPassword !== newPassword) {
      confirmPasswordError = "Passwords do not match";
    }
  
    this.setState({
      currentPassword,
      currentPasswordError,
      newPasswordError,
      confirmPasswordError
    });
  };
  
  
  

  handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value.trim();
    const { currentPassword, confirmNewPassword } = this.state;
  
    let newPasswordError = '';
    let confirmPasswordError = '';
    let currentPasswordError = '';
  
    if (!currentPassword || currentPassword.trim() === "") {
      currentPasswordError = "Current password is required";
    }
  
    if (newPassword && newPassword === currentPassword) {
      newPasswordError = "New password cannot be the same as the current password";
    } else if (!newPassword) {
      newPasswordError = "New password is required";
    } else if (newPassword.length < 8) {
      newPasswordError = "Password must be at least 8 characters long";
    } else if (newPassword.length > 12) {
      newPasswordError = "Password must not exceed 12 characters";
    } else if (!/[A-Z]/.test(newPassword)) {
      newPasswordError = "Password must contain at least one uppercase letter";
    } else if (!/\d/.test(newPassword)) {
      newPasswordError = "Password must contain at least one number";
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(newPassword)) {
      newPasswordError = "Password must contain at least one special character";
    }
  
    if (confirmNewPassword && confirmNewPassword !== newPassword) {
      confirmPasswordError = "Passwords do not match";
    }
  
    this.setState({
      newPassword,
      newPasswordError,
      currentPasswordError,
      confirmPasswordError
    });
  };
  
  

  handleConfirmNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const confirmNewPassword = event.target.value.trim();
    const { currentPassword, newPassword } = this.state;
  
    let confirmPasswordError = '';
    let currentPasswordError = '';
    let newPasswordError = '';
  
    if (!currentPassword || currentPassword.trim() === "") {
      currentPasswordError = "Current password is required";
    }
  
    if (!newPassword || newPassword.trim() === "") {
      newPasswordError = "New password is required";
    } else if (newPassword.length < 8) {
      newPasswordError = "Password must be at least 8 characters long";
    } else if (newPassword.length > 12) {
      newPasswordError = "Password must not exceed 12 characters";
    } else if (!/[A-Z]/.test(newPassword)) {
      newPasswordError = "Password must contain at least one uppercase letter";
    } else if (!/\d/.test(newPassword)) { // Using `\d` instead of `[0-9]` for code smell fix
      newPasswordError = "Password must contain at least one number";
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(newPassword)) {
      newPasswordError = "Password must contain at least one special character";
    }
  
    if (!confirmNewPassword) {
      confirmPasswordError = "Confirm password is required";
    } else if (confirmNewPassword !== newPassword) {
      confirmPasswordError = "Passwords do not match";
    }
  
    if (newPassword && newPassword === currentPassword) {
      newPasswordError = "New password cannot be the same as the current password";
    }
  
    this.setState({
      confirmNewPassword,
      confirmPasswordError,
      currentPasswordError,
      newPasswordError
    });
  };
  

  handleSubmit = () => {
    const { currentPassword, newPassword, confirmNewPassword } = this.state;
    let hasError = false;

    if (currentPassword.trim() === "") {
      this.setState({ currentPasswordError: "Current password is required" });
      hasError = true;
    } else {
      this.setState({ currentPasswordError: "" });
    }

    if (newPassword.trim() === "") {
      this.setState({ newPasswordError: "New password is required" });
      hasError = true;
    }

    if (confirmNewPassword.trim() === "") {
      this.setState({ confirmPasswordError: "Please confirm your new password" });
      hasError = true;
    }

    if (!hasError) {
      this.handlePasswordChnagesApi();
    }
  };

  isSaveDisabled = () => {
    const {
      currentPasswordError,
      newPasswordError,
      confirmPasswordError,
      currentPassword,
      newPassword,
      confirmNewPassword,
    } = this.state;

    return (
      !!currentPasswordError ||
      !!newPasswordError ||
      !!confirmPasswordError ||
      !currentPassword.trim() ||
      !newPassword.trim() ||
      !confirmNewPassword.trim()
    );
  };
  validation(response: { status: boolean; error?: string; }) {
    if (!response.status) {
        const errorMessage = response.error || "An unknown error occurred.";
        if (errorMessage === "Old password is not correct.") {
            this.setState({ currentPasswordError: errorMessage });
        } else if (errorMessage === "New and confirm password do not match.") {
            this.setState({ confirmPasswordError: errorMessage });
        } else {
            this.setState({ errorMessage: errorMessage });
        }
    } else {
        console.log("Password changed successfully");
        this.handleClosePasswordModal();
    }
}
handleFocus=()=> {
  this.setState({ isTyping: true });
}

handleBlur=()=> {
  this.setState({ isTyping: false });
}

  handlePasswordChnagesApi = () => {
    const endPoint= this.props.isClient? `/account_block/accounts/update_password`:`account_block/freelancers/update_password_freelencer`
    const header = {
      "Content-Type": "application/json",
      "token": this.state.authToken
    };
    let body ={
      "old_password": this.state.currentPassword,
      "new_password": this.state.newPassword,
      "password_confirmation": this.state.confirmNewPassword,
  };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.upDatePasswordID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${endPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body) 
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  };

  initiateTwoFactorAuth = async (userType: string) => {
    const { accountID, authToken, toggleEmailClient, toggleEmailFreelancer } =
      this.state;

    const isTwofactorEnabled =
      userType === "client" ? toggleEmailClient : toggleEmailFreelancer;

    const endpoint =
      userType === "client"
        ? `${configJSON.twoFactorAuthEndPointClient}${accountID}/update_client_twofactor_enable?[account][is_twofactor_enable]=${isTwofactorEnabled}`
        : `${configJSON.twoFactorAuthEndPoint}${accountID}/update_freelancer_twofactor_enable?[account][is_twofactor_enable]=${isTwofactorEnabled}`;

    const header = {
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.initiateTwoFactorAuthID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    if (userType === "client") {
      storage.set("isTwofactorEnableClient", isTwofactorEnabled);
    } else {
      storage.set("isTwofactorEnableFreelancer", isTwofactorEnabled);
    }

    return true;
  };
  // Customizable Area End
}
