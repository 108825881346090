import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { eliipse,dots } from "./assets";

export const config = require("./config");
const CustomButton = styled(Button)({
  marginTop:"40px",
  width: "max-content",
  padding: "1% 10%",
  borderRadius: "10px",
  color: "#FFFFFF",
  minWidth: 290,
  fontFamily: "Rubik",
  background: "#364BA0",
  fontSize: "20px",
  cursor: "pointer",
  fontWeight: 500,
  '&:hover': {
    width: "max-content",
    borderRadius: "10px",
    color:"#364BA0",
    border:"1px solid #364BA0",
    backgroundColor: "#fff",
  }
})
import { checkIcon } from "../../email-account-login/src/assets";
// Customizable Area End

import CreatePostController, { Props } from "./CreatePostController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#206FC4"
    }
  },
  typography: {
    fontFamily: "Inter"
  }
});

export default class CreatePost extends CreatePostController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Grid container spacing={2} style={styles.gridContainer}>
          <Grid item xs={12} sm={12} md={12}>
            <Box style={styles.postBox}>
              <Button
                variant="contained"
                style={styles.createPostBtn}
              >
                {config.createPost}
              </Button>
              <Typography
                variant="subtitle2"
                component="button"
                style={styles.reuseBtn}
              >
                {config.reusePost}
              </Typography>
            </Box>
            <Typography style={styles.getStarted}>
              {config.getStarted}
            </Typography>
            <Box style={styles.selectBox}>
              <Grid container justifyContent="center">
                <Grid item>
                  <Card
                    component="button"
                    data-testid="selectShortTerm"
                    onClick={() => {
                      this.handleSelectTerm("short");
                      this.props.getEmploymentTermSet("short_term_work");
                    }}
                    variant="outlined"
                    style={{
                      ...styles.cardContainer,
                      background:
                        this.props.valueTerm === "short_term_work" ? "#253342" : "#fff"
                    }}
                  >
                    <CardContent>
                      {this.props.valueTerm === "short_term_work" && (
                        <img src={checkIcon} alt="check" style={styles.radioInput} data-testid="client-checkbox"/> 
                      )}
                      <Typography
                        style={
                          this.props.valueTerm === "short_term_work"
                            ? styles.afterSelectText
                            : styles.beforeSelectText
                        }
                      >
                        {config.shortTerm}
                      </Typography>
                      <Typography
                        style={
                         this.props.valueTerm=== "short_term_work"
                            ? styles.afterSelectText
                            : styles.beforeSelectText
                        }
                      >
                        {config.work}
                      </Typography>
                      <Typography
                        style={
                         this.props.valueTerm=== "short_term_work"
                            ? styles.afterSelect
                            : styles.beforeSelect
                        }
                      >
                        {config.lessThan}
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card
                    component="button"
                    data-testid="selectLongTerm"
                    onClick={() => {
                      this.props.getEmploymentTermSet("longer_term_work");
                      this.handleSelectTerm("long");
                    }}
                    variant="outlined"
                    style={{
                      ...styles.cardContainer,
                      background:
                        this.props.valueTerm!== "short_term_work" ? "#253342" : "#fff"
                    }}
                  >
                    <CardContent>
                      {this.props.valueTerm!== "short_term_work" && (
                        <img src={checkIcon} alt="check" style={styles.radioInput} data-testid="client-checkbox"/> 
                      )}

                      <Typography
                        style={
                          this.props.valueTerm!== "short_term_work"
                            ? styles.afterSelectText
                            : styles.beforeSelectText
                        }
                      >
                        {config.longTerm}
                      </Typography>
                      <Typography
                        style={
                          this.props.valueTerm!== "short_term_work"
                            ? styles.afterSelectText
                            : styles.beforeSelectText
                        }
                      >
                        {config.work}
                      </Typography>
                      <Typography
                        style={
                          this.props.valueTerm!== "short_term_work"
                            ? styles.afterSelect
                            : styles.beforeSelect
                        }
                      >
                        {config.moreThan}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
            <CustomButton
              data-testid="continueBtn"
              onClick={() => this.handleContinue()}
            >
              {config.continue}
            </CustomButton>
          </Grid>
        </Grid>
        <img src={dots} alt="dots" style={styles.dotImg} />
        <img src={eliipse} alt="ellipse" style={styles.ellipseImg} />
      </ThemeProvider>
      // Customizable Area End
    );
  }
 
}

// Customizable Area Start
const styles = {
  invento: {
    padding: "20px 0 0 30px",
    fontSize: "2rem",
    "font-weight": "500"
  },
  button: {
    width: "max-content",
    padding: "1% 10%",
    borderRadius: "10px",
    marginTop: "2%",
    color: "#FFFFFF",
    minWidth: 290,
    fontFamily: "'Inter', sans-serif",
    background: "#206FC4",
    fontSize: "16px",
    cursor: "pointer",
    "font-weight": "600",
    fontWeight: 700,
  },
  radioInput: {
    position: "absolute" as "absolute",
    right: "16",
    top: "18",
    color: "#33A1E5",
    width:"30px",
    height:"30px"
  },
  gridContainer: {
    display: "block",
    textAlign: "center" as "center",
    margin: "auto",
    width: "auto"
  },

  selectBox: {
    textAlign: "center" as "center"
  },
  createPostBtn: {
    background:"#364BA0",
    borderRadius: "28px",
    textTransform: "capitalize" as "capitalize",
    fontSize: "16px",
    fontFamily:"Rubik",
    padding: "1% 4%",
    color:"#ffffff"
  },
  postBox: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    alignItems: "center",
   
  },
  getStarted: {
    fontSize:"28px",
    fontFamily:"Rubik",
    marginTop:"28px",
    marginBottom:"28px"
  },
  cardContainer: {
    marginTop: "28px",
    margin:"24px",
    padding: "59px 59.5px 60px 48.5px",
    position: "relative" as "relative",
    borderRadius: "10px",
    cursor: "pointer"
  },
  afterSelect: {
    marginTop:"13px",
    color: "#fff",
    fontSize: "18px",
    fontFamily:"Rubik"
  },
  afterSelectText: {
    color: "#fff",
    fontSize: "28px",
    fontFamily:"Rubik",
    fontWeight: 500
  },
  beforeSelect: {
    marginTop:"13px",
    color: "#000",
    fontSize: "18px",
    fontFamily:"Rubik"
  },
  beforeSelectText:{
    color: "#000",
    fontSize: "28px",
    fontFamily:"Rubik",
    fontWeight:500
  },
  reuseBtn: {
    border: "none",
    background: "none",
    fontSize: "16px",
    fontFamily:"Rubik"
  },
  dotImg: {
    bottom: "0",
    right: "0",
    position: "absolute" as "absolute",
    width: "110px",
    zIndex:-1
  },
  ellipseImg: {
    position: "absolute" as "absolute",
    left: "0",
    bottom: "0",
    width: "240px",
    zIndex:-1
  }
};
// Customizable Area End
