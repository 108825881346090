Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "bx_block_profile/profile/current_user_profile";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.getCustomizableProfileFieldsEndPoint =
  "bx_block_profile/profile/custom_user_profile_fields";
exports.personal = "Personal Details";
exports.profile = "My Profile";
exports.profileSetting = "Profile Settings"
exports.pswSecurity = "Password & Security";
exports.details = "Details";
exports.edit = "Edit";
exports.ipLawyer = "IP Lawyer";
exports.experience = "Experience";
exports.userId = "User Id:";
exports.title = "Title";
exports.editDetails = "Edit Details";
exports.workHistory = "Work History";
exports.skills = "Skills";
exports.googleInc = "Google Inc.";
exports.price = "Price(per day)";
exports.save = "Save";
exports.contactInfo = "Contact Info";
exports.bills = "Bills & Payments";
exports.clientProfile = "Profile";
exports.aboutMe = "About me";
exports.name = "Name:";
exports.email = "Email:";
exports.address = "Address:";
exports.phone = "Phone:";
exports.country = "Country";
exports.search = "Search";
exports.clearFilter = "Clear Filter";
exports.day = "/day";
exports.earned = "earned";
exports.jobSuccess = "% Job Success";
exports.websiteDevelop = "Website Development Languages";
exports.websiteSkills = "Website Development Skills";
exports.fourMore = "4 more";
exports.saveLater = "Save for later";
exports.removeFromSave = "Saved";
exports.inviteToJob = "Invite to job";
exports.filterBy = "Filter By";
exports.clearAll = "Clear All";
exports.experienceLevel = "Experience Level";
exports.noOfProposals = "Number Of Proposals";
exports.clearInfo = "Clear Info";
exports.hourlyRate = "Hourly Rate";
exports.hourlyBilled = "Hourly Billed";
exports.englishLevel = "English Level";
exports.otherLanguage = "Other Language";
exports.validationApiContentType = "application/json";
exports.apiEndPointGetUserData = "account_block/accounts/get_update_client_profile?id=";
exports.remainingEndPoint = "/show_client_profile"
exports.getUserDetailsMethodeType = "GET";
exports.accountSwitch = "Switch Account";
exports.apiEndPointUpdateUser = "account_block/accounts/";
exports.updateUserMethodeType = "PATCH";
exports.successMessage = "User details Updated Successfully";
exports.failedMessage = "Failed to Update User details";
exports.okBtnText = "Ok";
exports.onlineStatus = "ONLINE";
exports.offlineStatus = "OFFLINE";
exports.getSearchResult = "account_block/freelancers";
exports.availableStatus = "AVAILABLE NOW";
exports.notAvailable = "NOT AVAILABLE NOW";
exports.getFilteredEndPoint = "account_block/freelancers";
exports.to = "to";
exports.proposal ="Proposals";
exports.noProfile = "No Profiles to show";
exports.personalDetails = "Personal Details";
exports.profileSettings = "Profile Settings";
exports.pswdSecur = "Password & Security";
exports.awards = "Awards";
exports.articles = "Articles";
exports.successStory = "Success Stories";
exports.addExperience = "Add Experience";
exports.companyLabel = "Company:";
exports.jobTitleLabel = "Job title:";
exports.locationLabel = "Loaction:";
exports.startDateLabel = "Start Date:";
exports.endDateLabel = "End Date:";
exports.descriptionLabel = "Description(Optional:)";
exports.articlesLabel = "Articles";
exports.successStoryLabel="Success Stories"
exports.companyPlaceholder = "Enter Company Name";
exports.titlePlaceholder = "Enter title";
exports.jobTitlePlaceholder = "Enter Job Title";
exports.locationPlaceholder = "Enter Location";
exports.projectPreference = "Project Preference:";
exports.projectTypeDetails = "What kind of projects do you prefer to take on, long term(3 months+), short term(Less than 3 months), or both?";
exports.longTerm = "Long term projects(More than 3 months)";
exports.shortTerm = "Short term projects(Less than 3 months)";
exports.bothTerm = "Both short term and long term";
exports.oldPasswordLable = "Enter Old Password";
exports.newPasswordLable = "Enter New Password";
exports.confirmPasswordLable = "Confirm New Password";
exports.passSecurityHead = "Password & Security";
exports.seemsGood = "Seems good, we suggest to make it stronger";
exports.cancelText = "CANCEL";
exports.uppercase = "Contain atleast one uppercase letter";
exports.numberCase = "Contain atleast one number";
exports.specialCharCase = "Contain atleast one special character";
exports.required = "This field is required";
exports.saveLaterEndPoint = "bx_block_favourite/favourites";
exports.inviteToJobEndPoint = "bx_block_send_message/send_messages/invite_to_job";
exports.bankDetails = "Bank Details";
exports.account = "Account No:";
exports.panNumber = "PAN No:";
exports.IfcsCode = "IFSC Code:";
exports.zipCodText = 'ZIP/Postal code:';
exports.dateofbirth = 'Date of birth:';
exports.city = 'City:';
exports.state = 'State:';
exports.jobtitle = 'Job Title:';
exports.additionalAddress = 'Additional address:';
exports.suite = "Apt/Suite:";
exports.editContact = "Edit Contact Info";
exports.editBank = "Edit Bank Details";
exports.accountNoPlaceholder = "Enter Account No.";
exports.panNumPlaceholder = "Enter PAN No.";
exports.IFSCPlaceholder = "Enter IFSC Code";
exports.invalidEmail ="Enter Valid email Id";
exports.emailPlaceholder = "Enter email address";
exports.phoneNumPlaceholder = "Enter Phone number";
exports.usernamePlaceholder = "Enter User name";
exports.addressPlaceholder = "Enter address";
exports.titleErrorMessage = "Title should contain only letters and have maximum of 100 characters"
exports.previousClientsEndPoint="bx_block_contract/terms/previous_client"
exports.priceErrorMessage = "Price should contain only number and have maximum of 6 digits";
exports.titleDetailsErrorMessage = "Title Details should contain only letters and have maximum of 500 characters";
exports.workHistoryErrorMessage = "Work History should contain only letters have maximum of 1000 characters";
exports.nameFailed="Please Enter Valid Name";
exports.phoneReg=/^\d{10,15}$/;
exports.nameReg=/^(?! )(?=.*[a-zA-Z\s']).{2,}(?<! )$/
exports.freelancerProfileImageEndPoint="account_block/accounts"
exports.httpPatchMethod = "PATCH";
exports.httpDeleteMethod = "DELETE";
exports.httpDeleteMethod = "DELETE";
exports.firstName = "First Name";
exports.lastName = "Last Name";

exports.nameReg=/^(?! )(?=.*[a-zA-Z\s']).{2,}(?<! )$/;
exports.addressRegs= /^\S(?:.*\S)?$/;
exports.skillApiContentType= "application/json";
exports.getFreelancerProfileEndPoint="freelancer_profile";
exports.skillEndPoint="account_block/work_experiences";
exports.apiEndPointupdateUserExperience="account_block/work_experiences";
exports.freelancerProfile="/freelancer_profile";
exports.updateContactDetails="/update_profile_freelancer";

exports.freelancUpdatePassword="account_block/freelancers/update_password_freelencer"
exports.updateOnlineStatus = 'account_block/accounts/get_client_profile_online_status'
exports.clientUpdatePassword="account_block/accounts/update_password"
exports.freelancerDetailsEndPoint="account_block/accounts/get_update_freelancer_profile?id="
exports.freelancerProfileImageEndPoint="account_block/accounts"
exports.notMatchPasswordCriteria = "Password does not meet the criteria"
exports.twoFactorAuthEndPoint="account_block/freelancers/"
exports.twoFactorAuthEndPointClient="account_block/accounts/"

// Customizable Area End
