// Customizable Area Start
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { facebook, insta, twitter, linkdin } from '../assets';
import { Horizontalinventohub } from '../../../landingpage/src/assets';

const styles = createStyles({
  footerSection: {
    backgroundColor: '#E6EBEF',
    padding: '0 134px 35px',
    marginTop: '5rem',
    "@media(max-width:960px),": {
      marginTop: '50px',
     
     },
    "@media(max-width:600px),": {
      padding: '0px',
     },
     "@media(max-width:320px),": {
       padding: 'inherit',
      },
  },
  footerCard: {
    padding: '3rem 0 3rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    "@media(max-width:1076px),": {
      flexWrap:"wrap",
    },
    "@media(max-width:630px),": {
     display: 'flex',
     justifyContent: 'center',
    },
  },
  footerMenu: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '20px',
    color: '#515354',
    textTransform: 'capitalize',
  },
  footerBottom: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '2rem',
    "@media(max-width:960px)": {
     justifyContent: "center",
     flexDirection: "column",
     gap:12
    },
    
  },
  footerLogoText: {
    fontFamily: 'poppins',
    fontSize: '26px',
    fontWeight: 275,
    lineHeight: '38px',
    letterSpacing: '-1.6px',
    color: '#000000',
    margin: 0,
    "@media(max-width:600px)": {
      display: 'block',
    },
    "@media(max-width:320px)": {
      textAlign:'center'
    }
  },
  footerCopyRight: {},
  copyRightText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '20px',
    color: '#515354',
    margin: 0,
    "@media(max-width:320px)": {
      textAlign:'center'
    }
  },
  socialMedia: {
    display: 'flex',
    alignItems: 'center',
    gap: '1.5rem',
    "@media(max-width:320px)": {
      justifyContent:'center'
    }
  },
  insta: {
    marginTop: "5px"
  },
  linkdin: {
    marginBottom: "7px"
  },
  invento:{
    width:"300px",
    height:"61px",
    objectFit:"cover",
  },
});


interface NewFooterProps extends WithStyles<typeof styles> { 

}
// Customizable Area End
class Footer extends Component<NewFooterProps> {
  render() {
    // Customizable Area Start
    const userType=localStorage.getItem('userType')
    const { classes } = this.props;

    let menuItems = [
      { label: 'About Us', path: '/About' },
      // { label: 'Projects', path: '/activecontractprojects' },
      { label: 'FAQs', path: '/FAQ' },
      { label: 'How to Hire', path: '/HowToHire'},
      { label: 'How to Find Work', path: '/HowToFindWork' },
      { label: 'Contact Us', path: '/contact-us' },
      { label: 'Terms & Conditions', path: '/terms-and-conditions' },
      { label: 'Privacy Policy', path: '/privacy-policy' },
      
    ];
    if(localStorage.getItem('authToken')) {
if(userType!=="customer"){
menuItems =  menuItems.filter((item) =>item.label != 'How to Hire')
} else{
  menuItems =  menuItems.filter((item) =>item.label != 'How to Find Work')
}
    }
    return (
      <div className={classes.footerSection}>
        <div className={classes.footerCard}>
          {menuItems.map(menuItem => (
            <div key={menuItem.label}>
              <Button
                className={classes.footerMenu}
                color="secondary"
                variant="text"
                onClick={()=>this.handleRedirect(menuItem.path)}
              >
                {menuItem.label}
              </Button>
            </div>
          ))}
        </div>
        <hr className="" />
        <div className={classes.footerBottom}>
          <div>
          <img src={Horizontalinventohub} className={classes.invento}/>
          </div>
          <div className={classes.footerCopyRight}>
            <p className={classes.copyRightText}>
              &#169; Savio. All Rights Reserved 2023
            </p>
          </div>
          <div className={classes.socialMedia}>
            <div>
              <img alt="Twitter" src={twitter} />
            </div>
            <div>
              <img alt="Facebook" src={facebook} />
            </div>
            <div>
              <img alt="Instagram" src={insta} className={classes.insta} />
            </div>
            <div>
              <img alt="LinkedIn" src={linkdin} className={classes.linkdin} />
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  handleRedirect = (path: any) => {
    if (path)  {
      window.location.pathname = path;
    }
  };
}

export default withStyles(styles)(Footer);
// Customizable Area End
