import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {checkIcon,unCheckIcon,dots,ellipse} from './assets'
export const config = require("./config");
import { Horizontalinventohub } from "../../landingpage/src/assets";

// Customizable Area End

import UserTypeSelectionController, {
  Props,
} from "./UserTypeSelectionController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#206FC4",
    },
    secondary:{
      main: "#4caf50",
    }
  },
  typography:{
    fontFamily:'Inter', 
  }
});

export default class UserTypeSelection extends UserTypeSelectionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start

 handleHaveAnAccount = () => {
  return(
    <Box style={styles.accountBox}>
    <Typography style={styles.signInText}>{config.haveAccount}</Typography>
    <Typography
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          this.handleredirection()
        }
      }}
     data-testid="signInRedirectBtn" onClick={this.handleredirection} style={{ ...styles.signInLink, textDecoration: 'underline' }}>{config.signIn}</Typography>
  </Box>
  )
 }

 renderCardContentClient = () =>{
  return(
    <Card 
    variant="outlined" 
    data-testid="client-selection"
    onClick={() => this.handleUserType('client')} 
    tabIndex={0}
    onKeyDown={(e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        this.handleUserType('client')
      }
    }}
    style={{...styles.cardElement, background: this.state.userRole === 'client' ? '#253342' : '#fff'}}
  >
        <CardContent>
        {this.state.userRole === 'client' ? 
          <img src={checkIcon} alt="check" style={styles.checkIcon} data-testid="client-checkbox"/> :
          <img src={unCheckIcon} alt="uncheck" style={styles.checkIcon}/>
        }
          <Typography style={{color:this.state.userRole === 'client' ? '#fff' : '#000',
            fontFamily: 'Rubik',
            fontSize: "28px",
            fontWeight: 600
          }}>{config.jionAs}</Typography>
          <Typography style={{color:this.state.userRole === 'client' ? '#fff' : '#000',
            fontFamily: 'Rubik',
            fontSize: "28px",
            fontWeight: 600
          }}>{config.client}</Typography>
        </CardContent>
    </Card>
  )
 }
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
      <>
      <ThemeProvider theme={theme}>
        <img 
           tabIndex={0}
           onKeyDown={(e) => {
             if (e.key === 'Enter' || e.key === ' ') {
               e.preventDefault();
               this.handleLogoClick()
             }
           }}
        src={Horizontalinventohub} style={styles.invento as React.CSSProperties} onClick={this.handleLogoClick} data-test-id="handleLogoClick"/>
        <Box style={styles.userTypeMainCOntainer}>
          <Grid container spacing={2} style={styles.gridContainer}>
            <Grid item xs={12} sm={12} md={12}>
          <Typography style={styles.professional}>{config.professional}</Typography>
            <Box style={styles.selectBox}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={3}>
              <Card 
               tabIndex={0}
               onKeyDown={(e) => {
                 if (e.key === 'Enter' || e.key === ' ') {
                   e.preventDefault();
                   this.handleUserType('user')
                 }
               }}
              variant="outlined" 
              data-testid="user-selection"
              onClick={ () => this.handleUserType('user')}
              style={{...styles.cardElement,background: this.state.userRole === 'user' ? '#253342' :'#fff'}}
             >
                  <CardContent>
                    {this.state.userRole === 'user' ? 
                    <img src={checkIcon} alt="check" style={styles.radioInput} data-testid="user-check"/> :
                    <img src={unCheckIcon} alt="uncheck" style={styles.radioInput}/>
                  }
                   <Typography style={{ 
                        color: this.state.userRole === 'user' ? '#fff' : '#000', 
                        fontFamily: 'Rubik',
                        fontSize: "28px",
                        fontWeight: 600
                    }}>
                        {config.jionAs}
                    </Typography>

                    <Typography style={{ 
                        color: this.state.userRole === 'user' ? '#fff' : '#000', 
                        fontFamily: 'Rubik',
                        fontSize: "28px",
                        fontWeight: 600
                    }}>
                        {config.user}
                    </Typography>

                  </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={3}>
          {this.renderCardContentClient()}
            </Grid>
          </Grid>
            </Box>
            <Button variant="contained" data-testid="continueBtn" onClick={this.handleContinue} style={styles.button}>
                Apply
            </Button>
            {this.handleHaveAnAccount()}
            </Grid>
          </Grid>
          <img src={dots} alt="dots" style={styles.dotImg}/>
          <img src={ellipse} alt="ellipse" style={styles.ellipseImg}/>
        </Box>
      </ThemeProvider>
      </>
       // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  invento:{
    width:"264px",
    height:"48px",
    objectFit:"cover",
     marginTop: "42px",
     marginLeft:"100px",
     cursor: 'pointer'
  },
  container:{
    textAlign:'center' as 'center'
  },
  card:{
    position: 'relative' as 'relative',
    borderRadius: '5px',
    background:'#253342',
    width:'100%'
  },
  
  button: {
    width: '100%', 
    maxWidth: '512px', // Ensures it doesn't exceed this width
    padding: '1% 10%',
    borderRadius: '10px',
    marginTop: '3%',
    minWidth: '290px',
    color:"#FFFFFF",
    background:'#364BA0',
    fontSize:'20px',
    fontFamily:'Rubik',
    fontWeight:600,
    display: 'block',
    marginLeft: 'auto', 
    marginRight: 'auto',
  },
  radioInput:{
    position: 'absolute' as 'absolute',
    right: '8px',
    top: '10px',
    width:'20px'
  },
  checkIcon:{
    position: 'absolute' as 'absolute',
    right: '8px',
    top: '10px',
    width:'20px'
  },
  signInText:{
    fontSize:'20px',
    fontWeight:600,
    fontFamily:'Rubik',
    color:"#000000"
  },
  gridContainer:{
    display: 'block',
    textAlign: 'center' as 'center',
    width:"auto",
    margin:"auto"
  },
  professional:{
    marginTop: '2%',
    fontSize:"28px",
    fontWeight:500,
    fontFamily:"Rubik"
  },
  selectBox:{
    marginTop: '2%'
  },
  cardElement:{
    padding:'15% 0',
    position: 'relative' as 'relative', 
    borderRadius: '5px',
    cursor:'pointer'
  },
  signInLink:{
    fontSize:'20px',
    fontFamily:'Rubik',
    fontWeight:600,
    textDecoration:'underline',
    color:'#206FC4',
    cursor:'pointer'
  },
  accountBox:{
    display:'flex',
    gap:'4px',
    justifyContent:'center',
    marginTop:'2%',
    alignItems:'center'
  },
  userTypeMainCOntainer:{
    height:'87vh',
    position:'relative' as 'relative'
  },
  dotImg:{
    position:'absolute' as 'absolute',
    bottom:'0',
    right:'0',
    width:'110px'
  },
  ellipseImg:{
    position:'absolute' as 'absolute',
    bottom:'0',
    left:'0',
    width:'240px'
  }
}
// Customizable Area End
