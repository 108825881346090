import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import storage from "framework/src/StorageProvider";
// Customizable Area Start

// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  loading: boolean;
  jobReviewData:{
    id: string,
        type: string
        attributes: {
            id: number,
            skills: any[],
            per_day_charge: string
            description: string
            name: string
            job_description: string
            job_title: string
            budget: string
            employment_term:string,
            body: string
            duration: string
            estimate: string,
            account_id: number,
            file: {
              url: null
            },
            location: string,
            updated_at: string,
            hired: number,
            intermediate: string,
            is_drafted: boolean,
            proposals: number,
            images_and_videos: any[],
            messaged: number,
            created_at: string,
            last_viewed_by_client: string,
            model_name: string,
            file_name: string;
            rating:number,
            est_budget: number,
            project_length:string,
            patents: Array<{
              id: number;
              categories: {
                id: number;
                name: string;
                sub_categories: Array<{
                  id: number;
                  name: string;
                  sub_category_menus: Array<{
                    id: number;
                    title: string;
                    sub_category_sub_menus: Array<{
                      id: number;
                      title: string;
                      description: string;
                    }>;
                  }>;
                }>;
              };
            }>;
        }
  };

  isPopupOpen:boolean;
  editedName:string;
  isDescriptionPopupOpen:boolean;
  editedDescription:string;
  isSkillsPopupOpen:boolean;
  editedSkills:any[];
  skillsOption: any[];
  skillValue: string; 
  skillLabel: any[];
  isScopePopupOpen:boolean;
  isSuccessPopupOpen: boolean;
  isSuccessDraftPopupOpen:boolean;
  editedScope: string;
  editedDuration: string;
  isBudgetPopupOpen: boolean;
  editedBudgetRate: string;
  editedBudgetAmount: string;
  category:string;
  skillsLabel:[]
  originalSkillsOption:any[];
  skillInputValue: string ;
  DraftedJobData:{
    id: string,
    type: string
    attributes: {
        id: number,
        name: string
        description: string
        body: string
        job_title: string
        job_description: string
        per_day_charge: string
        budget: string
        duration: string
        employment_term:string,
        estimate: string,
        skills: any[],
        location: string,
        file: {
            url: null
        },
        account_id: number,
        created_at: string,
        updated_at: string,
        proposals: number,
        messaged: number,
        hired: number,
        is_drafted: boolean,
        est_budget: number,
        last_viewed_by_client: string,
        rating:number,
        intermediate: string,
        model_name: string,
        images_and_videos: any[],
        file_name: string;
    }
  }
  // Customizable Area End
}
interface SS { }

export default class JobReviewPageController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  JobReviewDataId:string=""
  getJobPostDetailUpdateCallId: string = '';
  DraftedJobDataId:string=""
  DraftThisJobsId:string="";
  apiPostEditSearchDataId:string="";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        loading: false,
        jobReviewData:{
          id: "",
          type: "",
          attributes: {
              id:  0,
              description: "",
              body: "",
              name: "",
              job_description : "",
              skills: [],
              job_title : "",
              budget: 'project_budget',
              duration: "",
              estimate: "",
              employment_term:"",
              per_day_charge: "",
              created_at: "",
              file: {
                url: null
              },
              is_drafted: false,
              hired:  0,
              intermediate: "",
              messaged:  0,
              location: "",
              updated_at: "",
              images_and_videos: [],
              proposals:  0,
              last_viewed_by_client: "",
              account_id:  0,
              est_budget:  0,
              file_name: "",
              rating: 0,
              model_name: "",
              project_length:"",
              patents: [
                {
                  id: 0,
                  categories: {
                    id: 0,
                    name: "",
                    sub_categories: [
                      {
                        id: 0,
                        name: "",
                        sub_category_menus: [
                          {
                            id: 0,
                            title: "",
                            sub_category_sub_menus: [
                              {
                                id: 0,
                                title: "",
                                description: "",
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
          }
        },
        DraftedJobData:{
          id: "",
          type: "",
          attributes: {
              id:  0,
              name: "",
              description: "",
              body: "",
              job_title : "",
              job_description : "",
              per_day_charge: "",
              budget: "",
              duration: "",
              employment_term:"",
              estimate: "",
              skills: [],
              location: "",
              file: {
                  url: null
              },
              account_id:  0,
              created_at: "",
              updated_at: "",
              proposals:  0,
              messaged:  0,
              hired:  0,
              is_drafted: false,
              est_budget:  0,
              last_viewed_by_client: "",
              rating: 0,
              intermediate: "",
              model_name: "",
              images_and_videos: [],
              file_name: ""
          }
        },
        isPopupOpen:false,
        editedName:"",
        isDescriptionPopupOpen:false,
        editedDescription:"",
        isSkillsPopupOpen:false,
        editedSkills:[],
        skillsOption: [],  
        skillValue: "",  
        skillLabel: [],
        isScopePopupOpen:false,
        isSuccessPopupOpen: false,
        isSuccessDraftPopupOpen:false,
        editedScope: "",
        editedDuration: "",
        isBudgetPopupOpen: false,
        editedBudgetRate: "",
        editedBudgetAmount: "",
        category:"Category/Subcat1, Subcat2, Subcat3",
        skillsLabel:[],
        originalSkillsOption:[],
        skillInputValue: "",
    };
    
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    const jobId = await storage.get('jobId');
    this.getJobReviewData(jobId)
    this.getPostEditSearchData()
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      let webResponseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (webResponseJson && webApiRequestCallId === this.JobReviewDataId) {
        this.setState({ jobReviewData: webResponseJson.data });
      }
      if (webResponseJson && webApiRequestCallId === this.apiPostEditSearchDataId) {
        this.setState({
          skillsOption: webResponseJson.skills,
          originalSkillsOption: webResponseJson.skills,
        });
      }
      else if (webApiRequestCallId === this.DraftThisJobsId) {
        this.setState({ loading: false });
        this.props.navigation.navigate('FullWidthJobsTab');
      }
  }
    // Customizable Area End
  }
  // Customizable Area Start
  handleOpenPopup = () => {
    this.setState({ isPopupOpen: true });
  };

  handleClosePopup = () => {
    this.setState({ isPopupOpen: false });
  };

  handleTitleChange = (event: { target: { value: any; }; }) => {
    this.setState({
      jobReviewData: {
        ...this.state.jobReviewData,
        attributes: {
          ...this.state.jobReviewData.attributes,
          name: event.target.value
        }
      }
    });
  };
  
  handleSave = () => {
    this.setState((prevState) => ({
      jobReviewData: { ...prevState.jobReviewData, name: prevState.editedName },
      isPopupOpen: false,
    }));
  };
  handleOpenDescriptionPopup = () => {
    this.setState({ isDescriptionPopupOpen: true, editedDescription: this.state.jobReviewData.attributes.description });
  };

  handleCloseDescriptionPopup = () => {
    this.setState({ isDescriptionPopupOpen: false });
  };
  handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      jobReviewData: {
        ...this.state.jobReviewData,
        attributes: {
          ...this.state.jobReviewData.attributes,
          description: event.target.value
        }
      }
    });
  };
  handleSaveDescription = () => {
    this.setState((prevState) => ({
      jobReviewData: {
        ...prevState.jobReviewData,
        description: prevState.editedDescription,
      },
      isDescriptionPopupOpen: false,
    }));
  };

  handleOpenSkillsPopup = () => {
    this.setState({
      isSkillsPopupOpen: true,
      editedSkills: [...this.state.jobReviewData.attributes.skills]
    });
  };
  
  handleCloseSkillsPopup = () => {
    this.setState({ isSkillsPopupOpen: false });
  };
  
  handleSaveSkills = () => {
    this.setState((prevState) => ({
      jobReviewData: {
        ...prevState.jobReviewData,
        attributes: {
          ...prevState.jobReviewData.attributes,
          skills: prevState.editedSkills,
        },
      },
      isSkillsPopupOpen: false,
    }));
  };
  
  handleOpenScopePopup = () => {
    this.setState({
      isScopePopupOpen: true,
      editedScope: this.state.jobReviewData.attributes.estimate, 
      editedDuration: this.state.jobReviewData.attributes.project_length,
    });
  };
  
  handleCloseScopePopup = () => {
    this.setState({ isScopePopupOpen: false });
  };
  
  handleScopeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEstimate = event.target.value;

  this.setState((prevState) => ({
    jobReviewData: {
      ...prevState.jobReviewData,
      attributes: {
        ...prevState.jobReviewData.attributes,
        estimate: newEstimate
      }
    }
  }));
  };
  

  handleDurationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDuration= event.target.value;

  this.setState((prevState) => ({
    jobReviewData: {
      ...prevState.jobReviewData,
      attributes: {
        ...prevState.jobReviewData.attributes,
        project_length: newDuration
      }
    }
  }));
  };
  
  handleSaveScope = () => {
    this.setState((prevState) => ({
      jobReviewData: {
        ...prevState.jobReviewData,
        estimate: prevState.editedScope,
        project_length: prevState.editedDuration,
      },
      isScopePopupOpen: false,
    }));
  };

  handleOpenBudgetPopup = () => {
    this.setState({
      isBudgetPopupOpen: true,
      editedBudgetRate: this.state.jobReviewData.attributes.budget,
      editedBudgetAmount: this.state.jobReviewData.attributes.per_day_charge,
    });
  };
  
  handleCloseBudgetPopup = () => {
    this.setState({ isBudgetPopupOpen: false });
  };
  handleBudgetRateChange = (newBudgetRate: string) => {
    this.setState((prevState) => ({
      jobReviewData: {
        ...prevState.jobReviewData,
        attributes: {
          ...prevState.jobReviewData.attributes,
          budget: newBudgetRate
        }
      },
      editedBudgetRate: newBudgetRate
    }));
  };

  handleBudgetAmountChange = (event: { target: { value: any; }; }) => {
    const newAmount = event.target.value;
    this.setState((prevState) => ({
      jobReviewData: {
        ...prevState.jobReviewData,
        attributes: {
          ...prevState.jobReviewData.attributes,
          per_day_charge: newAmount
        }
      },
      editedBudgetAmount: newAmount
    }));
  };
  
  handleSaveBudget = () => {
    this.setState((prevState) => ({
      jobReviewData: {
        ...prevState.jobReviewData,
        budgetRate: prevState.editedBudgetRate,
        budgetAmount: prevState.editedBudgetAmount,
      },
      isBudgetPopupOpen: false,
    }));
  };
  handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const allowedKeys = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
      "Shift"
    ];
    return (
      (allowedKeys.includes(event.key) ? false : !/^[^A-Za-z\W_]+$/.test(event.key)) &&
      event.preventDefault()
    );
  };
  handlePostJob = () => {
    this.setState({ isSuccessPopupOpen: true });
  };
  handleSaveAsDraft=()=>{
    this.setState({ isSuccessDraftPopupOpen: true });

  }
  handleCloseSuccessPopup = async () => {
    const jobId = await storage.get('jobId');
    this.setState({ isSuccessPopupOpen: false });
    this.getJobReviewDataUpdate(jobId,false)
    this.props.navigation.navigate('ViewJob', { jobId });
  };
  handleCloseSaveDraftSuccessPopup = async () => {
    const jobId = await storage.get('jobId');
    this.setState({ isSuccessDraftPopupOpen: true });
    this.getJobReviewDataUpdate(jobId,true)
    this.postSaveAsDraft()
    this.props.navigation.navigate('FullWidthJobsTab', { jobId });
  };

  handleInputChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    this.setState({ skillInputValue: newValue });
    if (newValue) {
      const filtered = this.state.originalSkillsOption.filter((option: any) =>
        option.toLowerCase().includes(newValue.toLowerCase())
      );
      this.setState({ skillsOption: filtered });
    } else {
      this.setState({ skillsOption: this.state.originalSkillsOption });
    }
  };
  
  handleSkillMenuInputChange = (event: any, newValue: any) => {
    this.setState((prevState) => ({
      jobReviewData: {
        ...prevState.jobReviewData,
        attributes: {
          ...prevState.jobReviewData.attributes,
          skills: newValue
        }
      },
      editedSkills: newValue
    }));
  };
  
  getJobReviewDataUpdate= async ( jobId : any,draft:boolean) => {
    const {name,description,skills,per_day_charge,budget,project_length,estimate}=this.state.jobReviewData?.attributes;
    const header = {
      token: await storage.get('authToken')
    };
    let formdata = new FormData();
    formdata.append("[data][attributes][name]",name);
    formdata.append("[data][attributes][description]", description);
   
    {
      skills.forEach((skills)=>{

        formdata.append("[data][attributes][skills][]", skills);
      })
    }
 
    formdata.append("[data][attributes][per_day_charge]", per_day_charge);
    formdata.append("[data][attributes][budget]",budget);
    formdata.append("[data][attributes][project_length]", project_length);
    formdata.append("[data][attributes][est_budget]", estimate);
    formdata.append("[data][attributes][is_drafted]",String(draft));
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getJobPostDetailUpdateCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_posts/posts/${jobId}`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getJobReviewData = async ( jobId : any) => {
    const header = {
      "Content-Type": webConfigJSON.validationApiContentType,
      token: await storage.get('authToken')
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.JobReviewDataId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_posts/posts/${jobId}`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
   
  postSaveAsDraft = async () => {
    const { jobReviewData } = this.state;
    const jobId = jobReviewData.id;
    console.log("hellllloooo mr ",jobReviewData.attributes.skills)
  
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      token: await storage.get('authToken')
    };
  
    let formdata = new FormData();
    formdata.append("[data][attributes][name]", jobReviewData.attributes.name);
    formdata.append("[data][attributes][description]", jobReviewData.attributes.description);
    {
      jobReviewData.attributes.skills.forEach((item)=>{

        formdata.append("[data][attributes][skills][]", item);
      })
    }
 
    formdata.append("[data][attributes][per_day_charge]", jobReviewData.attributes.per_day_charge);
    formdata.append("[data][attributes][budget]",jobReviewData.attributes.budget);
    formdata.append("[data][attributes][project_length]", jobReviewData.attributes.project_length);
    formdata.append("[data][attributes][est_budget]", jobReviewData.attributes.estimate);
   
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.DraftThisJobsId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/posts/${jobId}/save_draft`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata  // Include form data in the request body
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "put"
    );
  
    this.setState({ loading: true }, () => {
      runEngine.sendMessage(requestMessage.id, requestMessage);
    });
  };
  
  getPostEditSearchData=async ()=> {
    const headers = {
      'content-type': 'application/json',
      token: await storage.get('authToken')
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiPostEditSearchDataId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_posts/posts/search_skills'
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }
  // Customizable Area End
}
