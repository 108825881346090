import React from "react";

// Customizable Area Start
import FreelancerContactInformationController, {Props} from "./FreelancerContactInformationController";
import { Box, Dialog, DialogActions, DialogContent, FormControl, Grid, TextField, Typography, styled } from "@material-ui/core";
import { cancel, edit } from "./assets";
import { image_Success } from "../../email-account-registration/src/assets";
const CustomDialog = styled(Dialog)({
    '& .MuiPaper-rounded':{
      borderRadius: "18px",
      width:"480px"
    }
  })
  
  const DeactivateDialog = styled(Dialog)({
    '& .MuiPaper-rounded':{
      borderRadius: "18px",
    }
  })
  const HeaderGrid = styled(Grid)({
    '@media(max-width: 599px)': {
        '& .inner-header-box': {
            maxWidth: '100%',
            margin: 'auto',
            marginTop:"14px"
        },
    },
    '@media(max-width: 480px)': {
        '& .inner-header-box': {
            maxWidth: '100%',
            margin: 'auto',
            marginTop:"14px"
        },
    }
});
// Customizable Area End

export default class FreelancerContactInformation extends FreelancerContactInformationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderDialogBox = () => {
        return (
            <CustomDialog
                open={this.state.isDialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent style={{ textAlign: 'center',padding:"24px" }}>
                    <img src={image_Success} alt="Success" style={{ width: "35%", borderRadius: "50%",marginTop:"20px" ,marginBottom:"10px"}} />
                    <Typography gutterBottom style={styles.congratsText}>
                        Account Deleted Successfully!  
                    </Typography>
                    <Typography style={styles.interStyleText}>
                    You’ll be directed to our website in {this.state.countdown}
                    </Typography>
                </DialogContent>
            </CustomDialog>
        );
    };
    // Customizable Area End
    render() {  
        return (
            // Customizable Area Start
            // Required for all blocks
                <HeaderGrid container>
                    <Grid item xs={12} className="inner-header-box">
                    <Box style={styles.passwordSettingBorderBox}>
                    <Box style={styles.headingDisplay}>
                        <Typography style={styles.accountText}>Account</Typography>
                        <Box>
                            <img src={edit}/>
                        </Box>
                    </Box>
                    <Box style={{marginTop:"32px"}}>
                    <Typography style={styles.text16bold}>User ID</Typography>
                    <Typography style={styles.text18bold}>CBG67890</Typography>

                    <Typography style={{...styles.text16bold,marginTop:"32px"}}>Name</Typography>
                    <Typography style={styles.text18bold}>John Doe</Typography>

                    <Typography style={{...styles.text16bold,marginTop:"32px"}}>Email ID</Typography>
                    <Typography style={styles.text18bold}>John.Doe01@example.com</Typography>

                    <Box style={{marginTop:"32px"}}>
                    <Typography style={{...styles.text16bold,color:"#DF0004",textDecoration:"underline" }} onClick={this.handleOpenPasswordModal}>CLOSE MY ACCOUNT</Typography>
                    </Box>
                    </Box>
                    </Box>
                    
                    <Box style={{...styles.passwordSettingBorderBox, marginTop:"24px"}}>
                    <Box style={styles.headingDisplay}>
                        <Typography style={styles.accountText}>Personal Details</Typography>
                        <Box>
                            <img src={edit}/>
                        </Box>
                    </Box>
                    <Typography style={{...styles.text20bold,marginTop:"32px"}}>Address</Typography>
                    <Typography style={{...styles.text16bold,fontWeight:400,marginTop:"12px"}}>
                    193, Elizabeth apartments, George Street, New York 
                    <Typography>
                    New York - 1234567
                    </Typography>
                    </Typography>
                    <Typography style={{...styles.text20bold,marginTop:"32"}}>Phone</Typography>
                    <Typography style={{...styles.text16bold,fontWeight:400,marginTop:"12px"}}>+44 - 1234567890</Typography>
                    </Box>

                    <Box style={{...styles.passwordSettingBorderBox, marginTop:"24px"}}>
                    <Typography style={styles.text20bold}>Client Account</Typography>
                    <Typography style={{...styles.text16bold,fontWeight:400,marginTop:"12px"}}>Hire, manage and pay as a different company. Each client company has its own freelancers, payment methods and reports.</Typography>
                    <button style={{...styles.createNewAccBtn,marginTop:"24px"}}>NEW CLIENT ACCOUNT</button>
                    </Box>
                    </Grid>


                    <DeactivateDialog 
                        open={this.state.openPasswordModal} 
                        onClose={this.handleClosePasswordModal} 
                        maxWidth="sm" 
                        fullWidth 
                        style={{ borderRadius: '16px' }}
                    >
                        <Box>
                            <DialogContent style={{ padding: "24px" }}>
                                <div style={{ ...styles.headingDisplay, borderBottom: "1px solid #979797" }}>
                                    <Typography style={{ ...styles.text24BoldRed, marginBottom: "16px" }}>
                                        Close my Account
                                    </Typography>
                                    <img 
                                        src={cancel} 
                                        width={14} 
                                        height={14} 
                                        style={{ marginBottom: "16px", cursor: "pointer" }} 
                                        onClick={this.handleClosePasswordModal} 
                                    />
                                </div>

                                <div style={{ marginTop: "32px" }}>
                                    <Typography style={{ ...styles.text16bold, fontWeight: 400 }}>
                                        Deleting your account will remove all of your information from our database. This cannot be undone.
                                    </Typography>
                                    <Typography style={styles.text14Bold}>
                                        To continue, Please enter your password.
                                    </Typography>

                                    <div style={{ marginTop: "24px" }}>
                                        <Typography style={{ ...styles.text16bold, marginBottom: "12px" }}>Password</Typography>
                                        <FormControl style={styles.textField} variant="outlined">
                                            <TextField
                                                placeholder="Enter your password"
                                                name="current_password"
                                                type="password"
                                                variant="outlined"
                                                inputProps={{
                                                    "aria-label": "current_password",
                                                    style: {
                                                        fontSize: "16px",
                                                        fontWeight: 500,
                                                        fontFamily: "'Inter', sans-serif",
                                                        color: "#000000"
                                                    },
                                                    maxLength: 12
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </DialogContent>

                            <DialogActions style={{ display: "flex", gap: "12px", padding: "24px" }}>
                                <button 
                                    onClick={this.handleClosePasswordModal} 
                                    style={styles.createNewAccBtn}
                                >
                                    CANCEL
                                </button>
                                <button 
                                    style={styles.deleteAccBtn} 
                                    onClick={this.handleOpenDialog} 
                                >
                                    DELETE ACCOUNT
                                </button>
                            </DialogActions>
                        </Box>
                    </DeactivateDialog>

                    {this.renderDialogBox()}
                </HeaderGrid>
                
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const styles = {
    passwordSettingBorderBox: {
        border:"1px solid #CECECE",
        padding:"24px",
        borderRadius:"16px"
    },
    headingDisplay: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    accountText:{
        fontSize: '24px',
        fontWeight: 700,
        fontFamily:"Rubik",
        color:"#2A2F40"
    },
    text16bold:{
        fontSize: '16px',
        fontFamily:"Rubik",
        fontWeight: 500,
        textTransform: "inherit" as "inherit",
    },
    text18bold:{
        fontSize: '18px',
        fontFamily:"Rubik",
        fontWeight: 500,
        background:"#F4F4F4",
        marginTop:"12px",
        padding:"10px",
        borderRadius:"16px",
    },
    text20bold:{
        fontSize: '20px',
        fontWeight: 500,
        fontFamily:"Rubik",
    },
    createNewAccBtn:{
        color: "#364BA0",
        border: "1px solid #364BA0",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center",
        padding: "10px 22px",
        fontSize: "18px",
        fontFamily: "Rubik",
        fontWeight: 500,
        background:"#fff",
        cursor:"pointer",
    },
    deleteAccBtn:{
        color: "#fff",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center",
        padding: "10px 22px",
        fontSize: "16px",
        fontFamily: "Rubik",
        fontWeight: 500,
        background:"#DF0004",
        border: "1px solid #DF0004",
        cursor:"pointer"
      },
    text24BoldRed:{
        fontSize: '24px',
        fontWeight: 500,
        fontFamily:"Rubik",
        color:"DF0004"
    },
    text14Bold:{
        fontSize: '14px',
        fontWeight: 500,
        fontFamily:"Rubik",
        marginTop:"24px"
    },
    textField: {
        width: "100%",
        "&.MuiFormLabel-root.Mui-focused ": {
          color: "#6F6E6E !important"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline ": {
          borderColor: "#6F6E6E !important"
        }
      },
    congratsText:{
        marginTop:"24px",
        fontSize: "24px",
        color: "#059669",
        fontWeight: 600,
        fontFamily: "'Inter', sans-serif"
    },
    interStyleText:{
        marginTop:"24px",
        fontSize: "16px",
        color: "#011342",
        fontFamily: "'Inter', sans-serif"
    },
}
// Customizable Area End
