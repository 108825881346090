import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import storage from 'framework/src/StorageProvider';

interface IProfileData {
  data: {
    type: string;
    attributes: {
      basic_details: {
        id: number;
        first_name: string;
        last_name: string;
        email: string;
        country_code: string | null;
        full_phone_number: string | null;
        phone_number: number | null;
        dob: string;
        address: string;
        additional_address: string;
        apt_suite: string;
        nation: {
          id: number;
          name: string;
        };
        state: {
          id: number;
          name: string;
          nation_id: number;
        };
        city: string;
        zipcode: string;
        profile_status: {
          patents: boolean;
          expertise: boolean;
          experience: boolean;
          achievements: boolean;
          basic_details: boolean;
        };
        job_title: string;
        job_description: string;
        skills: string[];
        title_description: string | null;
        price: number | null;
        work_history: string | null;
        profile_image: string;
        experience_level: string;
        project_preferences: string;
        created_at: string;
        updated_at: string;
      };
      profile_data: {
        copyright_specialist: string | null;
        location: string | null;
        hour_rate: number;
        email: string;
        area_of_expertise: string[];
        language_expertises: {
          id: number;
          name: string;
          proficiency: string;
          created_at: string;
          updated_at: string;
        }[];
        industries: {
          id: number;
          name: string;
          created_at: string;
          updated_at: string;
        }[];
        services: {
          id: number;
          name: string;
          created_at: string;
          updated_at: string;
        }[];
        social_media: {
          id: number;
          media_name: string;
          link: string;
          created_at: string;
          updated_at: string;
        }[];
        skills: {
          name: string;
        }[];
        educations: {
          id: number;
          degree_name: string;
          college_name: string;
          country: string;
          start_date: string;
          end_date: string;
          description: string;
          degree_img: string | null;
          created_at: string;
          updated_at: string;
        }[];
        certificates: {
          id: number;
          certificate_name: string;
          certificate_provider: string;
          certification_id: string;
          certification_url: string;
          description: string | null;
          issue_date: string;
          expiration_date: string;
          created_at: string;
          updated_at: string;
        }[];
        licenses: {
          id: number;
          licensed_profession: string;
          jurisdiction_or_licensing_body: string;
          license_number: string;
          licensing_verification_url: string;
          issue_date: string;
          expiry_date: string;
          created_at: string;
          updated_at: string;
        }[];
        work_experiences: {
          id: number;
          company: string;
          job_title: string;
          location: string | null;
          currently_working: boolean;
          start_date: string;
          end_date: string;
          description: string;
          created_at: string;
          updated_at: string;
        }[];
        achievements: {
          public_relation_meterials: {
            id: number;
            organization_name: string;
            description: string;
            link: string;
            created_at: string;
            updated_at: string;
          }[];
          publications: {
            id: number;
            title: string;
            description: string;
            link: string;
            created_at: string;
            updated_at: string;
          }[];
          engagements_and_seminars: {
            id: number;
            title: string;
            description: string;
            link: string;
            account_id: number;
            created_at: string;
            updated_at: string;
          }[];
        };
        work_history: any[];
      };
      patents: {
        id: number;
        first_name: string;
        last_name: string;
        profile_status: {
          patents: boolean;
          expertise: boolean;
          experience: boolean;
          achievements: boolean;
          basic_details: boolean;
        };
        categories: {
          id: number;
          name: string;
          sub_categories: {
            id: number;
            name: string;
            sub_category_menus: {
              id: number;
              title: string;
              sub_category_sub_menus: {
                id: number;
                title: string;
                description: string;
              }[];
            }[];
          }[];
        }[];
      };
    };
  };
}

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  ratingNumber:number | null;
  rating: string
  selectedTab: number, 
  projects: any[]
  isPublicView: boolean
  editCertificateModalOpen: boolean
  addLanguageModalOpen: boolean
  addEducationModalOpen: boolean
  addExperienceModalOpen: boolean
  addCertificateModalOpen: boolean
  certificateName: string
  certificateProvider: string
  certificationID: string
  CertificationURL: string
  certificateIssueDate : string
  certificateExpirationDate : string
  addLanguages : string
  addProficiency: string
  addDegree: string
  addUniversityName: string
  addCountry:string
  addYearAttended : string
  addYearEnded: string
  addDescription : string
  addExperienceTitle:string
  addExperienceCompany:string
  addExperienceStartMonth:string
  addExperienceStartYear:string
  addExperienceCurrentlyWorking:false,
  addExperienceEndMonth:string
  addExperienceEndYear:string
  addExperienceDescription:string
  AddCertificateName: string
  AddCertificateProvider: string
  AddCertificationID: string
  AddCertificationURL: string
  AddCertificateIssueDate: string
  AddCertificateExpirationDate: string
  authToken:string
  freelancerCompleteData: IProfileData | null;
  showAll:boolean
  showAllEducation: boolean
  showAllArticles:boolean
  articles: any[],
  selectedFile: File | null;
  title:string
  projectRate: string,
  servicesFee: string,
  estimatedAmount: string,
  languages:string
  proficiency:string
  skills: string
  skillsArray: string []
  industryFocus:string
  industryFocusArray :string[]
  expertise: string,
  expertiseArray: string[]
  service: string,
  servicesArray: string[],
  degree:string
  universityName: string
  country: string
  yearAttended:string
  yearEnded: string
  description:string
  experienceTitle:string
  experienceCompany:string
  experienceStartMonth:string
  experienceEndMonth:string
  experienceStartYear:string
  experienceEndYear:string
  licensedProfession: string
  LicensingBody:string
  licenseNumber:string
  licensingVerificationURL:string
  Issue_Date:string
  ExpirationDate: string
  experienceCurrentlyWorking:boolean
  editServicesModalOpen: boolean
  expertiseModalOpen: boolean
  editProfileModalOpen:boolean
  editTitleModalOpen:boolean
  editRateModalOpen:boolean
  editLanguageModalOpen: boolean
  editSkillsModalOpen: boolean
  editIndustryFocusModalOpen: boolean
  editEducationModalOpen: boolean
  editExperienceModalOpen: boolean
  editLicenceModalOpen:boolean
  // Customizable Area End
}
interface SS { }

export default class FreelancerCompleteProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  freelancerCompleteDataId:string ="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];

    this.state = {
      ratingNumber: 5,
      rating: "4.95/5",
      selectedTab: 0, 
      authToken:"",
      projects: [
        {
            title: "XYZ Project",
            duration: "Mar 6, 2020 - Oct 12, 2020",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry...",
        },
        {
            title: "ABC Project",
            duration: "Jan 1, 2021 - Jul 15, 2021",
            description: "Another project description, with its own details...",
        },
      ],
      isPublicView: false,
      editCertificateModalOpen: false,
      addLanguageModalOpen: false,
      addEducationModalOpen: false,
      addExperienceModalOpen: false,
      addCertificateModalOpen: false,
      certificateName: "",
      certificateProvider: "",
      certificationID: "",
      CertificationURL: "",
      certificateIssueDate : "",
      certificateExpirationDate : "",
      addLanguages : "",
      addProficiency: "",
      addDegree: "",
      addUniversityName: "",
      addCountry:"",
      addYearAttended : "",
      addYearEnded: "",
      addDescription : "",
      addExperienceTitle:"",
      addExperienceCompany:"",
      addExperienceStartMonth:"",
      addExperienceStartYear:"",
      addExperienceCurrentlyWorking:false,
      addExperienceEndMonth:"",
      addExperienceEndYear:"",
      addExperienceDescription:"",
      AddCertificateName: '',
      AddCertificateProvider: '',
      AddCertificationID: '',
      AddCertificationURL: '',
      AddCertificateIssueDate: '',
      AddCertificateExpirationDate: '',
      freelancerCompleteData: null,
      showAll:false,
      showAllEducation: false, 
      showAllArticles:false,
      articles: [],
      selectedFile: null,
      title:"",
      projectRate: "150",
      servicesFee: "10",
      estimatedAmount: "1650",
      languages:"",
      proficiency:"",
      skills: "",
      skillsArray: [],
      industryFocus:"",
      industryFocusArray :[],
      expertise: '',
      expertiseArray: [],
      service: '',
      servicesArray: [],
      degree:"",
      universityName:"",
      country:"",
      yearAttended:"",
      yearEnded:"",
      description:"",
      experienceTitle:"",
      experienceCompany:"",
      experienceStartMonth:"",
      experienceEndMonth:"",
      experienceStartYear:"",
      experienceEndYear:"",
      licensedProfession:"",
      LicensingBody:"",
      licenseNumber:"",
      licensingVerificationURL:"",
      Issue_Date:"",
      ExpirationDate:"",
      experienceCurrentlyWorking:false,
      editServicesModalOpen: false,
      expertiseModalOpen: false,
      editProfileModalOpen:false,
      editTitleModalOpen:false,
      editRateModalOpen:false,
      editLanguageModalOpen: false,
      editSkillsModalOpen: false,
      editIndustryFocusModalOpen: false,
      editEducationModalOpen: false,
      editExperienceModalOpen:false,
      editLicenceModalOpen:false

    };
    this.handleSkillsChange = this.handleSkillsChange.bind(this);
    this.handleIndustryChange = this.handleIndustryChange.bind(this);
    this.handleExpertiseChange = this.handleExpertiseChange.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.setState({
      authToken: await storage.get('authToken'),
    });
    this.freelancerCompleteProfileData()
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      if (webResponseJson) {
        if (webApiRequestCallId === this.freelancerCompleteDataId) {
          this.setState({ freelancerCompleteData: webResponseJson});
        } 
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleTabChange = (event: any, newValue: number) => {
    this.setState({ selectedTab: newValue });
  };
  
  toggleView = () => {
    this.setState((prevState) => ({
        isPublicView: !prevState.isPublicView,
    }));
};
handleEditModalOpenCertificate = () => {
  this.setState({ editCertificateModalOpen: true });
};
handleAddModalOpenLanguage = () => {
  this.setState({ addLanguageModalOpen: true });
};
handleAddModalOpenEducation = () => {
  this.setState({ addEducationModalOpen: true });
};
handleEditModalCloseCertificate = () => {
  this.setState({ editCertificateModalOpen: false });
};
handleAddModalCloseLanguage = () => {
  this.setState({ addLanguageModalOpen: false });
};
handleAddModalCloseEducation = () => {
  this.setState({ addEducationModalOpen: false });
};
handleChangeCertificateName = (event: { target: { value: any; }; }) => {
  this.setState({ certificateName: event.target.value });
}
handleChangeCertificateProvider = (event: { target: { value: any; }; }) => {
  this.setState({ certificateProvider: event.target.value });
}
handleChangeCertificationID = (event: { target: { value: any; }; }) => {
  this.setState({ certificationID: event.target.value });
}

handleChangeCertificationURL = (event: { target: { value: any; }; }) => {
  this.setState({ CertificationURL: event.target.value });
}
handleChangeCertificateIssueDate = (event: { target: { value: any; }; }) => {
  this.setState({ certificateIssueDate: event.target.value });
}
handleCertificateExpirationDateChange = (event: { target: { value: any; }; }) => {
  this.setState({ certificateExpirationDate: event.target.value });
}
handleChangeAddLanguages = (event: { target: { value: any; }; }) => {
  this.setState({ addLanguages: event.target.value });
}
handleChangeAddProficiency = (event: { target: { value: any; }; }) => {
  this.setState({ addProficiency: event.target.value });
}
handleChangeAddDegree = (event: { target: { value: any; }; }) => {
  this.setState({ addDegree: event.target.value });
}
handleChangeAddUniversityName = (event: { target: { value: any; }; }) => {
  this.setState({ addUniversityName: event.target.value });
}
handleChangeAddCountry = (event: { target: { value: any; }; }) => {
  this.setState({ addCountry: event.target.value });
}
handleChangeAddYearAttended = (event: { target: { value: any; }; }) => {
  this.setState({ addYearAttended: event.target.value });
}
handleChangeAddYearEnded = (event: { target: { value: any; }; }) => {
  this.setState({ addYearEnded: event.target.value });
}
handleChangeAddDescription = (event: { target: { value: any; }; }) => {
  this.setState({ addDescription: event.target.value });
}
handleSaveCertificate  = () => {
  this.setState({ editCertificateModalOpen: false });
};
handleSaveAddLanguage = () => {
  this.setState({ addLanguageModalOpen: false });
};
handleAddedEducationSave = () => {
  this.setState({ addEducationModalOpen: false });
};
handleAddModalOpenExperience = () => {
  this.setState({ addExperienceModalOpen: true });
};

handleAddModalCloseExperience = () => {
  this.setState({ addExperienceModalOpen: false });
};

handleChangeAddExperienceTitle = (event: { target: { value: any; }; }) => {
  this.setState({ addExperienceTitle: event.target.value });
};

handleChangeAddExperienceCompany = (event: { target: { value: any; }; }) => {
  this.setState({ addExperienceCompany: event.target.value });
};

handleChangeAddStartMonth = (event: { target: { value: any; }; }) => {
  this.setState({ addExperienceStartMonth: event.target.value });
};

handleChangeAddStartYear = (event: { target: { value: any; }; }) => {
  this.setState({ addExperienceStartYear: event.target.value });
};

handleAddCurrentlyWorkingToggle = (event: { target: { checked: any; }; })  => {
  this.setState({ addExperienceCurrentlyWorking: event.target.checked });
};

handleChangeAddEndMonth = (event: { target: { value: any; }; }) => {
  this.setState({ addExperienceEndMonth: event.target.value });
};

handleChangeAddEndYear = (event: { target: { value: any; }; }) => {
  this.setState({ addExperienceEndYear: event.target.value });
};

handleChangeAddExperienceDescription = (event: { target: { value: any; }; }) => {
  this.setState({ addExperienceDescription: event.target.value });
};

handleAddedSaveExperience = () => {
 
};
handleAddModalOpenCertificate = () => {
  this.setState({ addCertificateModalOpen: true });
};

handleAddModalCloseCertificate = () => {
  this.setState({ addCertificateModalOpen: false });
};

handleChangeAddCertificateName = (event: { target: { value: any; }; })  => {
  this.setState({ AddCertificateName: event.target.value });
};

handleChangeAddCertificateProvider = (event: { target: { value: any; }; })  => {
  this.setState({ AddCertificateProvider: event.target.value });
};

handleChangeAddCertificationID = (event: { target: { value: any; }; })  => {
  this.setState({ AddCertificationID: event.target.value });
};

handleChangeAddCertificationURL = (event: { target: { value: any; }; })  => {
  this.setState({ AddCertificationURL: event.target.value });
};

handleChangeAddCertificateIssueDate = (event: { target: { value: any; }; })  => {
  this.setState({ AddCertificateIssueDate: event.target.value });
};

handleChangeAddCertificateExpirationDate = (event: { target: { value: any; }; })  => {
  this.setState({ AddCertificateExpirationDate: event.target.value });
};

handleSaveAddCertificate = () => {

};


toggleShowAll = () => {
  this.setState((prevState) => ({ showAll: !prevState.showAll }));
};
toggleShowAllEducation = () => {
  this.setState((prevState) => ({ showAllEducation: !prevState.showAllEducation }));
};
handleProfileSettings = () => {
  this.props.navigation.navigate('FreelancerSetting')
}
toggleShowAllArticles = () => {
  this.setState((prevState) => ({ showAllArticles: !prevState.showAllArticles }));
};
freelancerCompleteProfileData = () => {
  const header = {
    "Content-Type": "application/json",
    token: this.state.authToken
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.freelancerCompleteDataId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `account_block/freelancers/get_profile_freelancer`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};

handleRedirection = () => {
    this.props.navigation.navigate("componentName");
};

handleRedirection1 = () => {
    this.props.navigation.navigate("componentName");
};

handleRedirection2 = () => {
    this.props.navigation.navigate("componentName");
};

handleRedirection3 = () => {
    this.props.navigation.navigate("componentName");
};

handleRedirection4 = () => {
    this.props.navigation.navigate("componentName");
};

handleRedirection5 = () => {
  this.props.navigation.navigate("componentName");
};
  // Customizable Area End
handleEditProfileModalOpen = () => {
  this.setState({ editProfileModalOpen: true });
}
handleEditModalOpenTitle = () => {
  this.setState({ editTitleModalOpen: true });
}
handleEditModalOpenRate = () => {
  this.setState({ editRateModalOpen: true });
}
handleEditModalOpenLanguage = () => {
  this.setState({ editLanguageModalOpen: true });
};

handleEditModalOpenSkills = () => {
  this.setState({ editSkillsModalOpen: true });
};

handleEditModalOpenIndustryFocus = () => {
  this.setState({ editIndustryFocusModalOpen: true });
};

handleEditModalOpenEducation = () => {
  this.setState({ editEducationModalOpen: true });
};
handleEditModalOpenExperience  = () => {
  this.setState({ editExperienceModalOpen: true });
};
handleEditModalOpenLicence = ()=>{
  this.setState({ editLicenceModalOpen: true });
}
handleEditModalCloseLicence = ()=>{
  this.setState({ editLicenceModalOpen: false });
}
handleEditModalCloseExperience = () => {
  this.setState({ editExperienceModalOpen: false });
};
handleEditModalCloseSkills = () => {
  this.setState({ editSkillsModalOpen: false });
};
handleEditProfileModalClose = () => {
  this.setState({ editProfileModalOpen: false });
};

handleEditModalCloseTitle = () => {
  this.setState({ editTitleModalOpen: false });
};
handleEditModalCloseRate = () => {
  this.setState({ editRateModalOpen: false });
};
handleEditModalCloseLanguage = () => {
  this.setState({ editLanguageModalOpen: false });
};

handleEditModalCloseIndustryFocus = () => {
  this.setState({ editIndustryFocusModalOpen: false });
};

handleEditModalCloseEducation = () =>{
  this.setState({ editEducationModalOpen: false });
}
handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const file = event.target.files?.[0] || null;
  this.setState({ selectedFile: file });
};
handleChangeTitle = (event: { target: { value: any; }; }) => {
  this.setState({ title: event.target.value });
}
handleChangeDegree = (event: { target: { value: any; }; })=>{
  this.setState({degree: event.target.value})
}

handleChangeUniversityName = (event: { target: { value: any; }; })=>{
  this.setState({universityName: event.target.value})
}

handleChangeCountry= (event: { target: { value: any; }; })=>{
  this.setState({country: event.target.value})
}
handleChangeYearAttended =(event: { target: { value: any; }; })=>{
  this.setState({yearAttended: event.target.value})
}
handleChangeYearEnded =(event: { target: { value: any; }; })=>{
  this.setState({yearEnded: event.target.value})
}
handleChangeDescription  =(event: { target: { value: any; }; })=>{
  this.setState({description: event.target.value})
}
handleChangeExperienceTitle  =(event: { target: { value: any; }; })=>{
  this.setState({experienceTitle: event.target.value})
}
handleChangeExperienceCompany  =(event: { target: { value: any; }; })=>{
  this.setState({experienceCompany: event.target.value})
}
handleChangeStartMonth =(event: { target: { value: any; }; })=>{
  this.setState({experienceStartMonth: event.target.value})
}
handleChangeEndMonth =(event: { target: { value: any; }; })=>{
  this.setState({experienceEndMonth: event.target.value})
}
handleChangeStartYear =(event: { target: { value: any; }; })=>{
  this.setState({experienceEndMonth: event.target.value})
} 
handleChangeEndYear =(event: { target: { value: any; }; })=>{
  this.setState({experienceEndMonth: event.target.value})
} 
handleChangeLicensedProfession =(event: { target: { value: any; }; })=>{
  this.setState({licensedProfession: event.target.value})
} 
handleChangeLicensingBody =(event: { target: { value: any; }; })=>{
  this.setState({LicensingBody: event.target.value})
} 
handleChangeLicenseNumber =(event: { target: { value: any; }; })=>{
  this.setState({licenseNumber: event.target.value})
} 
handleChangeLicensingVerificationURL =(event: { target: { value: any; }; })=>{
  this.setState({licensingVerificationURL: event.target.value})
} 
handleIssueDateChange = (event: { target: { value: any; }; })=>{
  this.setState({Issue_Date: event.target.value})
} 
handleExpirationDateChange  = (event: { target: { value: any; }; })=>{
  this.setState({ExpirationDate: event.target.value})
} 
handleCurrentlyWorkingToggle = (event: { target: { checked: any; }; }) => {
  this.setState({ experienceCurrentlyWorking: event.target.checked });
};

handleProjectRateChange = (event: { target: { value: any; }; }) => {
  this.setState({ projectRate: event.target.value });
}

handleEstimatedAmountChange = (event: { target: { value: any; }; }) => {
  this.setState({ estimatedAmount: event.target.value });
}

handleSkillsChange(event: { target: { value: any; }; }) {
  this.setState({ skills: event.target.value});
}
handleIndustryChange(event: { target: { value: any; };}){
  this.setState({ industryFocus: event.target.value});
}
handleSkillsKeyPress = (event: React.KeyboardEvent) => {
  if (event.key === 'Enter' && this.state.skills.trim() !== '') {
    this.setState((prevState) => ({
      skillsArray: [...prevState.skillsArray, prevState.skills.trim()],
      skills: '',
    }));
  }
};
handleIndustryKeyPress = (event: React.KeyboardEvent) =>{
  if (event.key === 'Enter' && this.state.industryFocus.trim()!== '') {
    this.setState((prevState) => ({
      industryFocusArray: [...prevState.industryFocusArray, prevState.industryFocus.trim()],
      industryFocus: '',
    }));
  }
}
handleDeleteSkill = (skillToDelete: string) => {
  this.setState((prevState) => ({
    skillsArray: prevState.skillsArray.filter((skill) => skill !== skillToDelete),
  }));
};

handleDeleteIndustry = (industryToDelete: string) => {
  this.setState((prevState) => ({
    industryFocusArray: prevState.industryFocusArray.filter((industry) => industry!== industryToDelete),
  }));
};
handleSaveTitle = () => {
  this.setState({ editTitleModalOpen: false });
}

handleSaveRate = () => {
  this.setState({ editRateModalOpen: false });
}
handleSaveSkills = () => {
  this.setState({ editSkillsModalOpen: false });
};

handleSaveIndustry =()=>{
  this.setState({ editIndustryFocusModalOpen: false });
}

handleSave = () => {
  const { selectedFile } = this.state;
  if (selectedFile) {
    console.log("Uploading file:", selectedFile);
  }
};
handleSaveLanguage = () => {
  this.setState({ editLanguageModalOpen: false });
};
handleSaveExperience =()=>{
  this.setState({ editExperienceModalOpen: false });
}
handleSaveLicence = ()=>{
  this.setState({ editLicenceModalOpen: false });
}
handleEducationSave  =()=>{
  this.setState({ editEducationModalOpen: false });
}
handleChangeLanguages = (event: { target: { value: any; }; }) => {
  this.setState({ languages: event.target.value });
};

handleChangeProficiency = (event: { target: { value: any; }; }) => {
  this.setState({ proficiency: event.target.value });
};

handleDelete = () => {
  this.setState({
    languages: "",
    proficiency: "",
  });
};

handleEditModalOpenExpertise = () => {
  this.setState({ expertiseModalOpen: true });
};

handleEditModalCloseExpertise = () => {
  this.setState({ expertiseModalOpen: false });
};

handleExpertiseChange = (event: { target: { value: any; }; }) => {
  this.setState({ expertise: event.target.value });
};

handleExpertiseKeyPress = (event: { key: string; }) => {
  if (event.key === 'Enter' && this.state.expertise.trim() !== '') {
    this.setState((prevState) => ({
      expertiseArray: [...prevState.expertiseArray, prevState.expertise.trim()],
      expertise: '',
    }));
  }
};

handleDeleteExpertise = (expertiseToDelete: string) => {
  this.setState((prevState) => ({
    expertiseArray: prevState.expertiseArray.filter((item) => item !== expertiseToDelete),
  }));
};

handleSaveExpertise = () => {
  this.handleEditModalCloseExpertise();
};

handleEditModalOpenServices = () => {
  this.setState({ editServicesModalOpen: true });
};

handleEditModalCloseServices = () => {
  this.setState({ editServicesModalOpen: false });
};

handleServiceChange = (event: { target: { value: any; }; }) => {
  this.setState({ service: event.target.value });
};

handleServiceKeyPress = (event: { key: string; }) => {
  if (event.key === 'Enter' && this.state.service.trim() !== '') {
    this.setState((prevState) => ({
      servicesArray: [...prevState.servicesArray, prevState.service.trim()],
      service: '',
    }));
  }
};

handleDeleteService = (serviceToDelete: string) => {
  this.setState((prevState) => ({
    servicesArray: prevState.servicesArray.filter((item) => item !== serviceToDelete),
  }));
};

handleSaveService = () => {
  this.handleEditModalCloseServices();
};

  // Customizable Area End
}