import React from "react";
// Customizable Area Start
import { Box, Button, Grid, IconButton, Menu,Snackbar, MenuItem,Typography,Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  InputAdornment,
  TextField, 
  CircularProgress} from "@material-ui/core";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Pagination from '@material-ui/lab/Pagination';

import  {
    JobListData
} from "./MyJobsController";
import SearchIcon from "@material-ui/icons/Search";
// Customizable Area End
import MyJobsController, {
    Props,
    webConfigJSON
} from "./MyJobsController";

export default class MyJobs extends MyJobsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        if (this.state.loading) {
          return (
              <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                  <CircularProgress />
              </Box>
          );
      }
        return (
            //Merge Engine DefaultContainer
            <>
               <Grid container style={webStyles.mainConatiner}>
        <Box style={webStyles.searchContainer}>
          <TextField
            id="search"
            variant="outlined"
            data-testid="search"
            size="small"
            fullWidth
            placeholder="search"
            value={this.state.searchJob}
            onChange={this.handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="disabled" />
                </InputAdornment>
              ),
            }}
          />
          <Button style={webStyles.filterBtn} onClick={this.handleClickSearch}>Search</Button>
        </Box>
        <Grid item style={webStyles.borderBox}>
          <Box>
          <Grid container>
  <Grid item style={{ width: "100%" }}>
  {this.state.noJobsFound ? (
      <Typography style={webStyles.noProfile}>No job found</Typography>
    ) : (
      this.state.jobListData.map((item: JobListData, index: any) => {
        return (
          <Box key={item.id} style={webStyles.proposalContainer}>
            <Box>
              <Typography style={webStyles.postDescription} color="secondary">{item?.attributes?.name}</Typography>
              <Typography color="secondary" style={{ fontSize: "15px" }}>{item?.attributes?.created_at}</Typography>
            </Box>
            <Box style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <Box style={webStyles.proposalMainBox}>
                <Box>
                  <Typography color="primary" style={webStyles.proposalCount}>{item?.attributes?.proposals}</Typography>
                  <Typography>{webConfigJSON.proposals}</Typography>
                </Box>
                <Box>
                  <Typography color="primary">{item?.attributes?.messaged}</Typography>
                  <Typography>{webConfigJSON.messaged}</Typography>
                </Box>
                <Box>
                  <Typography color="primary">{item?.attributes?.hired}</Typography>
                  <Typography>{webConfigJSON.hired}</Typography>
                </Box>
              </Box>
              <Box style={{ display: "flex", alignItems: "center", width: "100%", gap: "24px" }}>
                <Box style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    data-test-id="viewProposal"
                    style={webStyles.viewProposalBtn}
                    onClick={() => this.handleViewJob(item.id)}
                  >
                    {webConfigJSON.ViewProposals}
                  </Button>
                  <Button
                    variant="contained"
                    data-test-id="reuseThisBtn"
                    style={webStyles.reuseThisBtn}
                    onClick={() => this.handleReusethisJobs(item.id)}
                  >
                    {webConfigJSON.reusePost}
                  </Button>
                </Box>
                <Box style={webStyles.iconButton}>
                  <IconButton
                    data-test-id="handleClick"
                    aria-label="more"
                    aria-controls="long-menu"
                    onClick={(e) => this.handleClick(e, item.id)}
                    style={{ width: "40px", height: "40px" }}
                  >
                    <MoreHorizIcon color="primary" />
                  </IconButton>
                </Box>
                {this.state.anchorEl && (
                  <Menu
                    id="long-menu"
                    data-testid={"menu-options-" + index}
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: -40,
                      horizontal: 'right',
                    }}
                  >
                    <MenuItem data-test-id="handleViewJob" onClick={() => this.handleViewProposals(this.state.selectedJobId)}>
                      {webConfigJSON.ViewProposals}
                    </MenuItem>
                    <MenuItem data-test-id={"handleClose"} onClick={this.handleClose}>
                      {webConfigJSON.InviteFreelancers}
                    </MenuItem>
                    <MenuItem data-test-id="handleViewJob2" onClick={() => this.handleViewJob(this.state.selectedJobId)}>
                      {webConfigJSON.Viewjobposting}
                    </MenuItem>
                    <MenuItem data-test-id="handleEditPosting" onClick={() => this.handleEditPosting(this.state.selectedJobId)}>
                      {webConfigJSON.Editposting}
                    </MenuItem>
                    <MenuItem data-test-id="handleReusethisJobs" onClick={() => { this.handleReusethisJobs(this.state.selectedJobId) }}>
                      {webConfigJSON.Reuseposting}
                    </MenuItem>
                    <MenuItem data-test-id="handleDeleteJob" onClick={() => this.handleDeleteJob(this.state.selectedJobId)}>
                      {webConfigJSON.Removeposting}
                    </MenuItem>
                  </Menu>
                )}
              </Box>
            </Box>
          </Box>
        );
      })
    )}
  </Grid>
</Grid>

          </Box>
        </Grid>
        {this.state.totalPages > 1 && (
         <Box style={webStyles.paginationJobsContainer}>
          <Pagination 
            onChange={this.handlePageChange}
            page={this.state.currentPage} 
            count={this.state.totalPages} 
          />
          </Box>
        )}
      </Grid>

                    <Dialog
                    open={this.state.deletePopUp}
                    onClose={this.cancelDeleteJob}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText style={webStyles.EditHeadings}>
                            Are you sure you want to delete this post?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.cancelDeleteJob} data-test-id="cancelDeleteDraft" color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.confirmDeleteJob} data-test-id="confirmDeleteDraft" style={webStyles.postBtn} autoFocus>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>

                  {/* Snackbar for Success Message */}
        <Snackbar
          open={this.state.snackbarOpen}
          autoHideDuration={6000}
          onClose={this.closeSnackbar}
          message={this.state.snackbarMessage}
          action={
            <Button color="inherit" size="small" onClick={this.closeSnackbar} data-test-id="closeSnackbar">
              Close
            </Button>
          }
        />
            </>

            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const webStyles = {
    mainConatiner: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        boxSizing: 'border-box' as 'border-box',
        background:"#FAFBFC"
    },
    noProfile:{
      fontSize:'1.5rem',
      paddingTop:'5%',
      textAlign:'center' as 'center',
      color:'grey'
  },
    borderBox: {
        background: 'FAFBFC',
        padding: '32px 0px',
        borderRadius: '5px',
        overflow: 'auto',
        width:"100%"
    },
    filterBtn: {
      borderRadius: "7px",
      padding: "0 3%",
      textTransform: "capitalize" as "capitalize",
      background: "#206FC4",
      color: "white",
      fontFamily:"Inter",
      width:"20%"
    },
    searchContainer: {
      display: "flex",
      gap: "2%",
      marginTop:"44px",
      width:"810px",
      marginLeft:"24px"
    },
    searchField: {
      width: "100%",
    },
    viewProposalBtn: {
        textTransform: 'inherit' as 'inherit',
        minWidth: 'max-content',
        height: 'min-content',
        background: "#206FC4",
        fontSize:"17px",
        fontWeight:"bold" as "bold",
        fontFamily:"Inter"
    },
    reuseThisBtn:{
        textTransform: 'inherit' as 'inherit',
        minWidth: 'max-content',
        height: 'min-content',
        border:"1px solid #206FC4",
        background: "white",
        color: "#206FC4",
        fontSize:"13.5px",
        fontWeight:"bold" as "bold",
        fontFamily:"Inter"

    },
    postDescription: {
        fontSize: '22px',
        marginBottom: '2%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap' as 'nowrap',
        fontFamily:"Inter"

    },
    iconButton: {
        background: '#DBE7F4',
        borderRadius: '50%',
        textAlign: 'center' as 'center',

    },
    proposalContainer: {
        display: 'flex',
        borderBottom: '1px solid #e1e1e1',
        padding: '16px 25px',
        gap: '19.5px',
        alignItems: 'center',
        justifyContent:"space-between"
    },
    proposalMainBox: {
        display: 'flex',
        gap: "40px",
        maxWidth: "295px",
        width: "100%",
        marginRight:"26px"
    },
    proposalCount: {
        display: 'block ruby',
       fontFamily:"Inter"
    },
    noJobsContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      },
      noJobsText: {
        fontSize: '20px',
        color: 'gray',
      },
      EditHeadings:{
        fontSize:"18px",
        fontWeight:500,
        fontFamily:"Inter",
        color:"#515354"
      },
      postBtn:{
        background: "#206FC4",
    color: "white",
    fontSize: "14px",
    fontWeight: 600,
    textTransform: "inherit" as "inherit",
    marginLeft: "10px",
    padding: "10px",
    borderRadius: "10px",
    fontFamily:"Inter"
    
    },
    paginationJobsContainer: {
      alignItems: 'center',
      marginTop:"14px",
      justifyContent: 'end',
      display: 'flex',
  },
};
// Customizable Area End
