//Customizable Area Start
import React from "react";
//Customizable Area Start
import { Box, Grid, Tab, Tabs, Typography, styled } from "@material-ui/core";
import FreelancerNavBar from "./FreelancerNavBar.web";
import FooterWeb from "./Components/Footer.web";
import ActiveContractsController, { Props } from "./ActiveContractsController.web";
import ProjectDetails from "./ProjectDetails.web";
import ProjectTracking from "./ProjectTracking.web";
export const webConfigJSON = require("./config");



const CustomTabs = styled(Tabs)({
  '& .MuiTab-textColorPrimary-25.Mui-selected': {
    fontWeight: "600",
    fontSize: "22px",
    textTransform: "inherit"
  },
  '& .MuiTab-textColorPrimary-25': {
    fontWeight: "600",
    fontSize: "22px",
    textTransform: "inherit"
  },
  '& .PrivateTabIndicator-root-31': {
    height: "4px"
  }

})
// Customizable Area End

export default class ActiveContract extends ActiveContractsController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    // Customizable Area End
  }

  //Customizable Area Start



  // Customizable Area End

  render() {
    //Customizable Area Start
    const { selectedTab } = this.state;
    return (
      <>
        <FreelancerNavBar navigation={this.props.navigation} />
        <Grid container spacing={2} lg={12} style={webStyle.gridBox}>

          <Grid item lg={12} xs={12}>
            <Box style={{ marginTop: "30px" }}>
              <HeaderTxt>Active Contracts</HeaderTxt>
              <TitleTxt style={{ fontFamily: "Rubik", fontSize: "14px", color: "#353535" }}>
                Stay focused and informed with a detailed view of your active contract. Monitor progress, manage tasks, and keep all important information at your fingertips for seamless project execution.
              </TitleTxt>

            </Box>
          </Grid>
          <Box display={"flex"} flexDirection={"column"} >
            <CustomTabs
              value={selectedTab}
              onChange={this.handleTabChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Project Details" style={{ textTransform: "none", fontFamily: "Rubik", fontSize: "16px", color: "#3F3F3F", fontWeight: 500 }} />
              <Tab label="Project Tracking" style={{ textTransform: "none", fontFamily: "Rubik", fontSize: "16px", color: "#3F3F3F", fontWeight: 500 }} />
            </CustomTabs>

            {selectedTab === 0 && (
              <ProjectDetails navigation={this.props.navigation} />
            )}
            {selectedTab === 1 && (
            <ProjectTracking navigation={this.props.navigation}/>
            )}
          </Box>

        </Grid>
        <FooterWeb />
      </>
    );
    //Customizable Area End
  }
}

//Customizable Area Start

const webStyle = {

  gridBox: {
    display: 'flex',
    margin: "45px 35px 45px 35px",
    overflow: "hidden"
  },
}

const TitleTxt = styled(Typography)({
  margin: "20px 0px",
  display: "flex",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "16px",
  width: "91%",
  color: "#353535"
});


const HeaderTxt = styled(Typography)({
  fontSize: "30px",
  color: "#3F3F3F",
  fontFamily: "Inter",
  fonSize: "36px",
  fontWeight: 700,
  lineHeight: "44px",
  letterSpacing: "0px",
  textAlign: "left"
});

//Customizable Area End
// Customizable Area End
