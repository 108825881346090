// Customizable Area Start
import React, { ReactElement } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { messageIcon } from './assets';
import {IconButton,TextField, InputAdornment, Popover, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, styled,Menu } from '@material-ui/core';
import FreelancerNavBarController, {
  Props
} from './FreelancerNavBarController.web';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/styles';
import { Horizontalinventohub, inventohub } from '../../landingpage/src/assets';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { createTheme } from "@material-ui/core/styles";
import MenuIcon from '@material-ui/icons/Menu';



// Customizable Area End
const theme = createTheme({
  palette: {
      primary: {
          main: "#206FC4"
      }
  },
  typography: {
      fontFamily: "Inter,Poppins,Roboto"
  },
  breakpoints: {
      values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
      },
  },
});

class FreelancerNavBar extends FreelancerNavBarController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handlePopoverClose = () => {
    this.setState({
      anchorEl: null
    });
  };

  StyledMenuItemFun(){
    return    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' },justifyContent:"end",alignItems:"center" }}>
    <IconButton
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          aria-label="open navigation menu"
                          onClick={this.handleOpenNavMenu}
                          color="inherit" >
                          <MenuIcon style={{color:"#364BA0"}} />
                      </IconButton>
                      <Custom_Menu_design
                          id="menu-appbar"
                          anchorEl={this.state.anchorElNav}
                          getContentAnchorEl={null} 
                          keepMounted
                          anchorOrigin={{
                              vertical: 'bottom',  horizontal: 'right',  }}
                            transformOrigin={{
                              horizontal: 'right',
                              vertical: 'top',
                            }}
                          open={Boolean(this.state.anchorElNav)}
                          onClose={this.handleCloseNavMenu} >
                          <Box onClick={this.handleCloseNavMenu}>
                          <Box className='menu_item_wrapper'  style={styles.boxStyle}>
          {this.state.menuItems.map((item: any, index: number) => (
            <Typography
            variant="h6"
              key={item.label}
              aria-describedby={`popover-${item.label}`}
              onMouseEnter={
                item.options
                  ? event => { this.handlePopoverOpen(event);
                      event.currentTarget.style.color = '#206FC4'
                    }
                  : undefined
              }
              onMouseLeave={
                item.options ? event => {
                  event.currentTarget.style.color = '#4A4A4A'; 
                    this.handlePopoverClose();
                  }
                  : undefined
            }
              onClick={
                item.options
                  ? this.handleClick
                  : () => 
                    this.handleRedirection(item.componentName) 
              }
              className='menu_item'
              data-testid={`navbar-option-${index}`}
            >
              {item.label}
              {item.options &&
                (Boolean(this.state.anchorEl) && this.state.anchorEl &&
                this.state.anchorEl.getAttribute('aria-describedby') ===
                  `popover-${item.label}` ? (
                  <ExpandLessIcon />   ) : (   <ExpandMoreIcon />
                ))}
              {item.options && (
                <Popper
                id={`popover-${item.label}`}
                  key={`popover-${item.label}`}
                  open={
                    Boolean(this.state.anchorEl) &&  this.state.anchorEl &&  this.state.anchorEl.getAttribute(
                      'aria-describedby'
                    ) === `popover-${item.label}`
                  }
                  anchorEl={this.state.anchorEl}
                  disablePortal={true}
                  placement="bottom"
                  modifiers={{
                    flip: {  enabled: true },
                    preventOverflow: { enabled: true,  boundariesElement: 'scrollParent'   },
                    arrow: {
                      element: this.state.anchorEl,
                      enabled: true,
                    }
                  }}
                  style={{
                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                    zIndex: 1,
                    padding: '10px 5px',
                    background: '#FFF',
                    width: 'inherit'
                  }}
                >
                  {item.options.map((option: any, index: number) => (
                    <StyledMenuItem
                      key={option.label}
                      onClick={() => this.handleRedirection(option.componentName) }
                      data-test-id="tastItem"
                      style={styles.options}
                    >
                      {option.label}
                           </StyledMenuItem>
                  ))}
                         </Popper>
              )}
            </Typography>
          ))}</Box>
                          </Box>
                      </Custom_Menu_design>
    </Box>
  }

  RightSection(open:boolean){
    return <Box className='right_section'>
    {!this.props.isFromHomePage && (
      <TextField
        value={this.state.searchValue}
        variant="outlined"
        data-testid="search-field"
        placeholder="Search"
        style={{ ...styles.searchInput, height: '52px' }}
        onChange={this.handleChange}
        onKeyDown={this.keyPress}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )
        }}
        name="searchValue"
      />
    )}
    {!this.props.isFromHomePage && (
      <Avatar
        alt="image"
        style={{
          ...styles.imgAvatar,
          overflow: 'visible',
          height: '45px',
          width: '45px'
        }}
        src={messageIcon}
      />
    )}
    <Avatar
      data-testid="profileImage"
      alt="image"
      src={this.state.freelancerProfileImg}
      style={{
        ...styles.imgAvatar,
       
        height: '45px',
        width: '45px',
        borderRadius: '50%'
      }}
      onClick={this.handleProfileClick} 
    />
    <Popover
    data-testid="account_card"
      open={open}
      anchorEl={this.state.profileAnchorEl}
      onClose={this.handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      
    
    >
     <Box style={{padding: '7px 0',display: 'grid',gap: '7px',minWidth:"245px"}}>
     <Box
     display={"flex"}
     flexDirection={"column"}
     justifyContent={"center"}
     alignItems={"center"}
     style={{paddingTop:"10px"}}>
      
      <Avatar

      alt="image"
      src={this.state.freelancerProfileImg}
      style={{
      
        height: '88px',
        width: '88px',
        borderRadius: '50%'
      }}
      
    />
    <Typography style={{fontWeight: 500,fontFamily: 'Inter',fontSize:"16px",marginTop:"10px",padding:"0 15px",color:"#181818"}}>{this.state.accountHolderName}</Typography> 
    <Typography style={{fontWeight:400,fontFamily: 'Inter',fontSize:'11px',color:"#676767"}}>Freelancer</Typography>          
    </Box>
    {/* online/offline byttons */}
<Box
style={{
border: "2px solid #d9d9d9",
borderRadius: "10rem",
display:"flex",
justifyContent: "center",
margin:"0px 10px"
}}
>
<Button
variant={this.state.currentStatus === 0 ? "contained" : "text"}
size="medium"
style={{
margin: 0,
padding:"10px",
borderRadius: "10rem",
backgroundColor: this.getColorValue(0).backgroundColor,
fontWeight: 500,
fontFamily: 'Inter',
textTransform:"none",
color:this.getColorValue(0).color,
flex:0.5,
}}
data-testid="prf-online"
onClick={() => {this.setState({currentStatus:0});this.updateOnlineStatus(0)}}
>
Online
</Button>
<Button
variant={this.state.currentStatus === 1 ? "contained" : "text"}
onClick={() => {this.setState({currentStatus: 1});this.updateOnlineStatus(1)}}
size="medium"
style={{
margin: 0,
padding:"10px",
borderRadius: "10rem",
backgroundColor: this.getColorValue(1).backgroundColor,
fontFamily: 'Inter',
textTransform:"none",
color: this.getColorValue(1).color,
flex:0.5
}}
data-testid="prf-invisible"
  >
Invisible
</Button>
</Box>
    <Box display={"flex"} flexDirection={"column"} style={{gap:"10px",margin:"10px 20px"}}>
    <Button startIcon={<AccountCircleIcon/>}style={styles.actionButton} >
    <Box display={"flex"} flexDirection={"column"} >
    <Typography  style={{...styles.actionButtonContent,textTransform:"none"}}>{this.state.accountHolderName}</Typography>
    <Typography  style={{...styles.actionButtonContent,fontSize:"11px",color:"#676767",textTransform:"none",textAlign:"left"}}>Client</Typography>
    </Box>
    </Button>
    <Button  data-testid="prf-setting" startIcon={<SettingsIcon/>} onClick={()=>this.handleRedirection('FreelancerSetting')} style={styles.actionButton} >
    
      <Typography  style={{...styles.actionButtonContent,textTransform:"none"}}>Settings</Typography>
      </Button>
      <Button   data-testid="prf-logout" startIcon={<ExitToAppIcon/>} onClick={() => this.handleLogout('LandingPage')} style={styles.actionButton}>
     
      <Typography style={{...styles.actionButtonContent,textTransform:"none"}}>Log out</Typography>
      </Button>    
     </Box>
     </Box> 
    </Popover>
</Box>
  }
LeftSection(){
  return    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' , justifyContent:  'space-between'   } }}>

  {/* <Divider orientation="vertical" flexItem /> */}
  <Box className='menu_item_wrapper'
   style={styles.boxStyle}>
    {this.state.menuItems.map((item: any, index: number) => (
      <Typography
        key={item.label}
        variant="h6"
        aria-describedby={`popover-${item.label}`}
        onMouseEnter={
          item.options
            ? event => {
                this.handlePopoverOpen(event);
                event.currentTarget.style.color = '#206FC4'
              }
            : undefined
        }
        onMouseLeave={
          item.options ? event => {
              this.handlePopoverClose();
              event.currentTarget.style.color = '#4A4A4A'; // Reset color on mouse leave
            }
            : undefined
      }
        onClick={
          item.options
            ? (e)=>this.handleClick(e)
            : () => this.handleRedirection(item.componentName) 
        }
        data-test-id='clickhandleRedirection'
        className='menu_item'
        data-testid={`navbar-option-${index}`}
      >
        {item.label}
        {item.options &&
          (Boolean(this.state.anchorEl) &&
          this.state.anchorEl &&
          this.state.anchorEl.getAttribute('aria-describedby') ===
            `popover-${item.label}` ? (
            <ExpandLessIcon />
          ) : (
            <ExpandMoreIcon />
          ))}
        {item.options && (
          <Popper
            key={`popover-${item.label}`}
            id={`popover-${item.label}`}
            open={
              Boolean(this.state.anchorEl) &&
              this.state.anchorEl &&
              this.state.anchorEl.getAttribute(
                'aria-describedby'
              ) === `popover-${item.label}`
            }
            anchorEl={this.state.anchorEl}
            placement="bottom"
            disablePortal={true}
            modifiers={{
              flip: {
                enabled: true
              },
              preventOverflow: {
                enabled: true,
                boundariesElement: 'scrollParent'
              },
              arrow: {
                enabled: true,
                element: this.state.anchorEl
              }
            }}
            style={{
              background: '#FFF',
              boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              zIndex: 1,
              padding: '10px 5px',
              width: 'inherit'
            }}
          >
            {item.options.map((option: any, index: number) => (
              <StyledMenuItem
                key={option.label}
                onClick={() =>
                  this.handleRedirection(option.componentName)
                }
                style={styles.options}
                data-testid={`menu-option-${index}`}
              >
                {option.label}
              </StyledMenuItem>
            ))}
          </Popper>
        )}
      </Typography>
    ))}
  </Box>
  </Box>
}
  render(): ReactElement {
    const open = Boolean(this.state.profileAnchorEl);
    return (
      <div style={styles.root}>
        <AppBar position="static">
          <Customheader_design>
            <Box className='middle_section'>
            <img src={Horizontalinventohub} style={{width:"249px",height:"48px",objectFit:"cover", marginRight:"23px"}} onClick={() => this.handleRedirection('FreelancerHomePage')} data-test-id='FreelancerHomePage'/>
          {this.LeftSection()}
          {this.RightSection(open)}
          </Box>
         {this.StyledMenuItemFun()}
          </Customheader_design>
        </AppBar>
        <div>
    
      <Dialog
     
        open={this.state.isTokenExpired}
        onClose={()=>this.handleLogout('LandingPage')}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Session expired"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Please log in again as your session has ended due to inactivity.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button  data-testid='handleCloseDialog' onClick={()=>this.handleLogout('LandingPage')} color="primary" autoFocus>
           Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
      </div>
    );
  }
}

export default FreelancerNavBar;
// Customizable Area End
// Customizable Area Start
const StyledMenuItem = withStyles(() => ({
  root: {
    color: '#000',
    fontSize: '12px',
    fontWeight: 500,
    width: 'inherit',
    height: '32px',
    '&:focus': {
      backgroundColor: 'aliceblue',
      borderLeft: '2px solid blue',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: 'white'
      }
    },
    '&:hover': {
      backgroundColor: 'aliceblue',
      borderLeft: '2px solid blue'
    }
  }
}))(MenuItem);
const styles = {
  root: {},
  iconButton: {
    height: '0',
},
menuIcon: {
  marginLeft: 'auto',  // Pushes the icon to the right
  [theme.breakpoints.down('sm')]: {
      marginLeft: '20px',  // Add space between logo and icon on small screens
  },
},
  title: {
    color: '#0A0A0A',
    fontFamily: 'poppins',
    fontSize: '26px',
    fontWeight: 275,
    lineHeight: 'normal',
    letterSpacing: '-1.244px',
    paddingRight: '1rem'
  },
  actionButton:{
    cursor:"pointer",
    justifyContent:"left",
    
  },
  actionButtonContent:{
    marginLeft:"15px",
    fontWeight: 400,
    fontFamily: 'Inter',
    fontSize:"15px",
    color:"#181818",
    
  },
  menuItem: {
    marginRight: '10px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '2rem',
    // textAlign: 'left',
    color: '#4A4A4A',
    fontSize: '20px',
    fontWeight: 500,
    fontFamily: 'Inter'
  },
  options: {
    color: '#000',
    fontSize: '12px',
    fontWeight: 500,
    width: '150px',
    height: '32px',
    '&:focus': {
      backgroundColor: 'aliceblue',
      borderLeft: '2px solid blue',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: 'white'
      }
    },
    '&:hover': {
      backgroundColor: 'aliceblue',
      borderLeft: '2px solid blue'
    }
  },


  imgAvatar: {
    cursor: 'pointer',

    '&.MuiAvatar-img': {
      borderLeft: '2px solid #E6E9EA',
      lineHeight: '5rem',
      paddingRight: '1rem',
      overflow: 'visible',
      width: '45px',
      height: '45px',

    }
  },

  searchInput: {
    width: '25rem',
    '& .MuiOutlinedInput-adornedStart': {
      height: '45px',
      borderRadius: '18px'
    }
  },

  boxStyle: {
    display: 'flex',
    alignItems: 'center',
    height: 'inherit',
    cursor: 'pointer'
  }
};
const Customheader_design = styled(Toolbar)({
    backgroundColor: ' #F2F5F7',
    height: '5rem',
    display: 'flex',
    justifyContent: 'space-between',
    padding:"8 48px",
    position:"relative",
    "@media (max-width: 1000px)": {
      padding:"8 20px",
    },
    "@media (max-width: 992px)": {
      padding:"14px 20px",
    },
    "& .middle_section":{
      display: 'flex',
      alignItems: 'center',
      height: 'inherit',
      cursor: 'pointer',
      width:"100%",
      justifyContent: 'space-between',

      "@media (max-width: 768px)": {
      width:"100%"

      },
    },


"& .menu_item_wrapper":{
      display: 'flex',
      alignItems: 'center',
      height: 'inherit',
      cursor: 'pointer',
      "@media (max-width: 768px)": {
        justifyContent:"space-between",
        width:"100%",
        marginTop:"9px"
      },


      "& .menu_item":{
        marginRight: '10px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        marginLeft: '2rem',
        color: '#4A4A4A',
        fontSize: '20px',
        fontWeight: 500,
        fontFamily: 'Inter',
        "@media (max-width: 992px)": {
          marginLeft: "unset",
        "@media (max-width: 500px)": {
        fontSize: '16px',
        },
        "@media (max-width: 428px)": {
          fontSize: '14px',
          }

         },
      },
    },




    "& .menu_item":{
      marginRight: '10px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      marginLeft: '2rem',
      color: '#4A4A4A',
      fontSize: '20px',
      fontWeight: 500,
      fontFamily: 'Inter',
      "@media (max-width: 992px)": {
        marginLeft: "unset",

 
       },
    },

  "& .right_Section":{
    display: 'flex',
    alignItems: 'center',
    flex: 0.75,
    justifyContent: 'end',
    gap: '20px',
   
    }
  });
  const Custom_Menu_design = styled(Menu)({
    "& .menu_item_wrapper":{
      display: 'flex',
      height: 'inherit',
      cursor: 'pointer',
      flexDirection:"column",
      alignItems: 'center',
      width:"200px",
      "@media (max-width: 768px)": {
        marginTop:"9px",
        justifyContent:"space-between",
      },


      "& .menu_item":{
        marginRight: '10px',
        cursor: 'pointer',
        display: 'flex',
        color: '#4A4A4A',
        fontSize: '20px',
        fontWeight: 500,
        fontFamily: 'Inter',
        marginBottom:"30px",
        alignItems: 'center',
        marginLeft: '2rem',
        "@media (max-width: 992px)": {
          marginLeft: "unset",
        "@media (max-width: 500px)": {
        fontSize: '16px',
        },
        "@media (max-width: 428px)": {
          fontSize: '12px',
          }

         },
      },
    },

  })



// Customizable Area End