import React from "react";
//Customizable Area Start
import { Box, Grid, Typography,styled,Button } from "@material-ui/core";
import { monitor, angleleft } from "../assets";
import Footer from "./Footer.web";
import DashboardController, { Props } from "../DashboardController.web";
import ClientNavBar from "./ClientNavBar.web";
const CustomButton = styled(Button)({
  background: "#364BA0",
  border: "1px solid #364BA0",
  fontSize: "16px",
  lineHeight: "19px",
  letterSpacing: "0px",
  color: "white",
  borderRadius: "10px",
  width: " 173px",
  height: " 56px",
  marginRight:"1rem",
  fontFamily:"Rubik",
  fontWeight:"bold",
  cursor:"pointer",
  marginTop: "20px",
  '&:hover': {
    width: " 173px",
    height: " 56px",
    borderRadius: "10px",
    color:"#206FC4",
    border:"1px solid #206FC4",
    backgroundColor: "#fff",
  }
})
//Customizable Area End
export default class PostJob extends DashboardController {
  //Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  //Customizable Area End
  render() {
    //Customizable Area Start
    return (
      <div>
        <ClientNavBar  />
        <div>
        <Grid style={styles.mainContainer1} container>
          <Grid item xs={6} sm={6}>
            <Typography style={styles.dashBoard as React.CSSProperties}>
              Your Dashboard
            </Typography>
            <Typography style={styles.leftTyp as React.CSSProperties}>{this.state.firstName}</Typography>
          </Grid>
          <Grid item xs={6} sm={5} style={styles.rightGrid1}>
            <CustomButton onClick={this.handleAddBillingMethod}>
              Post a Job
            </CustomButton>
          </Grid>
        </Grid>
      </div>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <div style={styles.leftBox}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography
                  component={"span"}
                  style={styles.leftHead as React.CSSProperties}
                >
                  Your Posting
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  component={"span"}
                  style={styles.rightHead as React.CSSProperties}
                >
                  See all posting
                </Typography>
              </Grid>
            </Grid>
            <div style={styles.imgBox as React.CSSProperties}>
              <Typography component={"div"} style={styles.imageWr}>
                <img src={monitor} alt="" style={styles.image} />
              </Typography>

              <Typography style={styles.rightHead}>
                No active job posts
              </Typography>

              <Typography
                style={styles.jobPostDescription as React.CSSProperties}
              >
                Lorem Ipsum is simply dummy text of the printing and
                typesetting industry.
              </Typography>
              <CustomButton
                onClick={this.handleAddBillingMethod}
              >    
                  Post a Job
              </CustomButton>
            </div>
          </div>
          <Box style={styles.lastBox as React.CSSProperties}>
              <div>
                <Typography style={styles.heading as React.CSSProperties}>
                How To Work With IP Professionals
                </Typography>
                <Typography style={styles.para as React.CSSProperties}>
                Best practices for collaborating with IP Professionals to protect and manage your innovations effectively.
                </Typography>
              </div>
              <div style={{marginTop:"35px"}}>
                <Typography style={styles.heading as React.CSSProperties}>
                  1. Post a job to the marketplace
                </Typography>

                <Typography style={styles.para as React.CSSProperties}>
                 Provide enough detail for great IP Professionals to figure out if the work is right for them.
                 (You can always edit your post, or send an invite to reach out to people directly.)
                </Typography>
              </div>
              <div style={{marginTop:"35px"}}>
                <Typography style={styles.heading as React.CSSProperties}>
                  2. Get Proposals from talent
                </Typography>
                <Typography style={styles.para as React.CSSProperties}>
                A strong working relationship starts with open communications. Here’s your chance to ask about experience
                set expectations for what you need, and discuss terms of the work.
                </Typography>
              </div>
              <div style={{marginTop:"35px"}}>
                <Typography style={styles.heading as React.CSSProperties}>
                  3. Start working together
                </Typography>
                <Typography style={styles.para as React.CSSProperties}>
                Once you both agree on terms, collaborate with simple and secure tools like chat, file sharing.
                </Typography>
              </div>
              <div style={{marginTop:"35px"}}>
                <Typography style={styles.heading as React.CSSProperties}>
                  4. Pay for work you approve
                </Typography>
                <Typography style={styles.para as React.CSSProperties}>
                Active Contracts are useful for keeping track of payments and reviewing work. As you complete jobs, you can build
                trusting relationships with IP Professionals in a way that helps you both grow.
                </Typography>
              </div>
            </Box>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Grid container direction="column" style={styles.thirdBox}>
            <div style={styles.rightGrid as React.CSSProperties}>
              <Grid item style={styles.exploMoreGrid}>
                <div>
                  <div style={styles.gridBoxone}>
                    <Typography
                      style={
                        styles.exploreMoreHeading as React.CSSProperties
                      }
                    >
                      Explore More Talent
                    </Typography>
                    <Typography>
                      <img
                        src={angleleft}
                        alt=""
                        style={{ width: "1rem" }}
                        onClick={this.handleRedirect}
                      />
                    </Typography>
                   
                  </div>
                  <Typography
                    style={
                      styles.exploreMoreTxt as React.CSSProperties
                    }>
                    Discover and connect with top talent to find the perfect match for your project needs.
                    </Typography>
                </div>
              </Grid>
              <Grid item style={styles.exploMoreGrid}>
                <div style={styles.gridBoxTwo}>
                  <div>
                    <Typography
                      style={
                        styles.exploreMoreHeading as React.CSSProperties
                      }
                    >
                      Add Billing Method
                    </Typography>
                  </div>
                  <div>
                    <img
                      src={angleleft}
                      alt=""
                      style={{ width: "1rem" }}
                    />
                  </div>
                </div>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
          
        <Footer />
      </div>
      //Customizable Area End
    );
  }
}

//Customizable Area Start


const styles = {
  mainContainer: {},
  leftBox: {
    margin: "16px 30px 30px 51px",
    background: "#FAFBFC",
    padding:"29px 19px",
    borderRadius:"10px"
  },
  leftHead: {
    fontFamily: "Rubik",
    fontSize: "22px",
    fontWeight: 400,
  },
  rightHead: {
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: 400,
  },
  imageWr: {
    marginBottom: "10px",
  },
  image: {
    maxWidth: "100%",
  },
  imgBox: {
    textAlign: "center",
  },
  heading: {
    fontFamily: "Rubik",
    fontSize: "22px",
    lineHeight: "27px",
    letterSpacing: "0px",
  },
  para: {
    fontFamily: "Rubik",
    fontSize: 14,
    fontWeight: 400,
    color: "#767677",
    marginTop:"15px"
  },
  postBtn: {
    width: "173px",
    height: "56px",
    background: "#206FC4",
    color: "#FFFFFF",
    marginTop: "20px",
    fontSize: "16px",
    fontFamily: "Rubik",
    fontWeight: 700,
    lineHeight: "18.75px",
    borderRadius: "10px",
    cursor: "pointer",
  },
  exploreHead: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "19px",
    letterSpacing: "0px",
  },
  exploreSubH: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0px",
    textAlign: "left",
  },

  thirdBox: {
    background: "#FAFBFC",
   
  },
  jobPostDescription: {
    marginTop: "13px",
    color: "#767677",
    fontSize: "14px",
    lineHeight: "17px",
    fontFamily: "Rubik",
  },
  lastBox: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    background:"#FAFBFC",
    borderRadius:"10px",
    marginTop:"30px",
    marginLeft:"50px",
    padding:"29px 19px"
  },
  exploMoreGrid: {
    height: " 101px",
  },
  gridBoxone: {
    display: "flex",
    justifyContent: "space-between",
  },
  gridBoxTwo: {
    display: "flex",
    justifyContent: "space-between",
  },
  exploreMoreHeading: {
    fontFamily: "Rubik",
    fontSize: "16px",
    color: "#000000",
  },
  exploreMoreTxt: {
    fontFamily: "Rubik",
    fontSize: "14px",
    color: "#767677",
    maxWidth:"400px"
  },
  rightGrid: {
    display: "flex",
    marginTop: "20px",
    justifyContent: "flex-end",
    flexDirection: "column",
    marginRight:"50px"
  },
  imgStyle: {
    Width: "1rem",
  },
  leftTyp: {
    fontFamily:"Rubik",
    fontSize: "20px",
    fontWeight: 400,
    marginTop:"17px"
  },

  btn: {
    background: "#206FC4",
    fontSize: "16px",
    lineHeight: "19px",
    letterSpacing: "0px",
    color: "white",
    borderRadius: "10px",
    width: " 173px",
    height: " 56px",
    marginRight:"1rem",
    fontFamily:"Rubik",
    fontWeight:"bold",
    cursor:"pointer"
  },
  mainContainer1: {
    margin: "0 auto",
    padding:"52px 52px 0px 52px"
  },
  dashBoard: {
    fontFamily: "Rubik",
    fontSize: "32px",
    fontWeight: 400,
    lineHeight: "27px",
    letterSpacing: "0px",
  },
  rightGrid1: {
    display: "flex",
    justifyContent: "flex-end",
  },
  //Customizable Area End
};
