//Customizable Area Start

//Customizable Area Start
import React from "react";
import {Grid, styled,Typography, Box, Select, IconButton, MenuItem, OutlinedInput, FormControl} from "@material-ui/core";
import LocationController from "./LocationController.web";
import { ellipse } from "./Components/assets";
import CloseIcon from '@material-ui/icons/Close';

const CustomTypography = styled(Typography)({
  fontFamily:"'Inter', sans-serif",
  fontSize: "16px",
  fontWeight: "bold",
  color: "#FFFFFF",
  padding: "15px",
  textDecoration: "none",
  cursor:"pointer",
  background: '#364BA0',
  borderRadius: 10,
  textAlign:"center",
  width:"100%",
  maxWidth: "161px",
  maxHeight: "55px",
  '&:hover': {
    color:"#364BA0",
    border:"1px solid #364BA0",
    backgroundColor: "#fff",
  }
})

const CustomLocationGrid = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  width: "90%",
  marginTop: "40px",
  marginLeft: "80px",
  marginBottom: "100px",
  padding: "19px",
"@media (max-width: 960px)": {
  display: "flex",
  justifyContent: "center",
  margin:"auto",
  width:"100%",
  padding: "19px"
},
})
export default class Location extends LocationController {
  constructor(props: any) {
    super(props);
  }
  //Customizable Area End
  render() {
    //Customizable Area Start
    
    return (
      <><div style={styles.root as React.CSSProperties}>
        <CustomLocationGrid container spacing={3} >
          <Grid item xs={12} md={6}>
            <Typography style={styles.skillsHeading}>
            Location
            </Typography>
            <div style={styles.skillsTextDiv}>
              <Typography style={styles.skillsText}>
              You can post a job either worldwide or limit it to a specific country and multiple cities. However, if you want to post a job across multiple countries, you will need to create separate job postings for each country.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6} style={styles.box2}>
                <Grid item xs={12}>
                  <Box marginBottom={"5%"} marginTop={"2%"}>
                  <InputField style={styles.textField} variant="outlined">
                      <Select
                      data-testid="country"
                        defaultValue=""
                        displayEmpty
                        name="country"
                        value={this.state.country}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          transformOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null
                        }}
                        input={
                          <OutlinedInput
                            style={{
                              borderRadius: "12px", 
                              color: this.state.country ? 'black' : '#979797' 
                            }}
                            placeholder="Select Country" 
                          />
                        }
                        
                        onChange={(event) => this.handleCountryChange(event)}   
                        data-test-id="counter">
                        <MenuItem value="" disabled style={{ color: '#979797' }}>
                          Select Country
                        </MenuItem>
                        {this.state.countryList && this.state.countryList.map((country) => (
                          <SelectManu_style key={country.id} value={country.name} >
                            {country.name}
                          </SelectManu_style>
                        ))}
                      </Select>
                    </InputField>
                    {this.state.countryError && <Typography style={{ color: 'red', marginTop: '4px' }}>{this.state.countryError}</Typography>}
                    </Box>

                    <Grid item xs={12}>
                      <Box>
                      <InputField style={styles.textField} variant="outlined">
                    <Select
                     data-testid="state"
                      multiple
                      displayEmpty
                      name="state"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }}
                      value={this.state.state}
                      open={this.state.dropdownOpen}
                      onOpen={this.handleDropdownOpen}
                      onClose={this.handleDropdownClose}
                      onChange={(event) => this.handleStateChange(event)}
                      data-test-id="counterstate"
                      style={styles.borderRadius}
                      renderValue={(selected) => {
                        const selectedIds = selected as number[];
                        return selectedIds.length > 0 ? (
                          selectedIds
                            .map((id) => this.state.stateList.find((state) => state.id === id)?.name)
                            .filter((name): name is string => name !== undefined)
                            .join(', ')
                        ) : (
                          <span style={{ color: '#979797' }}>Select States</span> // Placeholder color
                        );
                      }}
                    >
                      <MenuItem value="" disabled>
                      Select States
                      </MenuItem>
                      {this.state.stateList.map((state) => (
                        <SelectManu_style key={state.id} value={state.id}>
                          {state.name}
                        </SelectManu_style>
                      ))}
                    </Select>
                  </InputField>
                  <Box display="flex" flexWrap="wrap">
                    {this.state.citiesArray.map((city, index) => (
                      <Box key={index} style={{ margin: '12px 10px 10px 10px' }}>
                        <Box style={styles.chipStyle}>
                          <Typography style={styles.skillStyle}>{city}</Typography>
                          <IconButton
                            size="small"
                            onClick={() => this.handleDeleteState(city)}
                            data-test-id="deletedata"
                          >
                            <CloseIcon style={styles.iconStyle} />
                          </IconButton>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  {this.state.stateError && <Typography style={{ color: 'red', marginTop: '4px' }}>{this.state.stateError}</Typography>}
                </Box>
                </Grid>

                </Grid>
                
          </Grid>
        </CustomLocationGrid>
        {/* Lower design */}
        <img src={ellipse} alt="ellipse" style={styles.ellipseImg} />
      </div>
      <Box style={styles.lowerSectionDiv as React.CSSProperties} >
         <Box style={styles.lowerSectionDiv2}>
            <Typography
              style={{
                ...styles.lowerSectionItem1Text,
                ...(this.state.isHovered && styles.lowerSectionItem1TextHovered),
              }}
              onClick={() => this.handleBack()}
              onMouseEnter={this.handleHover}
              onMouseLeave={this.handleLeave}
              data-test-id="backbtn">
              BACK
            </Typography>
            <CustomTypography onClick={() => this.handleContinue()} data-test-id="contbtn">CONTINUE</CustomTypography>
            </Box>
        </Box>
        </>
    );
    //Customizable Area End
  }
}
//Customizable Area Start
const InputField = styled(FormControl)({
  "@media(max-width:500px)": {
    "&  .MuiOutlinedInput-input" :{
        padding: "11.5px 12px",
      }
    },

    "& .MuiListItem-button": {
      "& :hover":{
        textDecoration: "none",
        backgroundolor: "red",

      }
    }
});
const SelectManu_style = styled(MenuItem)({

  "&:hover": {
    backgroundColor: "364BA0",
    color:"white",
  },
});
const styles = {
  root: {
    flexGrow: 1,
    maxWidth: "1440px",
    margin: "0 auto",
  },
  autoCompleteField:{
    width:500,
    fontWeight:700,

    "& .MuiAutocomplete-input":{
      fontWeight: 700
    }
  },
  heading: {
    fontSize: "36px",
    fontWeight: 400,
    color: "#000000",
    marginTop: "40px",
    marginLeft: "20px",
    fontFamily: "'Inter', sans-serif",
  },
  boxMain: {
    display: "flex",
    justifyContent: "center",
    width: "90%",
    marginTop: "40px",
    marginLeft: "80px",
    marginBottom: "100px",
  },

  box2: {
    width:"100%"
  },
  skillsHeading: {
    fontSize: "38px",
    fontWeight: 600,
    fontFamily: "'Inter', sans-serif",
    color: "#000000",
  },
  skillsTextDiv: {
    marginTop: "10px",
    marginRight:"21%"
  },
  skillsText: {
    fontSize: "14px",
    color: "#5C5C5C",
    fontFamily: "'Inter', sans-serif",
    lineHeight: "30px",
  },
  searchSkillsTextDiv: {
    marginTop: "11px",
  },
  searchSkillsText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "20px",
    color: "#535353",
    lineHeight: "30px",
    marginBottom: "5px",
    fontWeight: "700"
  },
  searchTextFiled: {
    maxWidth: "500px",
    maxHeight: "56px",
    borderRadius: "10px",
    marginTop: "5px",

    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
    },
    "& .MuiOutlinedInput-input": {
      width: "432px",
    },
  },

  outlinedInput: {
    borderRadius: "10px",
    "&::placeholder": {
      color: "#000000",
      fontSize: "18px",
      lineHeight: "30px",
    },
  },
  examplesDiv1: {
    marginTop: "30px",
  },
  examplesText: {
    fontSize: "20px",
    color: "#535353",
    lineHeight: "30px",
    fontFamily: "'Inter', sans-serif",
    fontWeight: 700
  },

  examplesDiv2: {
    marginTop: "5px",
    display: "contents",
    flexWrap: "wrap",
  },

  examplesItems: {
    backgroundColor: "#D2E2F3",
    borderRadius: "23px",
    marginTop: "10px",
    marginRight: "15px",
    fontSize: "14px",
    color: "#364BA0",
    border: "none",
    padding: "5px",
    fontFamily: "'Inter', sans-serif",
  },

  lowerSectionDivMain: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    marginBottom: "20px",
    alignItems: "center",
  },
  lowerSectionDiv: {
    display: 'flex',
    justifyContent: 'space-between', 
    alignItems: 'center', 
    position: 'absolute', 
    bottom: 20, 
    left: 0, 
    width: '100%'
  },
  lowerSectionDiv2:{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingInline: 20,
    width: '100%'
  },
  lowerSectionItem1Text: {
    fontSize: "16px",
    color: "#535353",
    lineHeight: "27px",
    cursor: "pointer" as "pointer",
    fontFamily: "'Inter', sans-serif" as "'Inter', sans-serif",
    fontWeight: "bold" as "bold",
    position:'relative' as 'relative',
    zIndex : 1
  },
  lowerSectionItem1TextHovered:{
    color :""
  },

  lowerSectionBtn: {
    maxWidth: "161px",
    maxHeight: "55px",
    backgroundColor: "#364BA0",
    borderRadius: "10px",
  },
  btnText: {
    fontSize: "16px",
    fontWeight:"bold",
    color: "#FFFFFF",
    padding: "15px",
    fontFamily: "'Inter', sans-serif",
    cursor:"pointer",
  },
  ellipseImg:{
    position:'absolute' as 'absolute',
    bottom:'0',
    left:'0',
    width:'370px',
    zIndex: 0
  },
  labelStyle: {
    fontFamily: "Rubik",
    fontSize: "16px",
    color: "#0A0A0A",
    fontWeight: 500,
    marginBottom: "8px"
  },
  textField: {
    borderRadius:"12px",
    width: "100%",
    "&.MuiFormLabel-root.Mui-focused ": {
      color: "#6F6E6E !important",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline ": {
      borderColor: "#6F6E6E !important",
      borderRadius: "12px !important"
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px !important"
    }
  },
  borderRadius:{
    borderRadius:"12px"
  },

  chipStyle: {
    background: "#364BA0",
    borderRadius: "26px",
    display: "flex",
    padding: "8px",
    gap: "6px",
    justifyContent: "center",
  },
  iconStyle: {
    color: '#FFF',
    fontSize: '20px',
  },
  CountryStyle: {
    color: '#0A0A0A',
    padding: "2px 8px"
  },
  skillStyle: {
    color: '#FFF',
    padding: "2px 8px"
  },
 
};
//Customizable Area End
  //Customizable Area End

