// Customizable Area Start
import React from 'react';
import { Box, ThemeProvider, Typography, createTheme } from '@material-ui/core';
import PrivacyAndPolicyContentManagementController, {
  Props
} from './PrivacyAndPolicyContentManagementController.web';
import FooterWeb from '../../dashboard/src/Components/Footer.web';
import { getNavBar } from './About.web';
const theme = createTheme({
  palette: {
    primary: {
      main: '#206FC4'
    }
  },
  typography: {
    fontFamily: 'Rubik'
  }
});

// Customizable Area End
// Customizable Area Start
export default class PrivacyAndPolicyContentManagement extends PrivacyAndPolicyContentManagementController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      //Merge Engine DefaultContainerj
      <ThemeProvider theme={theme}>
     {getNavBar({userType:this.state.userType,...this.props})}
        <Box display={'grid'} style={{ padding: '5% 8%' }}>
          <Box>
            <Typography
             style={webStyle.text}
            >
              Privacy Policy
            </Typography>
          </Box>
          <Box>
            <Typography
              style={{ fontFamily: 'Rubik', fontSize: '18px', fontWeight: 400 }}
              dangerouslySetInnerHTML={{
                __html: this.state.PrivacyAndPolicyContentManagementRes
                  .description
              }}
            />
          </Box>
        </Box>
        <FooterWeb />
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const webStyle = {
  text: {
    fontFamily: 'Inter',
    fontSize: '35px',
    fontWeight: 600,
    marginBottom: '2%'
  }
};

// Customizable Area End
