import { IBlock } from 'framework/src/IBlock';
import { Message } from 'framework/src/Message';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
import MessageEnum, { getName } from 'framework/src/Messages/MessageEnum';
// Customizable Area Start
import storage from 'framework/src/StorageProvider';

// Customizable Area End
export const webConfigJSON = require('./config.js');

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  authToken: string;
  favJobsList:any[]
  removedIDS:any[]
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class FreelancerSavedJobController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getFavouritesJobId:string;
  removeJobFromFavouriteId:string;
  savedJobID:string;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.getFavouritesJobId=""
    this.removeJobFromFavouriteId=''
    this.savedJobID=''

    this.state = {
      authToken: "",
      favJobsList:[],
      removedIDS:[],
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.setState({
      authToken: await storage.get('authToken')
    });
     this.fetchShowFavourites()

    // Customizable Area End
  }
  // Customizable Area Start

  handleSendProposalClick=async(jobId:any) => {
    await storage.set("currentProposalJobId",jobId)
    this.props.navigation.navigate('SendProposals')  
}
  removeJobFromFavourite = (id:number) => {
    const removeJobFromFavouriteRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      'Content-Type': webConfigJSON.dashboarContentType,
      token: this.state.authToken
    };

    this.removeJobFromFavouriteId = removeJobFromFavouriteRequest.messageId;
    removeJobFromFavouriteRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.removeJobFromFavouriteRequestEndPoint +`${id}`
    );
    removeJobFromFavouriteRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    removeJobFromFavouriteRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.httpDeleteMethod
    );

    runEngine.sendMessage(
      removeJobFromFavouriteRequest.id,
      removeJobFromFavouriteRequest
    );
  };

  fetchShowFavourites = () => {
    
    const showFavouritesRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      'Content-Type': webConfigJSON.dashboarContentType,
      "token": this.state.authToken
     
    };
    

    this.getFavouritesJobId = showFavouritesRequest.messageId;
    showFavouritesRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.showFavouritesRequestEndPoint 
    );
    showFavouritesRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    showFavouritesRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(showFavouritesRequest.id, showFavouritesRequest);
  };
  // Customizable Area End

  async receive(_from: string, _message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === _message.id) {
      const requestCallId = _message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const errorBlock = ()=>{
        const errorMessage = _message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorMessage);
      }
     
      if (this.getFavouritesJobId === requestCallId) {
        const responseJson = _message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (responseJson.data) {
          this.setState({
            favJobsList: responseJson.data.map(
              (item: {
                id: any;
                attributes: {
                  job: { data: { attributes: { [key: string]: any } } };
                };
              }) => {
                return { ...item.attributes.job.data, favId: item.id };
              }
            )
          });
        } else {
          errorBlock();
        }

      }
    }
    // Customizable Area End
  }
}
