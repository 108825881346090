//Customizable Area Start
import React from "react";
//Customizable Area Start
import { Box, Button, FormControl, Tabs, Typography, styled } from "@material-ui/core";
import ProjectDetailsController, { Props } from "./ProjectDetailsController.web";
export const webConfigJSON = require("./config");



const CustomTabs = styled(Tabs)({
    '& .MuiTab-textColorPrimary-25.Mui-selected': {
        fontWeight: "600",
        fontSize: "22px",
        textTransform: "inherit"
    },
    '& .MuiTab-textColorPrimary-25': {
        fontWeight: "600",
        fontSize: "22px",
        textTransform: "inherit"
    },
    '& .PrivateTabIndicator-root-31': {
        height: "4px"
    }

})
// Customizable Area End

export default class ProjectDetails extends ProjectDetailsController {
    constructor(props: Props) {
        super(props);
        //Customizable Area Start
        // Customizable Area End
    }

    //Customizable Area Start



    // Customizable Area End

    render() {
        //Customizable Area Start

        return (
            <Box marginTop={"3%"} marginRight={"6%"}>
                <Box display={"flex"} border={"1px solid #ECECEC"} borderRadius={"10px"} flexDirection={"column"} padding={"18px 30px"}>
                    <Box>
                        <Box>
                            <Typography style={{...webStyle.header,color:"#303030"}}>Freelance IP Lawyer Needed</Typography>
                        </Box>
                        <Box display={"flex"} borderBottom="1px solid #ECECEC" width="calc(100% + 60px)" marginLeft="-30px" marginRight="-30px" >
                            <Box display={"flex"} gridColumnGap={"6rem"} marginTop={"2%"} paddingLeft={"30px"}>
                                <Box display={"grid"} gridRowGap={"10px"} marginBottom={"25px"}>
                                    <Typography style={webStyle.subHeader}>IP Client :</Typography>
                                    <Typography style={webStyle.subHeader}>Duration :</Typography>
                                    <Typography style={webStyle.subHeader}>Charge :</Typography>
                                    <Typography style={webStyle.subHeader}>Payment :</Typography>

                                </Box>
                                <Box display={"grid"} gridRowGap={"10px"} marginBottom={"25px"}>
                                    <Typography style={{...webStyle.text,fontSize:"16px",color:"#545454"}}>Rahul Sharma</Typography>
                                    <Typography style={{...webStyle.text,fontSize:"16px",color:"#545454"}}>1 Month</Typography>
                                    <Typography style={{...webStyle.text,fontSize:"16px",color:"#545454"}}>$ 500.00</Typography>
                                    <Typography style={{...webStyle.text,fontSize:"16px",color:"#545454"}}>By Milestones</Typography>
                                </Box>
                            </Box>
                        </Box>

                    </Box>
                    <Box borderTop="1px solid #ECECEC" width="calc(100% + 60px)" marginLeft="-30px" marginRight="-30px" marginTop={"18px"}>
                        <Box paddingLeft={"30px"}>
                            <Box>
                                <Typography style={{ ...webStyle.header, marginTop: "-4px", marginBottom: "1%" }}>Project Details</Typography>
                            </Box>
                            <Box display={"flex"}>
                                <Box>
                                    <Typography style={{ ...webStyle.text, paddingRight: "10%",color:"#0A0A0A" }}>We are seeking an experienced freelance Intellectual Property (IP) lawyer to assist with a variety of IP-related tasks. The ideal candidate will have a strong background in IP law and a proven track record of handling cases related to trademarks, copyrights, patents, and trade secrets.</Typography>
                                    <Typography style={{...webStyle.text,color:"#0A0A0A" }}>Responsibilities:</Typography>
                                    <ul style={{ ...webStyle.text, marginLeft: "-23px", marginTop: "2px",color:"#0A0A0A" }}>
                                        <Box display={"grid"} gridRowGap={"5px"}>
                                            {this.state.services.map((service: any, index: number) => (
                                                <li key={index}>{service}</li>
                                            ))}
                                        </Box>
                                    </ul>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box borderTop="1px solid #ECECEC" width="calc(100% + 60px)" marginLeft="-30px" marginRight="-30px" marginTop={"18px"}>
                        <Box marginTop={"2%"} paddingLeft={"30px"} display={"grid"}>
                            <Box>
                                <Typography style={{...webStyle.header,color:"#303030"}}>Milestone details</Typography>
                            </Box>
                            {this.state.milestones.map((milestones: any, index: number) =>
                                <Box display={"flex"} marginTop={"3%"}>
                                    <Box display={"flex"} gridColumnGap={"42px"}>

                                        <Box display={"grid"} marginLeft={"1%"} width={"250px"} gridRowGap={"15px"}>
                                            <Box>
                                                <Typography style={{ ...webStyle.text, fontWeight: 500,color:"#000000" }}>Milestone Description</Typography>
                                            </Box>
                                            <Box display={"flex"} alignItems={"center"}>
                                                <Box marginLeft={"-20px"}>
                                                    <Typography style={{ ...webStyle.text, fontWeight: 500,color:"#515354" }}>{index + 1}</Typography>
                                                </Box>
                                                <Box marginLeft={"10px"}>
                                                    <Box
                                                        style={{
                                                            ...webStyle.textField,
                                                            borderRadius: "12px",
                                                            cursor: "not-allowed",
                                                            width: "240px"
                                                        }}

                                                    >
                                                        <Typography style={{fontWeight:600, color: "#515354" }}>
                                                            {milestones.milestoneDescription}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>

                                        <Box display={"grid"} width={"170px"} gridRowGap={"15px"}>
                                            <Box>
                                                <Typography style={{ ...webStyle.text, fontWeight: 500,color:"#000000" }}>Budget</Typography>
                                            </Box>
                                            <Box display={"flex"}>
                                                <Box
                                                    style={{
                                                        ...webStyle.textField,
                                                        borderRadius: "12px",
                                                        cursor: "not-allowed",
                                                        width: "170px"
                                                    }}

                                                >
                                                    <Typography style={{fontWeight:600, color: "#515354" }}>
                                                        {milestones.budget}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box display={"grid"} width={"170px"} gridRowGap={"15px"}>
                                            <Box>
                                                <Typography style={{ ...webStyle.text, fontWeight: 500,color:"#000000" }}>Date</Typography>
                                            </Box>
                                            <Box display={"flex"}>
                                                <Box
                                                    style={{
                                                        ...webStyle.textField,
                                                        borderRadius: "12px",
                                                        cursor: "not-allowed",
                                                        width: "170px"
                                                    }}

                                                >
                                                    <Typography style={{fontWeight:600, color: "#515354" }}>
                                                        {milestones.date}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box display={"grid"} width={"170px"} gridRowGap={"15px"}>
                                            <Box>
                                                <Typography style={{ ...webStyle.text, fontWeight: 500,color:"#000000" }}>Status</Typography>
                                            </Box>
                                            <Box display={"flex"}>
                                                <Box
                                                    style={{
                                                        ...webStyle.textField,
                                                        borderRadius: "12px",
                                                        cursor: "not-allowed",
                                                        width: "170px"
                                                    }}

                                                >
                                                    <Typography style={{fontWeight:600, color: milestones.status === "Approved" ? "#348306" : milestones.status === "Ongoing" ? "#364BA0" : "#515354" }} >
                                                        {milestones.status}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box display={"grid"} width={"170px"} gridRowGap={"15px"}>
                                            <Box>
                                                <Typography style={{ ...webStyle.text, fontWeight: 500,color:"#000000" }}>Active Disputes</Typography>
                                            </Box>
                                            <Box display={"flex"}>
                                                <Box
                                                    style={{
                                                        ...webStyle.textField,
                                                        borderRadius: "12px",
                                                        cursor: "not-allowed",
                                                        width: "170px"
                                                    }}

                                                >
                                                    <Typography style={{fontWeight:600, color: "#515354" }}>
                                                        {milestones.activeDisputes}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box display={"grid"} width={"170px"} gridRowGap={"15px"}>
                                            <Box>
                                                <Typography style={{ ...webStyle.text, fontWeight: 500,color:"#000000" }}>Total Disputes</Typography>
                                            </Box>
                                            <Box display={"flex"}>
                                                <Box
                                                    style={{
                                                        ...webStyle.textField,
                                                        borderRadius: "12px",
                                                        cursor: "not-allowed",
                                                        width: "170px"
                                                    }}

                                                >
                                                    <Typography style={{fontWeight:600, color: "#515354" }}>
                                                        {milestones.totalDisputes}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                        </Box>

                    </Box>


                </Box>
                <Box display={"flex"} justifyContent={"space-between"} marginTop={"3%"}>
                    <Box display={"flex"} gridColumnGap={"2rem"}>
                        <Box>
                            <Button variant="contained" style={{ ...webStyle.button, backgroundColor: "#364BA0",color:"#FFF",borderRadius: "10px" }}>
                                raise dispute
                            </Button>
                        </Box>
                        <Box>
                            <Button variant="contained" style={{ ...webStyle.button,color: "#364BA0", backgroundColor: "#FFF",border: "1px solid #364BA0", borderRadius: "10px"}}>
                                All Disputes
                            </Button>
                        </Box>
                    </Box>
                    <Box>
                        <Box>
                            <Button variant="contained" style={{ ...webStyle.button, color: "#FFF", backgroundColor: "#D60000",borderRadius: "10px" }}>
                                end contract
                            </Button>
                        </Box>
                    </Box>
                </Box>

            </Box>
        );
        //Customizable Area End
    }
}

//Customizable Area Start
const InputField = styled(FormControl)({
    "@media(max-width:500px)": {
        "&  .MuiOutlinedInput-input": {
            padding: "11.5px 12px",
        }
    },

    "& .MuiListItem-button": {
        "& :hover": {
            textDecoration: "none",
            backgroundolor: "red",

        }
    }
});

const webStyle = {
    textField: {
        border: "1px solid #ECECEC",
        padding: "10px 16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        backgroundColor: "#fff",
        cursor: "not-allowed",
        height: "35px"
    },
    borderRadius: {
        borderRadius: "12px"
    },
    header: {
        fontFamily: "Rubik",
        fontSize: "24px",
        color: "#3F3F3F",
        fontWeight: 500
    },
    subHeader: {
        fontFamily: "Rubik",
        fontSize: "16px",
        color: "#3F3F3F",
        fontWeight: 500
    },
    text: {
        fontFamily: "Rubik",
        fontSize: "15px",
        color: "#3F3F3F",
    },
    button: {
        width: "200px",
        height: "50px",
        fontFamily: "Rubik",
        fontWeight: 450

    }
}

//Customizable Area End
// Customizable Area End
