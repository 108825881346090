import React from 'react';
// Customizable Area Start
import { Box, Button, Grid, Link } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Profile from './Profile.web';
import FooterWeb from '../../dashboard/src/Components/Footer.web';
import ClientNavBar from '../../dashboard/src/Components/ClientNavBar.web';
import FreelancerPasswordSetting from './FreelancerPasswordSetting.web';

const theme = createTheme({
  palette: {
    primary: {
      main: '#206FC4'
    },
    secondary: {
      main: 'rgb(96, 96, 96)'
    }
  },
  typography: {
    fontFamily: 'Inter'
  }
});
// Customizable Area End
import SettingsController, { Props, configJSON } from './SettingsController';
import BillsAndPayment from '../../dashboard/src/BillsAndPayment.web';

export default class Settings extends SettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    console.log('this.state', this.state);
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <>
        <ThemeProvider theme={theme}>
          <ClientNavBar />
          <Grid container style={webStyles.profileContainer}>
            <Grid item md={3} sm={3}>
              <Box style={webStyles.linkBox}>
                <Link
                  href="#"
                  data-testid="bills-link"
                  underline="none"
                  style={webStyles.billLink}
                  onClick={() => this.handleClick('bills')}
                  color={
                    this.state.storeData === 'bills' ? 'primary' : 'secondary'
                  }
                >
                  {configJSON.bills}
                </Link>
                <Link
                  href="#"
                  data-testid="profile-link"
                  underline="none"
                  color={
                    this.state.storeData === 'profile' ? 'primary' : 'secondary'
                  }
                  style={webStyles.linkText}
                  onClick={() => this.handleClick('profile')}
                >
                  {configJSON.profile}
                </Link>
                <Link
                  href="#"
                  data-testid="password-link"
                  underline="none"
                  style={webStyles.linkText}
                  onClick={() => this.handleClick('passwordSecurity')}
                  color={
                    this.state.storeData === 'passwordSecurity'
                      ? 'primary'
                      : 'secondary'
                  }
                >
                  {configJSON.pswSecurity}
                </Link>
              </Box>
              <Box display={'grid'} style={{ gap: '15px' }}>
                {/* <Button
                  variant="outlined"
                  color="primary"
                  style={webStyles.accountBtn}
                   data-test-id='handleRedirection'
                  onClick={() => this.handleRedirection('FreelancerHomePage')}
                >
                  {configJSON.accountSwitch}
                </Button> */}
                <Button
                variant="contained"
                style={{
                  color: '#F00',
                  backgroundColor: '#FFF',
                  border: '1px solid #F00 ',
                  borderRadius: '8px',
                  height: '38px',
                  width: '100%',
                  marginTop: '35px'
                }}
                data-test-id='handleLogout2'
                onClick={()=>this.handleLogout("LandingPage")}
                >
                <span style={webStyles.buttonText}>LogOut</span>
                </Button>
              </Box>
            </Grid>
            <Grid item md={8} sm={8}>
              {this.state.storeData === 'bills' && (
                <BillsAndPayment {...this.props} data-testid="bill-component" />
              )}
              {this.state.storeData === 'profile' && (
                <Profile {...this.props} data-testid="profile-component" />
              )}
              {this.state.storeData === 'passwordSecurity' && (
                <FreelancerPasswordSetting {...this.props} data-testid="password-security" isClient={true}/>
              )}
            </Grid>
          </Grid>
        </ThemeProvider>
        <FooterWeb />
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  linkBox: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flexWrap: 'wrap' as 'wrap',
    background: '#F2F2F2',
    borderRadius: '15px',
    gap: '21px'
  },
  billLink: {
    borderBottom: '2px solid #fff',
    padding: '10% 10%',
    fontFamily: 'Inter'
  },
  linkText: {
    borderBottom: '1px solid #fff',
    padding: '0 10% 10%',
    fontFamily: 'Inter'
  },
  profileContainer: {
    padding: '3% 6%',
    justifyContent: 'space-evenly'
  },
  accountBtn: {
    marginTop: '10%',
    width: '100%',
    padding: '10px',
    borderRadius: '10px',
    color: '#206FC4',
    border: '1px solid #206FC4'
  },
    buttonText: {
      fontFamily: 'Rubik',
      fontSize: '18px',
      fontStyle: 'normal',
      lineHeight: 'normal',
      padding: '10px',

    }
};
// Customizable Area End
